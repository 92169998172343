import { AnimatePresence, domAnimation, LazyMotion } from "framer-motion";
import { StyledDrawerBox } from "../../Styles/StyledScreens";

interface Props {
  showDrawer: boolean;
  setShowDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  intialX?: number | string;
  intialY?: number | string;
  animateX?: number | string;
  animateY?: number | string;
  exitX?: number | string;
  exitY?: number | string;
  animateHeight?: string;
  children?: React.ReactNode;
  [otherProps: string]: any;
}

const AppDrawer: React.FC<Props> = ({
  showDrawer = false,
  setShowDrawer,
  intialX = 0,
  intialY = "+60%",
  animateX = 0,
  animateY = 0,
  exitX = 0,
  exitY = "+100%",
  animateHeight = "120px",
  children,
  ...otherProps
}) => {
  return (
    <>
      <AnimatePresence>
        {showDrawer ? (
          <LazyMotion features={domAnimation}>
            <StyledDrawerBox
              transition={{ duration: 0.23, delay: 0, ease: "easeIn" }}
              initial={{
                opacity: 0,
                y: intialY,
                x: intialX,
                zIndex: 0,
                height: 0,
                overflow: "hidden",
              }}
              animate={{
                opacity: 1,
                y: animateY,
                x: animateX,
                zIndex: 9922220,
                height: animateHeight,
                overflow: "overlay",
              }}
              exit={{
                opacity: 0,
                y: exitY,
                x: exitX,
                zIndex: 0,
                height: 0,
                overflow: "hidden",
                transition: { duration: 0.12, delay: 0.02, ease: "easeOut" },
              }}
              {...otherProps}
            >
              {children}
            </StyledDrawerBox>
          </LazyMotion>
        ) : null}
      </AnimatePresence>
    </>
  );
};

export default AppDrawer;

import { useCallback, useEffect, useState } from "react";

const useTimer = (timeInMs: number = 1000) => {
  const [timer, setTimer] = useState(Math.floor(timeInMs / 1000));

  const timeOutCallback = useCallback(
    () => setTimer((currTimer) => (currTimer <= 0 ? currTimer : currTimer - 1)),
    []
  );

  useEffect(() => {
    timer >= 0 && setTimeout(timeOutCallback, 500);
  }, [timer, timeOutCallback]);

  useEffect(() => {
    setTimer(Math.floor(timeInMs / 1000));
  }, [timeInMs]);

  return {
    timer,
    setTimer
  };
};

export default useTimer;


import React, { memo } from "react";
import { StyledMessageText } from "../../Styles/StyledComponents";
import AppMessageBoxWrapper from "../UtilsComponents/AppMessageBoxWrapper";

interface Props {
  src: string;
  alt?: string;
  userMessage: boolean;
  time: string;
  children?: any;
  readStatus?: "sent" | "delivered" | "seen";
  [otherProps: string]: any;
}

const AppRenderAudio: React.FC<Props> = ({
  src = "",
  alt = "",
  userMessage = false,
  time,
  readStatus = "delivered",
}) => {
  if (!src) {
    return null;
  }
  return (
    <AppMessageBoxWrapper
      userMessage={userMessage}
      isMedia={true}
      time={time}
      readStatus={readStatus}
    >
      <audio src={src} controls autoPlay={false} style={{ width: "100%" }} />
      {alt && alt?.length > 0 ? (
        <StyledMessageText
          userMessage={userMessage}
          dangerouslySetInnerHTML={{
            __html: alt,
          }}
        />
      ) : null}
    </AppMessageBoxWrapper>
  );
};

export default memo(AppRenderAudio);

import { createGlobalStyle } from "styled-components";

interface IStylesProps {
  $fontFamily?: string;
}

const GlobalStyles = createGlobalStyle<IStylesProps>`




//RESET CSS STARTS HERE
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family:  ${({ $fontFamily }: any) =>
    $fontFamily ? `${$fontFamily}` : "Poppins"}, Helvetica, sans-serif !important;

  scroll-behavior: smooth;
  /* ===== Scrollbar CSS ===== */
  ::selection {
      background: rgb(54, 84, 227);
      color: white;
  }
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    border-radius: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #7e8392;
  }
  

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #888;
  }
}

body{
  background-color: transparent !important;
}
html{
  font-size: 100%;
}


.App {
  height: 100vh;
  width: 100%;
  background-color: transparent;
  overflow: hidden;
  position: relative;
 
}




`;

export default GlobalStyles;

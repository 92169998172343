import { useState } from 'react';

const useModal = () => {
  const [isShowing, setIsShowing] = useState(false);

  function toggle(value?: boolean) {
    if (value) {
      setIsShowing(true);
    }
    else if (!value) {
      setIsShowing(false);
    }
    else {
      setIsShowing(!isShowing);
    }
  }

  return {
    isShowing,
    toggle,
  }
};

export default useModal;
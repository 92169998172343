import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../..";

interface Props {
  allMessages: any[];
  originalNodeResponses: any;
  lastNodeResponse: any;
  typingStatus: string | any;
  sessionId: string | any;
  lastQuickActions: any[];
  lastInputBoxType: string | any;
  lastInputBoxStatus: boolean;
  botLanguage: string | any;
  isSessionIdFromLocalStorage: boolean;
  liveChatConnected: boolean;
}

let INITIAL_STATE: Props = {
  allMessages: [],
  originalNodeResponses: [],
  lastQuickActions: [],
  lastNodeResponse: null,
  lastInputBoxType: null,
  lastInputBoxStatus: false,
  typingStatus: "",
  sessionId: null,
  botLanguage: "english",
  isSessionIdFromLocalStorage: false,
  liveChatConnected:false,
};

const AllMessagesSlice = createSlice({
  name: "AllMessagesSlice",
  initialState: INITIAL_STATE,
  reducers: {

    setAllMessages(state, { payload }: PayloadAction<any>) {
      if (!Array.isArray(payload) && typeof payload === "object") {
        payload = [payload];
      }
      const existingMsgs = JSON.parse(JSON.stringify(state.allMessages));
      const newMsgs = [...existingMsgs, ...payload];
      state.allMessages = newMsgs;
    },

    removeMessage(state, { payload }: PayloadAction<any>) {
      state.allMessages = state.allMessages?.filter(
        (message:any) => message?.message_id !== payload?.message_id
      );
    },

    setAllHistoryMessages(state, { payload }: PayloadAction<any>) {
      if (!Array.isArray(payload) && typeof payload === "object") {
        payload = [payload];
      }

      if (payload.length === 0) {
        state.allMessages = [];
        return;
      }

      state.allMessages = payload;
    },

    removeAllMessages() {
      return INITIAL_STATE;
    },

    setTypingStatus(state, { payload }: PayloadAction<any>) {
      state.typingStatus = payload;
    },
    setLastNodeResponse(state, { payload }: PayloadAction<any>) {
      state.lastNodeResponse = payload;
      state.lastInputBoxStatus = payload?.["input"] ? true : false;
      state.lastInputBoxType = payload?.["input"] && payload?.["input_type"]
        ? payload?.["input_type"]
        : null;
      state.lastQuickActions = payload?.["quickActions"]?.length > 0
        ? payload?.["quickActions"]
        : [];
    },

    setOriginalResponses(state, { payload }: PayloadAction<any>) {
      const existingResponses = JSON.parse(
        JSON.stringify(state.originalNodeResponses)
      );
      const newResponses = [...existingResponses, payload];
      state.originalNodeResponses = newResponses;
    },

    setSessionId(state, { payload }: PayloadAction<any>) {
      state.sessionId = payload;
    },

    setBotLanguage(state, { payload }: PayloadAction<any>) {
      state.botLanguage = payload;
    },
    setIsSessionIdFromLocalStorage(state, { payload }: PayloadAction<any>) {
      state.isSessionIdFromLocalStorage = payload;
    },
    setLiveChatConnected(state, { payload }: PayloadAction<any>) {
      state.liveChatConnected = payload;
    },
  },
});

export default AllMessagesSlice.reducer;

export const {
  removeAllMessages,
  setAllMessages,
  removeMessage,
  setAllHistoryMessages,
  setTypingStatus,
  setSessionId,
  setLastNodeResponse,
  setOriginalResponses,
  setBotLanguage,
  setIsSessionIdFromLocalStorage,
  setLiveChatConnected
} = AllMessagesSlice.actions;

export const allMessagesStore = (state: RootState) =>
  state.botData.currrentAllMessagesStore;

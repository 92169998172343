import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { INPUT_TYPES } from "../../../Models/Enum";
import { getChatbotHeight } from "../../../Utils";
import { getChatbotWidth } from '../../../Utils/Utils';

interface Props {
  width: number;
  height: number;
}

let INITIAL_STATE: Props = {
  width: 0,
  height: 0,
};

const IframeDimensionSlice = createSlice({
  name: "IframeDimensionSlice",
  initialState: INITIAL_STATE,
  reducers: {
    ChangeIframeDimension(state, { payload }: PayloadAction<Props>) {
      const wh = {
        height: getChatbotHeight(payload.width, payload?.height) + 16,
        width: getChatbotWidth(payload.width)
      }
      return wh;
    },

  },
});

export default IframeDimensionSlice.reducer;
export const { ChangeIframeDimension } = IframeDimensionSlice.actions;

export const useIframeDimensionStore = (state: any) => {
  return { ...(state.botData.currrentIframeDimension as Props) };
};

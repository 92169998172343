import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Props {
  visitorId: string | null;
}

let INITIAL_STATE: Props = {
  visitorId: null,
};

const VisitorIdConfigSlice = createSlice({
  name: "VisitorIdConfigSlice",
  initialState: INITIAL_STATE,
  reducers: {
    StoreVisitorIdConfigFn(state, { payload }: PayloadAction<Props>) {
      // console.log("visitor Config store fn", payload);
      return payload;
    },
    RemoveVisitorIdConfigFn() {
      // console.log(" visitor Config remove fn");
      let temp = {
        visitorId: null
      };
      return temp;
    },
  },
});

export default VisitorIdConfigSlice.reducer;
export const { StoreVisitorIdConfigFn, RemoveVisitorIdConfigFn } = VisitorIdConfigSlice.actions;

export const useVisitorIdConfigStore = (state: any) => {
  return { ...(state.botData.currrentVisitorIdConfig as Props) };
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TrackingConfig } from "../../../Models/TrackingConfig";

interface Props {
  trackingConfig: TrackingConfig;
}

let INITIAL_STATE: Props = {
  trackingConfig: {
    bot_id: null,
    device: null,
    geta_host: null,
    session_data: null,
  },
};

const TrackingConfigSlice = createSlice({
  name: "TrackingConfigSlice",
  initialState: INITIAL_STATE,
  reducers: {
    StoreTrackingConfigFn(state, { payload }: PayloadAction<Props>) {
      // console.log("tracking config store fn", payload);
      return payload;
    },
    RemoveTrackingConfigFn() {
      // console.log("tracking congfig remove fn");
      let temp = {
        trackingConfig: {
          bot_id: null,
          device: null,
          geta_host: null,
          session_data: null,
        },
      };
      return temp;
    },
  },
});

export default TrackingConfigSlice.reducer;
export const { StoreTrackingConfigFn, RemoveTrackingConfigFn } =
  TrackingConfigSlice.actions;

export const useTrackingConfigStore = (state: any) => {
  return { ...(state.botData.currrentTrackingConfig as Props) };
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { INPUT_TYPES } from "../../../Models/Enum";

interface Props {
  openStatus: boolean;
  selectedResponseLanguage: string;
  inputBoxType: INPUT_TYPES;
  inputBoxStatus: boolean;
}

let INITIAL_STATE: Props = {
  openStatus: false,
  selectedResponseLanguage: '',
  inputBoxType: INPUT_TYPES.NONE,
  inputBoxStatus:false,
};

const ChatWindowStatusSlice = createSlice({
  name: "ChatWindowStatusSlice",
  initialState: INITIAL_STATE,
  reducers: {
    ChangeChatWindowStatus(state, { payload }: PayloadAction<Props>) {
      // console.log("StoreChatWindowStatus", payload);
      return payload;
    },
    RemoveChatWindowStatus() {
      // console.log(" RemoveChatWindowStatus");
      return INITIAL_STATE;
    },
  },
});

export default ChatWindowStatusSlice.reducer;
export const { ChangeChatWindowStatus, RemoveChatWindowStatus } = ChatWindowStatusSlice.actions;

export const useChatWindowStatusStore = (state: any) => {
  return { ...(state.botData.currrentChatWindowStatus as Props) };
};

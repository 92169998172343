import { Alert, AlertProps } from "@mui/material";
import React from "react";
import styled from "styled-components";


interface Props extends AlertProps {
  children?: React.ReactNode;
  [otherProps: string]: any;
}
const AppAlert: React.FC<Props> = ({ children, ...otherProps }) => {
  return <StyledAlert {...otherProps}>{children}</StyledAlert>;
};

export default AppAlert;

const StyledAlert = styled(Alert)`
  && {
    z-index: 9999;
    padding: 4px 8px;
    font-size: 14px;
    line-height: 16px;
    width: 100%;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 60px;

    .MuiAlert-icon {
      margin-right: 6px;
      padding: 0px 0px;
      font-size: 18px;
    }
  }
`;

import React, { memo } from "react";
import { useAppDispatch, useAppSelector } from "../../../../Store";
import {
  allMessagesStore,
  setAllMessages,
} from "../../../../Store/Slices/socket/AllMessagesSlice";
import useMessagesData from "../../../hooks/useMessagesData";
import AppSmallButton from "../../UtilsComponents/AppSmallButton";

interface Props {
  userMessage: boolean;
  item: any;
  rowWrapperStyle?: React.CSSProperties | undefined;
  containerStyle?: React.CSSProperties | undefined;
  time: string;
  readStatus?: "sent" | "delivered" | "seen";
  [otherProps: string]: any;
}

const AppRenderNestedFlowButtons: React.FC<Props> = ({
  userMessage,
  item,
  rowWrapperStyle,
  containerStyle,
  time,
  readStatus = "delivered",
  otherProps,
}) => {
  const dispatch = useAppDispatch();
  const { typingStatus, sessionId, liveChatConnected } = useAppSelector(allMessagesStore);
  // const { socket } = useAppSelector(socketStatusStore);
  const { postBotUserQuery } = useMessagesData();

  const handleOptionBtnClick = async (item: any) => {
    if (!item?.value?.nodeId) {
      console.warn("nodeId is empty wrong button format recieved", item);
      return;
    }

    if (!item?.value?.qid) {
      console.warn("qid is empty wrong button format recieved", item);
      return;
    }

    if (!item?.value?.stage) {
      console.warn("stage is empty wrong button format recieved", item);
      return;
    }

    let data = {
      type: "text",
      value: item?.value?.text,
      journey: {
        nodeId: item?.value?.nodeId,
        qid: item?.value?.qid,
        stage: item?.value?.stage,
      },
      session_id: sessionId,
      isUser: true,
    };

    dispatch(setAllMessages([data]));
    // if (socket?.connected) {
    //   socket?.emit(SOCKET_CONNECTION_TYPES.BOT_CONVERSATION, data);
    // }
    postBotUserQuery(data);
  };

  return (
    <AppSmallButton
      variant="primaryoutlinebutton"
      onClick={() => handleOptionBtnClick(item)}
      disabled={liveChatConnected ? true : false}
      style={{ pointerEvents: typingStatus ? "none" : "auto" }}
      title={liveChatConnected ? "Flows unavailable during live chat" : ""  }
    >
      {item?.value?.text}
    </AppSmallButton>
  );
};

export default memo(AppRenderNestedFlowButtons);

import { Avatar } from "@mui/material";
import React, { memo } from "react";
import uniqid from "uniqid";
import botConfiguration from "../../../Customization/botConfiguration";
import { useAppSelector } from "../../../Store";
import { useBotConfigStore } from "../../../Store/Slices/ChatbotSlices/BotConfigSlice";

interface Props {
  userMessage: boolean;
  isButton?: boolean;
}

const AppChatUserAvatar: React.FC<Props> = ({
  userMessage = true,
  isButton = false,
}) => {

  const { botConfig } = useAppSelector(useBotConfigStore);
  let CHAT_AVATAR =
    botConfig.config?.avatar?.source || botConfiguration.CHAT_AVATAR;

  if (userMessage) {
    return null;
  }

  return (
    <Avatar
      sx={{
        width: 24,
        height: 24,
        fontSize: "12px",
        marginBottom: "1rem",
        pointerEvents: "none",
        opacity: isButton ? 0 : 1,
        // display: "none",
      }}
      aria-label="recipe"
      key={uniqid()}
      src={CHAT_AVATAR}
    />
  );
};

export default memo(AppChatUserAvatar);

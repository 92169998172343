import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Slide,
  Typography,
  Zoom,
} from "@mui/material";
import { red } from "@mui/material/colors";
import React, { memo } from "react";
import { useAppDispatch, useAppSelector } from "../../Store";
import { useBotConfigStore } from "../../Store/Slices/ChatbotSlices/BotConfigSlice";
import { setIsOpenedFirstTime } from "../../Store/Slices/socket/BotInfoSlice";
import { StyledCalloutMessageCard } from "../Styles/StyledScreens";
import { AppMaterialIcons } from "../appComponents/Icons";
import {
  TOOLTIP_POSITIONS_OPTIONS,
  WIDGET_POSITIONS_OPTIONS,
} from "../utils/Enum";
import { getDefaultAvatar } from "../utils/utils";
import useTimer from "../hooks/useTimer";

interface Props {
  handleQuickChat: any;
  handleClosedStateIframeDimensions: any;
}

const CalloutMessageScreen: React.FC<Props> = ({
  handleQuickChat,
  handleClosedStateIframeDimensions,
}) => {
  const { botConfig } = useAppSelector(useBotConfigStore);
  const dispatch = useAppDispatch();
  const { setTimer, timer } = useTimer(2000);

  React.useEffect(() => {
    if (timer <= 0) {
      handleClosedStateIframeDimensions();
    }

    // Clear the timer on component unmount to prevent memory leaks
    return () => clearTimeout(timer);
  }, [timer]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCloseTooltipButton = () => {
    dispatch(setIsOpenedFirstTime(true));
    setTimer(0);
    handleClosedStateIframeDimensions();
  };

  return (
    <Slide
      direction={
        botConfig.config?.tooltip?.position === TOOLTIP_POSITIONS_OPTIONS.TOP
          ? "up"
          : botConfig.config?.display_position === WIDGET_POSITIONS_OPTIONS.LEFT
          ? "right"
          : "left"
      }
      in={timer === 0 ? true : false}
      unmountOnExit
      timeout={{ enter: 800, exit: 500 }}
      // style={{ transitionDelay: '5000ms'}}
    >
      <StyledCalloutMessageCard className="StyledCalloutMessageCard">
        <div className="tooltipActionBox">
          <IconButton
            title="close"
            onClick={() => {
              handleCloseTooltipButton();
            }}
          >
            <AppMaterialIcons iconName="Close" />
          </IconButton>
        </div>
        <Card onClick={() => handleQuickChat()}>
          <CardHeader
            avatar={
              botConfig.config?.avatar?.source ? (
                <Avatar
                  aria-label="widget-avatar"
                  src={botConfig.config?.avatar?.source}
                />
              ) : (
                <Avatar
                  sx={{ bgcolor: red[500] }}
                  aria-label="widget-avatar"
                >
                  {getDefaultAvatar(botConfig?.chatbot?.name || "Bot")}
                </Avatar>
              )
            }
            title={botConfig?.chatbot.name || "Bot"}
          />
          <CardContent>
            <Typography variant="body2" color="text.secondary">
              {botConfig.config?.tooltip?.tooltip_text}
            </Typography>
          </CardContent>
          {botConfig.config?.tooltip?.position ===
            TOOLTIP_POSITIONS_OPTIONS.TOP &&
          botConfig.config?.tooltip?.image?.source ? (
            <div className="cardImageContainer">
              <img
                src={botConfig.config?.tooltip?.image?.source}
                alt="Callout Message Media"
                className="cardImage"
              />
            </div>
          ) : null}
        </Card>
      </StyledCalloutMessageCard>
    </Slide>
  );
};

export default memo(CalloutMessageScreen);

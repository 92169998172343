import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { BotConfig } from "./../../../Models/BotConfig";

interface Props {
  botConfig: BotConfig;
}

let INITIAL_STATE: Props = {
  botConfig: {
    chatbot: null,
    config: null,
    theme: null,
  },
};

const BotConfigSlice = createSlice({
  name: "BotConfigSlice",
  initialState: INITIAL_STATE,
  reducers: {
    StoreBotConfigFn(state, { payload }: PayloadAction<Props>) {
      return payload;
    },
    RemoveBotConfigFn() {
      return INITIAL_STATE;
    },
  },
});

export default BotConfigSlice.reducer;
export const { StoreBotConfigFn, RemoveBotConfigFn } = BotConfigSlice.actions;

export const useBotConfigStore = (state: RootState) =>
  state.botData.currrentBotConfig;

import moment, { Moment } from "moment";
import uniqid from "uniqid";
import { DEVICE_SIZE } from "../Customization/BreakPoints";
import { MESSAGE_TYPES } from "../Models/Enum";
import {
  // addYears,
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  // isSameDay,
  // differenceInCalendarDays,
  format,
  isWithinInterval,
  set
} from "date-fns";
import { convertToTimeZone } from "date-fns-timezone";
import botConfiguration from '../Customization/botConfiguration';

export const DefinedCustomDates = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),

  today: {
    startDate: startOfDay(new Date()),
    endDate: endOfDay(new Date()),
  },
  yesterday: {
    startDate: startOfDay(addDays(new Date(), -1)),
    endDate: endOfDay(addDays(new Date(), -1)),
  },
  last7Days: {
    startDate: addDays(new Date(Date.now()), -7),
    endDate: endOfDay(addDays(new Date(), -1)),
  },
  last30Days: {
    startDate: addDays(new Date(Date.now()), -30),
    endDate: endOfDay(addDays(new Date(), -1)),
  },
  last90Days: {
    startDate: addDays(new Date(Date.now()), -90),
    endDate: endOfDay(addDays(new Date(), -1)),
  },
};


export const isWithinBusinessHour = (data: any) => {

  console.log("business hour data", data);

  if (!data || !data?.activeOperatingHours) {
    console.log("no business time data found");
    return true;
  }

  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const currentTime = new Date();

  //* business timezone
  const timezone = data?.timezone || "Asia/Kolkata";

  const timezoneCurrentTime = convertToTimeZone(currentTime, { timeZone: timezone })
  const timezoneCurrentDay = format(timezoneCurrentTime, "EEEE").toLowerCase();


  let startHours = data[timezoneCurrentDay]?.start?.slice(0, 2) || '10';
  let startMinutes = data[timezoneCurrentDay]?.start?.slice(3, 5) || '30';
  // if (startHours == '00') {
  //   startHours = startHours.replace(/^.{2}/g, '24')
  // }

  let endHours = data[timezoneCurrentDay]?.end?.slice(0, 2) || '10';
  let endMinutes = data[timezoneCurrentDay]?.end?.slice(3, 5) || '30';
  // if (endHours == '00') {
  //   endHours = endHours.replace(/^.{2}/g, '24')
  // }
  const timezoneStartTime = set(timezoneCurrentTime, { hours: startHours, minutes: startMinutes });
  const timezoneEndTime = set(timezoneCurrentTime, { hours: endHours, minutes: endMinutes });

  // console.log("values", currentTime, timezoneCurrentTime, timezoneCurrentDay, timezoneStartTime, timezoneEndTime)

  if (!data[timezoneCurrentDay]?.enabled) {
    console.log("business is off for following day");
    return false;
  }

  if (timezoneEndTime > timezoneStartTime && isWithinInterval(timezoneCurrentTime, {
    start: timezoneStartTime,
    end: timezoneEndTime,
  })) {
    console.log("end user time is within business hour");
    return true;
  }
  else {
    console.log("end user time is outside business hour");
    return false;
  }


};

export const getChatbotWidth = (size?: number) => {
  if (size && size < DEVICE_SIZE.tablet) {
    // console.log("size ", size, DEVICE_SIZE.tablet, size < DEVICE_SIZE.tablet)
    let temp = size - 16;
    return temp;
  } else {
    return 448;
  }
};

export const getChatbotHeight = (
  deviceWidth?: number,
  deviceHeight?: number
) => {
  // console.log("size height", deviceWidth, DEVICE_SIZE.tablet, deviceHeight);
  if (deviceWidth && deviceHeight && deviceWidth < DEVICE_SIZE.tablet) {
    // let temp = deviceHeight - 30;
    let temp = deviceHeight;

    return temp;
  } else {
    if (deviceHeight && deviceHeight < 590) {
      let temp = deviceHeight - 100;

      return temp;
    }
    return 590;
  }
};

export const getOfficeWorkingHours = (date: string | Moment) => {
  // console.log("getOfficeWorkingHours", date);
  let a: any = [];
  for (let i = 1; i < 19; i++) {
    // let todayHours: any = moment().add(i, "hours");
    let futureDaysHours: any = moment(moment(date).format("L")).add(i, "hours");

    let now = moment(moment().add(i, "hours").format("LL HH:mm"));
    let starttime = moment(moment().format("LL 09:00"));
    let endtime = moment(moment().format("LL 19:00"));

    // console.log("isbetwwwen", now.isBetween(starttime, endtime) )
    // console.log("isbetwwwen", now, futureDaysHours)

    if (moment(date).isAfter()) {
      if (
        a.length < 10 &&
        parseInt(futureDaysHours.format("HH")) < 19 &&
        parseInt(futureDaysHours.format("HH")) > 8
      ) {
        a.push(futureDaysHours.format("hh:00 A"));
      }
    } else {
      if (a.length < 10 && now.isBetween(starttime, endtime)) {
        a.push(now.format("hh:00 A"));
      }
    }
  }
  return a;
};

export const getOfficeWorkingDays = () => {
  let a: any = [];
  for (let i = 0; i < 15; i++) {
    let temp: any = moment().add(i, "days");

    if (
      a.length < 10 &&
      !temp.format("ddd, Do MMM").includes("Sat") &&
      !temp.format("ddd, Do MMM").includes("Sun")
    ) {
      let temporary: any = {
        key: uniqid(),
        date: temp.format("ddd, Do MMM"),
        time: getOfficeWorkingHours(temp),
      };
      a.push(temporary);
    }
  }
  return a;
};

export const isEmptyObject = (obj: object) => {
  return Object.keys(obj).length === 0;
};

export function formatString(string: string, replacements: string[]): string {
  return string.replace(/{(\d+)}/g, function (match, number) {
    return typeof replacements[number] !== "undefined"
      ? replacements[number]
      : match;
  });
}

export const DefaultIndiaMobileFormat = {
  countryCode: "91",
  dialCode: "91",
  format: "+.. .....-.....",
  iso2: "in",
  name: "India",
  priority: 0,
  regions: ["asia"],
};

export const getValuePercent = (achieved: number, total: number) => {
  return ((100 * achieved) / total).toFixed(2);
};

export const getValueColor = (value: number) => {
  let color = "orange";
  if (value > 75) {
    color = "green";
  } else if (value < 35) {
    color = "red";
  }
  return color;
};

export const humanizeDate = (date: number, time = true) => {
  return moment(date).format(`LL${time ? "L" : ""}`);
};

export const titlize = (str: string) => {
  return str
    ? str
      ?.toLowerCase()
      ?.split(" ")
      ?.map((word) => {
        return word.replace(word[0], word[0].toUpperCase());
      })
      ?.join(" ")
    : "";
};

export const uuidv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const makeDummyUid = (section_id: string) => {
  let uid = uuidv4().split("-");
  uid[1] = section_id;
  return uid.join("-");
};

export const simplifyMessages = (newMessages: any) => {
  let temp: any = [];
  newMessages &&
    newMessages.length > 0 &&
    newMessages.map((item: any, index: number) => {
      item?.response?.forEach((singleMsg: any, index2: number) => {
        temp.push(singleMsg);
      });
    });
  return temp;
};
export const checkInputTypeOfLastElement = (newMessages: any) => {
  let temp: string | null = null;
  newMessages &&
    newMessages.length > 0 &&
    newMessages.map((item: any, index: number) => {
      const isLastElement = index === newMessages?.length - 1;
      if (isLastElement && item?.input && item?.input_type || isLastElement && item.type === "FAQ") {
        temp = "true";
      }
    });
  return temp;
};

export const FLOW_BUTTONS = [
  MESSAGE_TYPES.FLOW,
  MESSAGE_TYPES.LINK,
  MESSAGE_TYPES.BUTTONS,
  MESSAGE_TYPES.MOBILE,
  MESSAGE_TYPES.EMAIL,
];

export const isFlowLinkButtons = (mtype: MESSAGE_TYPES) => {
  return FLOW_BUTTONS.find((mt: string) => mt === mtype) !== undefined;
};

export const attachingAvatarToLastMsg = (newMessages: any = []) => {
  let nLength = newMessages?.length;
  let eLength = newMessages[nLength - 1].response?.length;
  let lastElement = newMessages[nLength - 1].response[eLength - 1];
  if (!lastElement?.userMessage) {
    lastElement = {
      ...lastElement,
      showAvatar: true,
    };
    newMessages[nLength - 1].response[eLength - 1] = lastElement;
    return newMessages;
  } else if (newMessages[nLength - 1].response[eLength - 2]) {
    let last2ndElement = newMessages[nLength - 1].response[eLength - 2];
    last2ndElement = {
      ...lastElement,
      showAvatar: true,
    };
    newMessages[nLength - 1].response[eLength - 1] = last2ndElement;
    return newMessages;
  } else {
    let last2ndElement = {
      ...lastElement,
      showAvatar: false,
    };
    newMessages[nLength - 1].response[eLength - 1] = last2ndElement;
    return newMessages;
  }
};
export const newMsgLengthFinder = (newMessages: any = []) => {
  let count = 0;
  newMessages &&
    newMessages.length > 0 &&
    newMessages.map((item: any, index: number) => {
      item && item?.response?.length > 0 && item?.response?.map((singleMsg: any, index2: number) => {
        if (!isFlowLinkButtons(singleMsg?.type)) {
          count = count + 1;
        }
      })
      // count = count + item?.response?.length;
    });

  return count;
};

export const getMessageDelayTimeWithLength = (lengthCount: number = 1) => {
  let time = 0;
  let delayTimeArray: number[] = []
  for (let index = 0; index < lengthCount; index++) {
    delayTimeArray.push((index) * botConfiguration.MESSAGES_DELAY)
  }
  return delayTimeArray;
};

export const getFinalLastMsg = (newMessages: any = [], index?: number, type?: string) => {
  if (type && index !== undefined && newMessages?.[newMessages?.length - 1]?.actions?.[index]) {
    return newMessages?.[newMessages?.length - 1]?.actions[
      index
    ];
  } else {
    return newMessages?.[newMessages?.length - 1]?.response[
      newMessages?.[newMessages?.length - 1]?.response?.length - 1
    ];
  }

};
export const getLastBotResponseFullItem = (newMessages: any = []) => {
  return newMessages?.[newMessages?.length - 1];
};


export const compareTwoObjects = (object1: any, object2: any) => {
  if (JSON.stringify(object1) === JSON.stringify(object2)) {
    return true;
  } else {
    return false;
  }
};
export const secondsToHms = (value: string) => {
  const sec = parseInt(value, 10);
  let hours: string | number = Math.floor(sec / 3600);
  let minutes: string | number = Math.floor((sec - hours * 3600) / 60);
  let seconds: string | number = sec - hours * 3600 - minutes * 60;
  if (hours < 10) { hours = '0' + hours; }
  if (minutes < 10) { minutes = '0' + minutes; }
  if (seconds < 10) { seconds = '0' + seconds; }
  if (hours == 0) {
    return +minutes + ':' + seconds; // Return in MM:SS format
  } else {
    return hours + ':' + minutes + ':' + seconds; // Return in HH:MM:SS format
  }
}

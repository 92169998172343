import { Divider, IconButton } from "@mui/material";
import React from "react";
import styled, { withTheme } from "styled-components";
import { theme } from "../../../Customization/Theme";
import { ConversationService } from "../../../Services/Apis";
import { HostWindowCommService } from "../../../Services/IframeHostService/HostWindowCommService";
import { OEvents } from "../../../Services/IframeHostService/models";
import { useAppDispatch, useAppSelector } from "../../../Store";
import {
  allMessagesStore,
  removeAllMessages,
  setIsSessionIdFromLocalStorage,
} from "../../../Store/Slices/socket/AllMessagesSlice";
import {
  botInfoStore,
  setOpenStatus,
  setToggleReloadWidget,
} from "../../../Store/Slices/socket/BotInfoSlice";
import {
  removeSocketStatus,
  socketStatusStore,
} from "../../../Store/Slices/socket/SocketSlice";
import AppDrawer from "../../hooks/useDrawer/AppDrawer";
import useToggle from "../../hooks/useDrawer/useToggle";
import AppModel from "../../hooks/useModel/AppModel";
import useModal from "../../hooks/useModel/useModel";
import { PreviewType, SOCKET_CONNECTION_TYPES } from "../../utils/Enum";
import { EndChatFeedbackSchema } from "../../utils/ValidationSchema";
import { AppForm, AppInputRatingField, AppSubmitButton } from "../Forms";
import { AppMaterialIcons } from "../Icons";
import AppSmallButton from "../UtilsComponents/AppSmallButton";

interface Props {
  theme?: any;
}

const AppEndChatFeedback: React.FC<Props> = ({ theme }) => {
  let commService = React.useMemo(() => {
    return HostWindowCommService.getInstance();
  }, []);

  const dispatch = useAppDispatch();
  const { isShowing, toggle } = useModal();
  const model = useModal();

  const { socket } = useAppSelector(socketStatusStore);
  const { sessionId } = useAppSelector(allMessagesStore);
  const { visitorId, botId, widgetPreviewType, toggleReloadWidget, getaHost } = useAppSelector(botInfoStore);
  const botHeaders = {
    appid: JSON.stringify({
      botid: botId,
    }),
    visitor_id: visitorId || null,
    preview: widgetPreviewType === PreviewType.getaWidgetPreview ? true : false,
    "GETA-HOST": getaHost,
  };

  // const useDrawer = useToggle();
  const [formStep, setFormStep] = React.useState<number>(1);
  const [userInput, setUserInput] = React.useState<any>({
    rating: 5,
    feedback: "",
  });

  const handleLiveDisconnectBtn = () => {
    // toggle(false);
    setFormStep(2);
    // useDrawer.toggle(true);
  };

  const handleCancel = () => {
    toggle(false);
    setFormStep(1);
    // useDrawer.toggle(false);
  };

  const handleRestartBtn = () => {
    let data = {
      session_id: sessionId,
      rating: 5,
      feedback: '',
    };
    //inform the agent
    socket?.emit(SOCKET_CONNECTION_TYPES.CHAT_END_BY_USER, data);
    ConversationService.postChatFeedback(data, botHeaders)
      .then((res: any) => {
        const data = res?.data;
        console.log("data", data);
      })
      .catch((err: any) => {
        console.error("submit error", err);
      })
      .finally(() => {
        dispatch(setOpenStatus(false));
        initiateBotSessionEvent();
        toggle(false);
      });
  };

  const handleReviewSubmit = (values: any, submitProps: any) => {
    let data = {
      session_id: sessionId,
      rating: values.rating,
      feedback: values.feedback,
    };
    //inform the agent
    socket?.emit(SOCKET_CONNECTION_TYPES.CHAT_END_BY_USER, data);
    ConversationService.postChatFeedback(data, botHeaders)
      .then((res: any) => {
        const data = res?.data;
        console.log("data", data);
      })
      .catch((err: any) => {
        console.error("submit error", err);
      })
      .finally(() => {
        submitProps?.setSubmitting(false);
        submitProps?.resetForm();
        setUserInput({ rating: 5, feedback: "" });
        dispatch(setOpenStatus(false));
        initiateBotSessionEvent();
        toggle(false);
      });
  };

  const handleNotReviewBtn = () => {
    let data = {
      session_id: sessionId,
      rating: 5,
      feedback: null,
    };
    //inform the agent
    socket?.emit(SOCKET_CONNECTION_TYPES.CHAT_END_BY_USER, data);
    setUserInput({ rating: 5, feedback: "" });
    dispatch(setOpenStatus(false));
    initiateBotSessionEvent();
    toggle(false);
  };

  const initiateBotSessionEvent = () => {
    commService.postMessage({
      event_type: OEvents.CHATBOT_SESSION_ID,
      data: null,
    });
    socket?.disconnect();
    dispatch(removeAllMessages());
    dispatch(setIsSessionIdFromLocalStorage(false));
    // setTimeout(() => {
    dispatch(setToggleReloadWidget(!toggleReloadWidget));
    // }, 10);
  };

  return (
    <>
      <IconButton onClick={() => toggle(true)}>
        <AppMaterialIcons
          iconName="Close"
          style={{
            color: theme?.components?.header.text.color,
            fontSize: "26px",
          }}
        />
      </IconButton>
      {/* Show Confirmation Model on reload incase of live agent */}
      <AppModel
        isShowing={isShowing}
        onClose={() => undefined}
        divStyle={{ padding: 0 }}
      >
        {formStep === 1 ? (
          <StyleWarningModelWrapper>
            <div className="body">
              Are you sure you want to end conversation?
            </div>
            <Divider />
            {}
            <div className="footer">
              {" "}
              <AppSmallButton
                // variant="outline"
                onClick={() => handleLiveDisconnectBtn()}
                style={{
                  height: "38px",
                  borderRadius: "8px",
                  width: "100%",
                }}
              >
                End Conversation
              </AppSmallButton>
              <div className="footerAction">
                <AppSmallButton
                  variant="text"
                  onClick={() => handleRestartBtn()}
                  style={{
                    height: "38px",
                    borderRadius: "8px",
                    width: "100%",
                    minWidth: "114px",
                    color: theme.palette.default.darkGrey,
                    whiteSpace: "nowrap",
                  }}
                >
                  Restart Chat
                </AppSmallButton>
                <AppSmallButton
                  variant="text"
                  onClick={() => handleCancel()}
                  style={{
                    height: "38px",
                    borderRadius: "8px",
                    width: "100%",
                    color: theme.palette.default.darkGrey,
                  }}
                >
                  Not now
                </AppSmallButton>
              </div>
            </div>
          </StyleWarningModelWrapper>
        ) : null}
        {formStep === 2 && (
          <div
            className="rating"
            style={{ width: "330px", padding: "30px 12px" }}
          >
            <AppForm
              initialValues={userInput}
              onSubmit={(values: any, submitProps: any) => {
                handleReviewSubmit(values, submitProps);
              }}
              validationSchema={EndChatFeedbackSchema}
            >
              <AppInputRatingField />
              <Divider />
              <div className="footer">
                {" "}
                <AppSubmitButton
                  title="Submit Feedback"
                  // variant="outline"
                  style={{ height: "38px", borderRadius: "8px", width: "100%" }}
                />
                <AppSmallButton
                  variant="text"
                  onClick={() => handleNotReviewBtn()}
                  style={{
                    height: "38px",
                    borderRadius: "8px",
                    width: "100%",
                    color: theme.palette.default.darkGrey,
                  }}
                >
                  No, Thanks
                </AppSmallButton>
              </div>
            </AppForm>
          </div>
        )}
      </AppModel>
    </>
  );
};

export default withTheme(AppEndChatFeedback);

const StyleWarningModelWrapper = styled.div`
  && {
    display: flex;
    flex-direction: column;
    min-height: 100px;
    border-radius: 4px;

    .footerAction {
      display: flex;
      gap: 1rem;
      justify-content: center;
      align-items: center;
    }

    .header {
      padding: 4px 12px;

      width: 100%;
      height: 36px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
      background-color: ${theme.palette.default.white};

      font-size: 14.5px;
      font-weight: 500;
      letter-spacing: 0.5px;

      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
    }
    .footer {
      padding: 8px 16px 8px;
      width: 100%;
      background-color: ${theme.palette.default.white};

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      font-family: ${({ theme }) => theme?.font?.primary.family};
      font-weight: 400;
      font-size: 1rem;
      line-height: 28px;
      letter-spacing: 1px;
      color: ${theme.palette.default.text};
      text-align: center;
    }
    .title {
      width: 100%;
    }

    .body {
      padding: 10px 12px 10px;

      flex: 1;
      overflow-y: auto;
      background-color: ${theme.palette.default.white};

      font-family: ${({ theme }) => theme?.font?.primary.family};
      font-weight: 500;
      font-size: 1rem;
      /* line-height: 28px; */
      letter-spacing: 0.5px;
      color: ${theme.palette.default.text};
      text-align: center;
    }

    .section {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #ccc;
    }
  }
`;

const StyleDrawerWrapper = styled.div`
  && {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* height: 100%; */
    padding: 1rem 0.5rem;
    margin: 0 2rem;
    border-radius: 0 0 8px 8px;
    background-color: ${theme.palette.default.white};
    border-radius: 4px;
    border: 1px solid;
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.35);

    .header {
      padding: 4px 12px;

      width: 100%;
      height: 36px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
      background-color: ${theme.palette.default.white};

      font-size: 14.5px;
      font-weight: 500;
      letter-spacing: 0.5px;

      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
    }
    .footer {
      padding: 12px 16px 0px;
      width: 100%;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      gap: 0.5rem;
    }

    .body {
      padding: 10px 12px 10px;

      flex: 1;
      overflow-y: auto;
      background-color: ${theme.palette.default.white};

      font-size: 15.2px;
      font-weight: 400;
      line-height: 24px;
      color: ${theme.palette.default.text};
    }

    .section {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #ccc;
    }
  }
`;

import { ClickAwayListener } from "@mui/material";
import React, { useEffect, useState } from "react";

import MenuIcon from "@mui/icons-material/Menu";
import { useSelector } from "react-redux";
import { withTheme } from "styled-components";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { useAppDispatch, useAppSelector } from "../../../Store";
import { useBotConfigStore } from "../../../Store/Slices/ChatbotSlices/BotConfigSlice";
import {
  allMessagesStore,
  setAllMessages,
} from "../../../Store/Slices/socket/AllMessagesSlice";
import { socketStatusStore } from "../../../Store/Slices/socket/SocketSlice";
import { StyledQuickMenuWrapper } from "../../Styles/StyledComponents";
import { SOCKET_CONNECTION_TYPES } from "../../utils/Enum";

interface Props {
  theme?: any;
}

const AppRenderQuickMenu: React.FC<Props> = ({ theme }) => {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const [open, setOpen] = useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);
  const [viewStyle, setViewStyle] = useState<number>(2);
  const dispatch = useAppDispatch();
  const { sessionId } = useAppSelector(allMessagesStore);
  const { socket } = useAppSelector(socketStatusStore);
  const { botConfig } = useSelector(useBotConfigStore);

  useEffect(() => {
    if (
      botConfig?.config?.quick_menu?.enabled &&
      botConfig?.config?.quick_menu?.data
    ) {
      setData(botConfig?.config?.quick_menu?.data);
      if (
        botConfig?.config?.quick_menu?.view_style === "Lists" ||
        botConfig?.config?.quick_menu?.view_style === 1
      ) {
        setViewStyle(1);
      }
    }
  }, [botConfig]);

  const handleCardClick = (url: any) => {
    window.open(url, "_blank", "noreferrer");
    setTimeout(() => {
      setExpanded(false);
    }, 100);
  };

  const handleOptionBtnClick = async (item: any) => {
    let data = {
      type: "text",
      value:
        item?.journey?.sub_flow?.response?.[0]?.value ||
        item?.journey?.main_flow?.name,
      journey: {
        nodeId: item?.journey?.sub_flow?.nodeId,
        qid: item?.journey?.sub_flow?.qid,
        stage: item?.journey?.sub_flow?.stage,
        displayOrder: item?.journey?.sub_flow?.displayOrder,
      },
      session_id: sessionId,
      isUser: true,
    };

    dispatch(setAllMessages([data]));
    if (socket?.connected) {
      socket?.emit(SOCKET_CONNECTION_TYPES.BOT_CONVERSATION, data);
    }
    setTimeout(() => {
      setExpanded(false);
    }, 100);
  };

  if (
    !botConfig?.config?.quick_menu?.enabled ||
    botConfig?.config?.quick_menu?.data?.length < 1
  ) {
    return null;
  }

  return (
      <StyledQuickMenuWrapper>
        <ClickAwayListener onClickAway={() => setExpanded(false)}>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              // expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              onClick={() => setOpen(!open)}
            >
              <MenuIcon
                style={{
                  color: "#7E8392",
                }}
              />
              {/* <p
                className="accordianTitle"
                style={{ width: "33%", flexShrink: 0 }}
              >
                Quick Menu
              </p> */}
            </AccordionSummary>
            <AccordionDetails>
              {data?.map((curElem: any, index: number) => {
                return (
                  <div
                    className={viewStyle === 1 ? "singleRow" : "singleCard"}
                    key={index?.toString()}
                    onClick={() => {
                      curElem?.journey?.main_flow &&
                      curElem.position !== "Add Link"
                        ? handleOptionBtnClick(curElem)
                        : handleCardClick(curElem?.button_url);
                    }}
                  >
                    <img
                      className={viewStyle === 1 ? "rowImage" : "cardImage"}
                      src={curElem?.image?.source}
                      alt="quick menu "
                    />
                    <p className="buttonTitle">
                      {curElem?.button_title ||
                        curElem?.journey?.main_flow?.name}
                    </p>
                  </div>
                );
              })}
            </AccordionDetails>
          </Accordion>
        </ClickAwayListener>
      </StyledQuickMenuWrapper>
  );
};

export default withTheme(AppRenderQuickMenu);

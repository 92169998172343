import React from "react";
import { StyledErrorText } from "../../Styles/StyledForms";

interface Props {
  error?: any;
  visible?: any;
  children?: React.ReactNode;
  [otherProps: string]: any;
}

const AppErrorMessage: React.FC<Props> = ({
  error,
  visible,
  ...otherProps
}) => {
  if (!visible || !error || typeof error !== "string") return null;

  return <StyledErrorText {...otherProps}>{error}</StyledErrorText>;
};

export default AppErrorMessage;

import React, { memo } from "react";
import { MESSAGE_TYPES } from "../../utils/Enum";
import AppMessageBoxWrapper from "../UtilsComponents/AppMessageBoxWrapper";
import AppRenderNestedFlowButtons from "./nestedFlow/AppRenderNestedFlowButtons";
import AppRenderNestedText from "./nestedFlow/AppRenderNestedText";
import AppRenderNestedLiveButtons from "./nestedFlow/AppRenderNestedLiveButtons";
import AppRenderNestedCtaButtons from "./nestedFlow/AppRenderNestedCtaButtons";
import { StyledReactVideoPlayer } from "../../Styles/StyledComponents";
import { Box } from "@mui/material";
import styled from "styled-components";

const FallbackImage = require("../../../assets/images/Image_not_available.png");

interface Props {
  userMessage: boolean;
  item: any;
  rowWrapperStyle?: React.CSSProperties | undefined;
  containerStyle?: React.CSSProperties | undefined;
  time: string;
  readStatus?: "sent" | "delivered" | "seen";
  [otherProps: string]: any;
}

const AppRenderCard: React.FC<Props> = ({
  userMessage,
  item,
  rowWrapperStyle,
  containerStyle,
  time,
  readStatus = "delivered",
  otherProps,
}) => {
  return (
    <AppMessageBoxWrapper
      userMessage={userMessage}
      time={time}
      isMedia={false}
      isButton={false}
      divStyle={{
        maxWidth: "100%",
        flexDirection: "row",
        gap: "12px",
        padding: "10px 12px",
        backgroundColor: "#fff",
        borderRadius: "8px",
      }}
    >
      {item?.map((item: any, index: number) => {

        // * IMAGE MESSAGE
        if (item?.type === MESSAGE_TYPES.IMAGE)
          return (
        <ImageWrapper>
            <a key={index.toString()} className="message-image" href={item?.value?.url} target="_blank" rel="noreferrer">
              <img
                className="renderImage"
                src={item?.value?.url}
                alt={item?.value?.alt}
                // onClick={() => window.open(src, "_blank")}
                onError={(error: any) => {
                  error.target.src = FallbackImage;
                }}
              />
            </a>
          </ImageWrapper>
          );


        // Video Message
        if (item?.type === MESSAGE_TYPES.VIDEO)
          return (
            <StyledReactVideoPlayer
              key={index.toString()}
              url={item?.value?.url}
              width={"100%"}
              controls={true}
              light={false}
              alt={item?.value?.url}
              height={180}
            />
          );

        if (item?.type === MESSAGE_TYPES.TEXT)
          return (
            <AppRenderNestedText key={index.toString()}>
              {item?.text || item?.value}
            </AppRenderNestedText>
          );

        // * FLOW BUTTONS
        if (item?.type === MESSAGE_TYPES.FLOW)
          return (
            <AppRenderNestedFlowButtons
              key={index.toString()}
              userMessage={userMessage}
              item={item}
              time={item?.time}
            />
          );

        // * LIVE CHAT BUTTONS
        if (item?.type === MESSAGE_TYPES.LIVE_AGENT_REQUESTED) {
          return (
            <AppRenderNestedLiveButtons
              key={index.toString()}
              item={item}
              time={item?.time}
            />
          );
        }

        // * CTA BUTTONS
        if (
          item?.type === MESSAGE_TYPES.LINK ||
          item?.type === MESSAGE_TYPES.MOBILE ||
          item?.type === MESSAGE_TYPES.EMAIL
        ) {
          return (
            <AppRenderNestedCtaButtons
              item={item}
              key={index.toString()}
              time={item?.time}
            />
          );
        }

        return null;
      })}
    </AppMessageBoxWrapper>
  );
};

export default memo(AppRenderCard);

const ImageWrapper = styled.div`
&& {
  width: 100%;
  .renderImage {
    width: 100%;
    max-width: 300px;
    max-height: 300px;
    object-fit: contain;
    width: 100%;
    object-fit: contain;
    border-radius: 4px;
  }
}
`;
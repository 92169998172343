import { motion } from "framer-motion";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import styled, { css } from "styled-components";
import { theme } from "../../Customization/Theme";
import { Select } from "@mui/material";

interface StyledProps {
  userMessage?: any;
  tooltipPosition?: any;
  isTooltipVisible?: any;
  WIDGET_POSITION?: any;
  variant?: any;
  enableuserinput?: number;
  chatopened?: any;
  devicewidth?: any;
  deviceheight?: any;
  jumpPosition?: any;
  theme?: any;
  selected?: any;
  isInvalid?: any;
  disabled?: boolean;
  error?: boolean;
  ref?: any;
}
interface InputRef {
  ref?: any;
  innerRef?: any;
}

const hexToRGBA = (hex: any, alpha: any) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const StyledErrorText = styled.p`
  && {
    position: absolute;
    z-index: 99;
    color: #f34e4e;
    font-size: 13px;
    line-height: 21px;
    background-color: aliceblue;
    top: -26px;
    min-height: 24px;
    left: 0px;
    padding-left: 6px;
    width: 100%;
    transition: all 0.5s linear 0s;
  }
`;

export const StyledWidgetInputBox = styled.div<StyledProps>`
  && {
    width: calc(100% - 24px);
    margin: 0 auto;
    margin-bottom: 15px;

    // padding: 0px 12px;
    justify-content: space-between;
    max-height: 44px;
    min-height: 44px;
    height: 42px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    // box-shadow: 0px 4px 1rem rgba(0, 0, 0, 0.08);
    background-color: transperant;
    pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
    transition: all 0.5s linear 0s;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    // border: 1px solid green;
    gap: 8px;

    input {
      border: 1px solid ${({ theme }) => theme?.palette?.primary?.main} !important;
      // border: 2px solid yellow !important;
      border-radius: 43px;
      height: 40px;
      position: relative;

      // width: calc(100% - 115px)
      box-shadow: 0px 4px 4px 0
        ${({ theme }) => hexToRGBA(theme?.palette?.primary?.main, 0.15)};
    }

    ${(props: any) =>
    props?.error &&
    css`
        border-color: ${theme?.palette?.default?.errorLight};
        border-radius: 43px;
      `}

    .inputIconBox {
      background-color: ${({ theme }) =>
    theme?.components?.user_input.normal.background.color};
      background-color: transparent;
      min-width: 40px;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;

      overflow: hidden;

      cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

      .MuiSvgIcon-root {
        font-size: 1.3rem;
        color: ${({ theme }) =>
    theme?.components?.user_input.placeholder.text.color};
        color: ${({ theme }) => theme?.components?.primary_color_scheme["500"]};
        color: ${(props) =>
    props.disabled
      ? theme?.palette?.default.darkGrey
      : props.theme?.components?.primary_color_scheme["500"]};
      }
    }

    .inputActionBox {
      border-radius: 50%;
      background-color: ${({ theme }) => theme?.palette?.primary?.main};
      // background-color: red;
      min-width: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      overflow: hidden;

      cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

      .MuiIconButton-root {
        border-radius: 8px;
        height: 32px;
        width: 34px;

        &:hover {
        }
      }

      .MuiSvgIcon-root {
        font-size: 20px;
        color: #fff;
      }
    }
  }
`;

export const InputAndUploadWrap = styled.div<StyledProps>`
  & {
    // border: 1px solid green;
    display: flex;
    align-items: center;
    border-radius: 43px;
    background-color: #fff;
    width: 100%;

    // border: 1px solid ${({ theme }) => theme?.palette?.primary?.main};
    box-shadow: 0px 4px 4px 0
      ${({ theme }) => hexToRGBA(theme?.palette?.primary?.main, 0.15)};
  }
`;

export const StyledSuggestionBox = styled(motion.div)`
  && {
    position: absolute;
    bottom: 70px;
    z-index: 10;

    background-color: ${({ theme }) =>
    theme?.components?.user_input.normal.background.color};
    width: 100%;
    max-height: 320px;
    padding: 0rem 1rem 0.5rem;
    border-radius: 12px 12px 0 0;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    overflow: overlay;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
    border: 1px solid #cbcdd3;
    .suggestionHeader {
      padding: 0.6rem 0;
      background-color: ${({ theme }) =>
    theme?.components?.user_input.normal.background.color};
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      font-weight: 500;
      letter-spacing: 0.5px;
      font-size: 15px;
      color: #7e8392;
      position: sticky;

      top: 0;
      .MuiIconButton-root {
        font-size: 16px;
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          font-size: 22px;
        }
      }
    }

    .suggestionBoxSingleRow {
      /* background-color: #f5f6f8; */
      border-bottom: 1px solid #cbcdd3;
      color: ${({ theme }) => theme?.components?.bot_message?.text?.color};
      width: 100%;
      height: 32px;
      min-height: 32px;
      padding: 5px 0.5rem;
      font-size: 15.5px;

      cursor: pointer;
      border-radius: 4px;

      overflow: hidden;

      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      align-items: center;
      justify-content: space-between;

      &:hover {
        background-color: #ededf1;
      }

      :nth-last-child(1) {
        border-bottom: none;
      }
    }

    /* -ms-overflow-style: none; IE and Edge */
    /* scrollbar-width: none; Firefox */

    /* Hide scrollbar for Chrome, Safari and Opera */
    ::-webkit-scrollbar {
      /* display: none; */

      width: 4px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
`;

export const StyledInputField = styled.input<InputRef>`
  && {
    background-color: ${({ theme }) =>
    theme?.components?.user_input.normal.background.color};
    display: flex;
    align-items: center;
    position: relative;
    height: 42px;
    width: 100%;
    outline: none;
    border: none;
    padding: 0px 1rem 0px 1rem;
    font-family: ${({ theme }) =>
    theme?.components?.user_input.normal.text.family};
    font-weight: 400;
    font-size: 0.95rem;
    line-height: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &::placeholder {
      color: ${({ theme }) =>
    theme?.components?.user_input.placeholder.text.color};
    }

    &:active {
      color: ${({ theme }) => theme?.components?.user_input.focus.text.color};
    }
    &:focus {
      color: ${({ theme }) => theme?.components?.user_input.focus.text.color};
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
      margin: 0 !important;
    }
  }
`;
export const StyledTextAreaField = styled.textarea`
  && {
    background-color: ${({ theme }) => {
    return theme?.components?.chat_wall.background.color;
  }};
    background-color: ${({ theme }) =>
    theme?.components?.user_input.normal.background.color};
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    outline: none;
    border: none;
    resize: none !important;
    padding: 1rem;
    border-radius: 8px;
    font-family: ${({ theme }) =>
    theme?.components?.user_input.normal.text.family};
    font-weight: 400;
    font-size: 1rem;
    line-height: 22px;

    &::placeholder {
      color: ${({ theme }) =>
    theme?.components?.user_input.placeholder.text.color};
    }

    &:active {
      color: ${({ theme }) => theme?.components?.user_input.focus.text.color};
    }
    &:focus {
      color: ${({ theme }) => theme?.components?.user_input.focus.text.color};
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
      margin: 0 !important;
    }
  }
`;
export const StyledSelectField = styled(Select)`
  && {
    .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
      min-height: 0px;
    }

    .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
      padding: 0 14px !important;
    }

    fieldset {
      border: none !important;
      outline: none !important;
      display: none !important;
    }
    legend {
      display: none !important;
    }
    input {
      display: none !important;
    }

    .MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
      padding: 0 !important;
      max-height: 3rem !important;
    }
    background-color: ${({ theme }) =>
    theme?.components?.user_input.normal.background.color};
    display: flex;
    align-items: center;
    position: relative;
    height: 42px !important;
    max-height: 44px !important;
    width: 100%;
    outline: none;
    border: none;

    padding: 0px 3.1rem 0px 3rem;

    font-family: ${({ theme }) =>
    theme?.components?.user_input.normal.text.family};
    font-weight: 400;
    font-size: 0.95rem;
    line-height: 18px;

    &::placeholder {
      color: ${({ theme }) =>
    theme?.components?.user_input.placeholder.text.color} !important;
    }

    &:active {
      border: 2px solid transparent !important;
      color: ${({ theme }) => theme?.components?.user_input.focus.text.color};
    }
    &:focus {
      border: 2px solid transparent !important;
      color: ${({ theme }) => theme?.components?.user_input.focus.text.color};
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
      margin: 0 !important;
    }
  }
`;

export const StyledMobileInput = styled(PhoneInput)`
  && {
    width: 100%;

    .special-label {
      display: none !important;
    }

    .form-control {
      background-color: ${({ theme }) =>
    theme?.components?.user_input.normal.background.color};

      display: flex;
      align-items: center;
      position: relative;
      height: 42px;
      width: 100%;
      outline: none;
      border: none;
      padding: 0px 3.1rem 0px 4rem;

      font-family: ${({ theme }) =>
    theme?.components?.user_input.normal.text.family};
      font-weight: 400;
      font-size: 1rem;
      line-height: 18px;
      border: 2px solid transparent;
      &::placeholder {
        color: ${({ theme }) =>
    theme?.components?.user_input.placeholder.text.color};
      }

      &:active {
        border: 2px solid transparent;
        color: ${({ theme }) => theme?.components?.user_input.focus.text.color};
      }
      &:focus {
        border: 2px solid transparent;
        color: ${({ theme }) => theme?.components?.user_input.focus.text.color};
      }

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none !important;
        margin: 0 !important;
      }
    }
    .invalid-number {
    }

    .flag-dropdown {
      width: 3.5rem;
      height: 38px;
      position: absolute;

      left: 3px;
      top: 2px;
      border-top-left-radius: 23px;
      border-bottom-left-radius: 23px;
      z-index: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      outline: none;
      background-color: ${({ theme }) =>
    theme?.components?.user_input.normal.background.color};
      &:hover {
        background-color: ${({ theme }) =>
    theme?.components?.user_input.normal.background.color};
      }

      .selected-flag {
        background-color: ${({ theme }) =>
    theme?.components?.user_input.normal.background.color};
        &:hover {
          background-color: ${({ theme }) =>
    theme?.components?.user_input.normal.background.color};
        }
      }
    }

    .country-list {
      outline: none;
      z-index: 1;
      list-style: none;
      position: absolute;
      padding: 0;
      margin: 10px 0 3rem -1px;
      box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.35);
      background-color: #fafafa;
      width: 300px;
      max-height: 220px;
      overflow-y: scroll;
      border-radius: 6px;
      left: 10%;
      bottom: 0;
      overflow-x: hidden;
      z-index: 333333333;
      .search {
        padding: 10px 0 16px 10px;
        background-color: ${({ theme }) =>
    theme?.components?.user_input.normal.background.color};
      }

      .search-box {
        width: 95%;
      }
      /* -ms-overflow-style: none;
    scrollbar-width: none; */

      /* Hide scrollbar for Chrome, Safari and Opera */
      ::-webkit-scrollbar {
        /* display: none; */
        width: 4px;
      }
      /* Track */
      ::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
  }
`;

export const StyledUploadedMediaBox = styled.div`
  && {
    display: flex;
    background-color: ${({ theme }) =>
    theme?.components?.user_input.normal.background.color};

    width: 100%;
    border: 1px solid #ededed;
    /* border-bottom: none; */
    border-radius: 6px 6px 0 0;
    /* position: absolute; */
    /* bottom: 48px; */
    z-index: 1;
    padding: 0 0.5rem 0 1rem;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    font-size: 14px;
    p {
      color: ${theme.palette.default.text};
      width: 100%;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    svg {
      font-size: 1.1rem;
      color: ${theme.palette.default.text};
    }
  }
`;

import SendRoundedIcon from "@mui/icons-material/SendRounded";
import { IconButton } from "@mui/material";
import React from "react";
import { useAppSelector } from "../../../Store";
import { allMessagesStore } from "../../../Store/Slices/socket/AllMessagesSlice";

interface Props {
  children?: React.ReactNode;
  [otherProps: string]: any;
}
const AppSendSubmitButton: React.FC<Props> = ({ ...otherProps }) => {
  const { typingStatus } = useAppSelector(allMessagesStore);

  return (
    <div className="inputActionBox">
      <IconButton
        type="submit"
        {...otherProps}
        disabled={!typingStatus ? false : true}
      >
          <SendRoundedIcon/>
      </IconButton>
    </div>
  );
};

export default AppSendSubmitButton;

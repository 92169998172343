export enum OEvents {
  CHATBOT_RESIZE = "chatbot_resize",
  CHATBOT_OPEN = "chatbot_open",
  CHATBOT_HOST_URL_REDIRECT = "chatbot_host_url_redirect",
  CHATBOT_SESSION_CHANGE = "chatbot_session_change",
  CHATBOT_CONFIGURATION = "chatbot_configuration",
  TRACKING_EVENTS = "tracking_events",
  LIVECHAT_SESSION_CHANGE = "livechat_session_change",
  CHATBOT_SESSION_ID = "chatbot_session_id",
}

export enum IEvents {
  CHATBOT_START = "chatbot_start",
  CHATBOT_SAVE_VISITOR_ID = "chatbot_save_visitor_id",
  PARENT_WINDOW_RESIZE = "parent_window_resize",
  LOCATION = "location",
}

export const INCOMING_EVENTS = new Set([
  IEvents.CHATBOT_START,
  IEvents.CHATBOT_SAVE_VISITOR_ID,
  IEvents.PARENT_WINDOW_RESIZE,
  IEvents.LOCATION,
]);
export const OUTGOING_EVENTS = new Set([
  OEvents.CHATBOT_RESIZE,
  OEvents.CHATBOT_OPEN,
  OEvents.CHATBOT_HOST_URL_REDIRECT,
  OEvents.CHATBOT_SESSION_CHANGE,
  OEvents.CHATBOT_CONFIGURATION,
  OEvents.TRACKING_EVENTS,
  OEvents.LIVECHAT_SESSION_CHANGE,
  OEvents.CHATBOT_SESSION_ID,
]);

export class CommMessage {
  event_type: IEvents | OEvents;
  data: any;

  constructor(event_type: IEvents | OEvents, data: any) {
    this.event_type = event_type;
    this.data = data;
  }
}

export enum TrackingEventTypes {
  GTM_CONVERSION = "gtm_conversion",
  MATOMO_CONVERSION = "matomo_conversion",
  MATOMO_SET_USER_ID = "matomo_set_user_id",
}

export enum MatomoEvent {
  CATEGORY = "chatbot",
  CHAT_OPEN_ACTION = "chat_open",
  CHAT_OPEN_NAME = "user_chat_open",

  CHAT_CONVERSION_ACTION = "chat_conversion",
  CHAT_CONVERSION_NAME = "user_chat_conversion",
}

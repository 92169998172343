import axios from "axios";

const UNAUTHORIZED = 401

const addLoginHeaders = (config: any) => {
  // const data = AppConfig.getLoggedInData();
  // if (data) {
  //   const token = data?.token;
  //   const domain = data?.data?.business.domain;

  //   if (token && token.length > 0) {
  //     config.headers["Authorization"] = token;
  //   }
  //   if (domain && domain.length > 0) {
  //     config.headers["GETA-HOST"] = domain;
  //   }
  // }
  // const getaHost = AppConfig.getGetaHost();
  // if (getaHost) {
  //   if (getaHost && getaHost.length > 0) {
  //     config.headers["GETA-HOST"] = getaHost;
  //   }
  // }
 
  return config;
}
let coreBaseUrl = process.env.REACT_APP_CORE_API_BASE_URL ? process.env.REACT_APP_CORE_API_BASE_URL : 'http://core.local:8000/';
coreBaseUrl = coreBaseUrl + 'api/v1/'
let conversationBaseUrl = process.env.REACT_APP_CH_BOT_API_BASE_URL + 'api/v1/conversation/' ? process.env.REACT_APP_CH_BOT_API_BASE_URL : 'http://core.local:8002/';
conversationBaseUrl = conversationBaseUrl + 'api/v1/conversation/'
let consoleBaseUrl = process.env.REACT_APP_CH_CONSOLE_API_BASE_URL + 'api/v1/console/' ? process.env.REACT_APP_CH_CONSOLE_API_BASE_URL : 'http://core.local:8001/';
consoleBaseUrl = consoleBaseUrl + 'api/v1/'

const coreAxios = axios.create({
  baseURL: coreBaseUrl,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json;charset=UTF-8",
  },
});

const conversationAxios = axios.create({
  baseURL: conversationBaseUrl,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json;charset=UTF-8",
  },
});
const chatbotConsoleAxios = axios.create({
  baseURL: consoleBaseUrl,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json;charset=UTF-8",
  },
});
const matomoAxios = axios.create({
  baseURL: process.env.REACT_APP_MATOMO_API_BASE_URL ? process.env.REACT_APP_MATOMO_API_BASE_URL : 'http://core.local:8080/',
  headers: {
    Accept: "*/*"
  }
});

//request interceptors for core api
coreAxios.interceptors.request.use(
  function (config: any) {
    config = addLoginHeaders(config);
    // console.log("config", config);
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

//response interceptors for core api
coreAxios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  // 
  // console.log("error", error);
  if (error.hasOwnProperty('response')) {
    const { status } = error.response;
    if (status === UNAUTHORIZED) {
      // store.dispatch(logoutAuth(null));
    }
  }
  return Promise.reject(error);
}
);

//request interceptors for conversation api
conversationAxios.interceptors.request.use(
  function (config: any) {
    config = addLoginHeaders(config);
    // console.log("config", config);
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

//response interceptors for conversation api
conversationAxios.interceptors.response.use(
  response => response,
  error => {
    // 
    // console.log("error", error);
    if (error.hasOwnProperty('response')) {
      const { status } = error.response;
      if (status === UNAUTHORIZED) {
        // store.dispatch(logoutAuth(null));
      }
    }
    return Promise.reject(error);
  }
);

//request interceptors for chatbot-console api
chatbotConsoleAxios.interceptors.request.use(
  function (config: any) {
    config = addLoginHeaders(config);
    // console.log("config", config);
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

//response interceptors for chatbot-console api
chatbotConsoleAxios.interceptors.response.use(
  response => response,
  error => {
    // 
    // console.log("error", error);
    if (error.hasOwnProperty('response')) {
      const { status } = error.response;
      if (status === UNAUTHORIZED) {
        // store.dispatch(logoutAuth(null));
      }
    }
    return Promise.reject(error);
  }
);


export { coreAxios, conversationAxios, chatbotConsoleAxios, matomoAxios };

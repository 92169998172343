import { FastField, getIn, useFormikContext } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { theme } from "../../../Customization/Theme";
import {
  StyledMobileInput,
  StyledWidgetInputBox,
} from "../../Styles/StyledForms";
import { useAppSelector } from "../../../Store";

import { allMessagesStore } from "../../../Store/Slices/socket/AllMessagesSlice";
import AppErrorMessage from "./AppErrorMessage";
import AppSendSubmitButton from "./AppSendSubmitButton";
import "react-phone-input-2/lib/style.css";
import { useBotConfigStore } from "../../../Store/Slices/ChatbotSlices/BotConfigSlice";
import AppVoiceButton from "./AppVoiceButton";

const DefaultIndiaMobileFormat = {
  countryCode: "91",
  dialCode: "91",
  format: "+.. .....-.....",
  iso2: "in",
  name: "India",
  priority: 0,
  regions: ["asia"],
};

interface Props {
  name?: string;
  label?: string;
  mobileCountryFormat?: string;
  children?: React.ReactNode;
  [otherProps: string]: any;
  ref?: any;
}

const AppFlowMobileField: React.FC<Props> = ({
  name = "mobile",
  label = "+91 99999 00000",
  mobileCountryFormat = "mobileCountryFormat",
  children,
  ...otherProps
}) => {
  const { errors, touched, values, setFieldValue, handleSubmit } =
    useFormikContext<any>();
  const { typingStatus } = useAppSelector(allMessagesStore);
  const { botConfig } = useAppSelector(useBotConfigStore);

  let [mobileCountry] = useState<any>(DefaultIndiaMobileFormat);

  const handleValueChange = (e: any) => {
    setFieldValue(mobileCountryFormat, mobileCountry);
    //do not remove settimeout
    setTimeout(() => {
      setFieldValue(name, e);
    }, 1);
  };

  let temp: any =
    typeof getIn(values, name) == "number"
      ? getIn(values, name).toString()
      : getIn(values, name);

  useEffect(() => {
    setFieldValue("mobileCountryFormat", DefaultIndiaMobileFormat);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const inputRef: any = useRef<any>();

  useEffect(() => {
    if (inputRef?.current && !typingStatus) {
      inputRef?.current?.focus();
    }
  }, [inputRef, typingStatus]);

  return (
    <StyledWidgetInputBox
      disabled={typingStatus ? true : false}
      error={getIn(errors, name) && getIn(touched, name)}
    >
      <FastField name={name}>
        {({ field }: any) => (
          <StyledMobileInput
            {...otherProps}
            {...field}
            value={temp || ""}
            onChange={(e: any) => handleValueChange(e)}
            isValid={(value: any, country: any) => {
              mobileCountry = country;
              return true;
            }}
            onEnterKeyPress={() => handleSubmit(values)}
            countryCodeEditable={false}
            placeholder={label}
            enableLongNumbers={17}
            country={botConfig?.config?.default_country || "in"}
            searchPlaceholder="Search countries or dial code"
            autocompleteSearch
            enableSearch
            disableSearchIcon
            inputProps={{
              required: true,
              autoFocus: true,
              name: "mobile",
              autoComplete: "off",
            }}
          />
        )}
      </FastField>

      {/* {getIn(values, name)?.length > 0 ? (
        <AppSendSubmitButton />
      ) : (
        <AppVoiceButton name={name} />
      )} */}
      <AppSendSubmitButton />

      {/* <AppSendSubmitButton
      /> */}

      <AppErrorMessage
        error={getIn(errors, name)}
        visible={getIn(touched, name)}
      />
    </StyledWidgetInputBox>
  );
};

export default AppFlowMobileField;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { INPUT_TYPES } from "../../../Models/Enum";

interface Props {
  inputReadyStatus: boolean;
}

let INITIAL_STATE: Props = {
  inputReadyStatus: false
};

const InputReadyStatusSlice = createSlice({
  name: "InputReadyStatusSlice",
  initialState: INITIAL_STATE,
  reducers: {
    ChangeInputReadyStatus(state, { payload }: PayloadAction<Props>) {
      // console.log("ChangeInputReadyStatus", payload);
      return payload;
    },
    // RemoveChatWindowStatus() {
    //   console.log(" RemoveChatWindowStatus");
    //   return INITIAL_STATE;
    // },
  },
});

export default InputReadyStatusSlice.reducer;
export const { ChangeInputReadyStatus } = InputReadyStatusSlice.actions;

export const useInputReadyStatusStore = (state: any) => {
  return { ...(state.botData.currrentInputReadyStatus as Props) };
};

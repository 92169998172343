import { FastField, getIn, useFormikContext } from "formik";
import React, { useEffect, useRef } from "react";
import { theme } from "../../../../Customization/Theme";
import { useAppSelector } from "../../../../Store";
import {
  InputAndUploadWrap,
  StyledInputField,
  StyledUploadedMediaBox,
  StyledWidgetInputBox,
} from "../../../Styles/StyledForms";

import { allMessagesStore } from "../../../../Store/Slices/socket/AllMessagesSlice";
import { socketStatusStore } from "../../../../Store/Slices/socket/SocketSlice";
import AppErrorMessage from ".././AppErrorMessage";
import AppSendSubmitButton from ".././AppSendSubmitButton";
import EditorMediaUpload from "./EditorMediaUpload";
import { AppMaterialIcons } from "../../Icons";
import { Box, Divider, IconButton } from "@mui/material";
import AppVoiceButton from "../AppVoiceButton";

interface Props {
  name?: string;
  label?: string;
  children?: React.ReactNode;
  [otherProps: string]: any;
  ref?: any;
}

const AppFlowLiveChatField: React.FC<Props> = ({
  name = "livechat",
  label = "Enter message",
  children,
  ...otherProps
}) => {
  const {
    errors,
    touched,
    values,
    handleChange,
    setFieldValue,
    setFieldError,
  } = useFormikContext<any>();
  const { typingStatus } = useAppSelector(allMessagesStore);
  const { socket } = useAppSelector(socketStatusStore);
  // useTypingStatus(socket)
  const inputRef: any = useRef<any>();

  useEffect(() => {
    if (inputRef?.current && !typingStatus) {
      inputRef?.current?.focus();
    }
  }, [inputRef, typingStatus]);
  useEffect(() => {
    if (inputRef?.current) {
      inputRef?.current?.focus();
    }
  }, [getIn(values, "media")]); // eslint-disable-line react-hooks/exhaustive-deps

  // console.log("getIn(values", getIn(values, "media"), getIn(errors, "media"));

  return (
    <>
      {getIn(errors, "media") ? (
        <StyledUploadedMediaBox style={{ border: "1px solid red" }}>
          <p>{getIn(errors, "media")}</p>
          <IconButton
            title={"Clear"}
            onClick={() => {
              setFieldValue("media", null);
              setFieldError("media", undefined);
            }}
          >
            <AppMaterialIcons iconName={"Close"} />
          </IconButton>
        </StyledUploadedMediaBox>
      ) : null}
      {getIn(values, "media")?.name && getIn(values, "media")?.source ? (
        <StyledUploadedMediaBox>
          <p>{getIn(values, "media")?.name}</p>
          <IconButton
            title={"Clear"}
            onClick={() => setFieldValue("media", null)}
          >
            <AppMaterialIcons iconName={"Close"} />
          </IconButton>
        </StyledUploadedMediaBox>
      ) : null}
      <StyledWidgetInputBox
        disabled={typingStatus ? true : false}
        error={getIn(errors, name) && getIn(touched, name)}
      >
        <InputAndUploadWrap>
          <FastField name={name}>
            {({ field }: any) => (
              <StyledInputField
                style={{
                  paddingRight: "50px"
                }}
                {...otherProps}
                {...field}
                value={getIn(values, name) || ""}
                onChange={(e: any) => {
                  handleChange(e);
                }}
                ref={inputRef}
                placeholder={label}
                autoFocus={true}
              />
            )}
          </FastField>
          <EditorMediaUpload />
        </InputAndUploadWrap>
        {/* {getIn(values, name)?.length > 0 ? (
          <AppSendSubmitButton />
        ) : (
          <AppVoiceButton name={name} />
        )} */}
        <AppSendSubmitButton />
        {/* <AppSendSubmitButton /> */}
        <AppErrorMessage
          error={getIn(errors, name)}
          visible={getIn(touched, name)}
        />
      </StyledWidgetInputBox>
    </>
  );
};

export default AppFlowLiveChatField;

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../Store";
import {
  allMessagesStore,
  removeMessage,
  setAllHistoryMessages,
  setAllMessages,
  setIsSessionIdFromLocalStorage,
  setLastNodeResponse,
  setOriginalResponses,
  setSessionId,
  setTypingStatus,
} from "../../Store/Slices/socket/AllMessagesSlice";
import { useBotConfigStore } from "../../Store/Slices/ChatbotSlices/BotConfigSlice";
import { sleep } from "../utils/utils";
import { PreviewType } from "../utils/Enum";
import { botInfoStore } from "../../Store/Slices/socket/BotInfoSlice";
import { HostWindowCommService } from "../../Services/IframeHostService/HostWindowCommService";
import { OEvents } from "../../Services/IframeHostService/models";
import { ConversationService } from "../../Services/Apis";
import { socketStatusStore } from "../../Store/Slices/socket/SocketSlice";

let audio: any = new Audio(require("../../assets/tong.mp3"));
const SLEEP_TIME = 500;

const useMessagesData = () => {
  const commService = useMemo(() => {
    return HostWindowCommService.getInstance();
  }, []);

  //redux
  const dispatch = useAppDispatch();
  const { botConfig } = useAppSelector(useBotConfigStore);

  const { botId, visitorId, widgetPreviewType, getaHost } = useAppSelector(botInfoStore);
  const { executeConnectSocketEventWithReConnectUser } = useAppSelector(socketStatusStore);
  const [loading, setLoading] = useState(false)


  const { sessionId, botLanguage } =
    useAppSelector(allMessagesStore);
  const botName = botConfig.chatbot?.name ?? "Bot";
  const botHeaders = useMemo(
    () => ({
      appid: JSON.stringify({
        botid: botId,
      }),
      "geta-host": getaHost,
      visitor_id: visitorId || null,
      preview:
        widgetPreviewType === PreviewType.getaWidgetPreview ? true : false,
      connection_type: "user",
    }),
    [botId, visitorId, widgetPreviewType, getaHost]
  );

  // Create a ref for sessionId
  const sessionIdRef = useRef(sessionId);
  // Update the ref whenever sessionId changes to avoid closures scopes below.
  useEffect(() => {
    sessionIdRef.current = sessionId;
  }, [sessionId]);

  // Create a ref for botLanguage
  const botLanguageRef = useRef(botLanguage);
  // Update the ref whenever botLanguage changes to avoid closures scopes below.
  useEffect(() => {
    botLanguageRef.current = botLanguage;
  }, [botLanguage]);

  const tempRequestData: any = {
    session_id: sessionIdRef.current,
    language: botLanguageRef.current,
  };

  const storeSessionId = (id: string) => {
    if (id === sessionIdRef.current) return;

    dispatch(setSessionId(id));
    dispatch(setIsSessionIdFromLocalStorage(false));

    //* do not store session id in local storage if widget preview type is getaWidgetPreview
    let tempId =
      widgetPreviewType !== PreviewType.getaWidgetPreview ? id : null ?? null;

    commService.postMessage({
      event_type: OEvents.CHATBOT_SESSION_ID,
      data: tempId,
    });
  };

  const handleNewMessages = async (data: any) => {
    if (!data) return;
    if (!Array.isArray(data)) return;

    dispatch(setTypingStatus(`${botName} is typing...`));
    for (let element of data) {
      await sleep(SLEEP_TIME);
      if (element?.["subType"] === "button") {
        let temp = {
          value: element?.["response"],
          type: "button",
          time: element?.["createdAt"],
        };
        dispatch(setAllMessages(temp));
      } else {
        dispatch(setAllMessages(element?.response));
      }
      dispatch(setLastNodeResponse(element));
      dispatch(setOriginalResponses(element));
    }

    //set typing status
    dispatch(setTypingStatus(``));
    //sound
    playSoundLastBotMsg();

    //store session id
    const newSessionId = data?.[data?.length - 1]?.session_id ?? "";
    storeSessionId(newSessionId);
  };

  const handleHistoryMessages = async (history: any) => {
    if (
      !history ||
      history?.chat_data?.length === 0 ||
      !Array.isArray(history?.chat_data)
    ) {
      console.warn("chatData not found");
      dispatch(setSessionId(null));
      dispatch(setIsSessionIdFromLocalStorage(false));
      getNewMessages({
        ...tempRequestData,
        session_id: null,
      });;
      return;
    }

    if (history?.user?.botid !== botId) {
      console.warn("bot id not matched");
      getNewMessages({
        ...tempRequestData,
        session_id: null,
      });
      return;
    }

    let payload: any = [];

    history?.chat_data?.forEach((item: any, index: any) => {
      if (item?.["user_query_object"]) {
        let temp = {
          ...item?.["user_query_object"],
          time: item?.["createdAt"],
        };
        payload.push(temp);
      }


      if (item?.["bot_response"]?.["response"]?.length > 0) {
        //single card
        if (item?.["bot_response"]?.["subType"] === "button") {
          let temp = {
            value: item?.["bot_response"]?.["response"],
            type: "button",
            time: item?.["createdAt"],
          };
          payload.push(temp);
        }
        else {
          item?.["bot_response"]?.["response"]?.forEach((curRes: any) => {
            let temp = {
              ...curRes,
              time: item?.["createdAt"],
            };
            payload.push(temp);
          });
        }
      }

      if (index + 1 === history?.chat_data?.length) {
        dispatch(
          setLastNodeResponse(
            item?.["user_query_object"] ?? item?.["bot_response"]
          )
        );
      }

      dispatch(setOriginalResponses(item?.["bot_response"]));
    });

    dispatch(setAllHistoryMessages(payload));
    dispatch(setIsSessionIdFromLocalStorage(true));

    if (history?.user?.agent?.length > 0) {
      console.error("agent found");
      executeConnectSocketEventWithReConnectUser();
    }
    await sleep(500);
    setLoading(false);
  };

  // --------------------------------API CALLS------------------

  //================= GET BOT MESSAGES =================
  const getNewMessages = (request: any) => {
    return new Promise((resolve, reject) => {
      ConversationService.getMessages(request, botHeaders, widgetPreviewType)
      .then(async (response) => {
        handleNewMessages(response?.data);
        resolve(response);
      })
      .catch((error) => {
        console.error("get messages api:", error);
        reject(error);
      })
        .finally(() => {
      });
    });
  };

  //================= GET BOT HISTORY MESSAGES =================
  const getHistoryMessages = async(request: any) => {
    return new Promise(async (resolve, reject) => {
      setLoading(true);
    ConversationService.getHistoryMessages(request, botHeaders)
    .then(async (response) => {
        handleHistoryMessages(response?.data?.data);
        resolve(response);
        })
        .catch((error) => {
          console.error("history get messages api:", error);
          reject(error);
        })
        .finally(() => {
         });
    });
  };

  //================= POST BOT USER QUERY =================
  const postBotUserQuery = (data: any) => {
    dispatch(setTypingStatus(`${botName} is typing...`));
    ConversationService.postMessages(data, botHeaders, widgetPreviewType)
      .then(async (response) => {
        handleNewMessages(response?.data?.payload);
      })
      .catch((error) => {
        console.error("message not sent:", error);
        dispatch(removeMessage(data));
      })
      .finally(() => { });
  };

  //================= PLAY SOUND =================
  const playSoundLastBotMsg = useCallback(() => {
    if (botConfig.config?.message_tone?.enabled) {
      audio.volume = (botConfig.config?.message_tone?.volume_level || 70) / 100;
      audio.play();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    postBotUserQuery,
    getHistoryMessages,
    getNewMessages,
    loading
  };
};

export default useMessagesData;

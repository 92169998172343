import React from "react";
import uniqid from "uniqid";
import AppMessageBoxWrapper from "../UtilsComponents/AppMessageBoxWrapper";
import AppSmallButton from "../UtilsComponents/AppSmallButton";
import { getCurrentUTCTime } from "../../utils/utils";
import useMessagesData from "../../hooks/useMessagesData";
import { useAppSelector } from "../../../Store";
import { allMessagesStore } from "../../../Store/Slices/socket/AllMessagesSlice";
import AppRenderNestedText from "./nestedFlow/AppRenderNestedText";
import { useBotConfigStore } from "../../../Store/Slices/ChatbotSlices/BotConfigSlice";

interface Props {
  userMessage?: boolean;
  children?: any;
  time?: string;
  readStatus?: "sent" | "delivered" | "seen";
  [otherProps: string]: any;
}
const AppRenderReturningSession: React.FC<Props> = React.memo(
  ({
    userMessage = false,
    children = "Hey! This is not the first time you are here. What would you like to do?",
    time = getCurrentUTCTime(),
    readStatus = "delivered",
    ...otherProps
  }) => {
    //redux
    const { sessionId, botLanguage } = useAppSelector(allMessagesStore);
    const { botConfig } = useAppSelector(useBotConfigStore);
    const conversationLimit = botConfig?.config?.conversation;

    //custom hooks
    const { getNewMessages, getHistoryMessages } = useMessagesData();

    const requestData = {
      session_id: sessionId,
      language: botLanguage,
    };
    const handleStartAgainBtn = () => {
      let temp = {
        session_id: null,
        language: botLanguage,
      };
      getNewMessages(temp);
    }; 
    
    const handleContinueChatBtn = () => {
      getHistoryMessages(requestData);
    };

    if (!children || children?.length < 1) {
      return null;
    }

    if (conversationLimit?.enabled) { 
      return null;
    }

    // if (conversationLimit?.enabled) {
    //   return (
    //     <AppMessageBoxWrapper
    //       key={uniqid()}
    //       userMessage={userMessage}
    //       time={time}
    //       isMedia={false}
    //       isButton={false}
    //       divStyle={{
    //         maxWidth: "100%",
    //         flexDirection: "row",
    //         gap: "12px",
    //         padding: "10px 12px",
    //         backgroundColor: "#fff",
    //         borderRadius: "8px",
    //       }}
    //     >
    //       <AppRenderNestedText>{children}</AppRenderNestedText>
    //       <AppSmallButton
    //         variant="primaryoutlinebutton"
    //         onClick={() => handleStartAgainBtn()}
    //         disableRipple={true}
    //       >
    //         Start Again 🔄
    //       </AppSmallButton>
    //       <AppSmallButton
    //         variant="primaryoutlinebutton"
    //         onClick={() => handleContinueChatBtn()}
    //         disableRipple={true}
    //       >
    //         Continue
    //       </AppSmallButton>
    //     </AppMessageBoxWrapper>
    //   );
    // }

    return (
      <AppMessageBoxWrapper
        key={uniqid()}
        userMessage={userMessage}
        time={time}
        isMedia={false}
        isButton={false}
        divStyle={{
          maxWidth: "100%",
          flexDirection: "row",
          gap: "12px",
          padding: "10px 12px",
          backgroundColor: "#fff",
          borderRadius: "8px",
        }}
      >
        <AppRenderNestedText>{conversationLimit?.message || "message credits expired."}</AppRenderNestedText>
      </AppMessageBoxWrapper>
    );
  }
);

export default AppRenderReturningSession;

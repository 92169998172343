import React, { memo } from "react";
import uniqid from "uniqid";
import { useAppDispatch, useAppSelector } from "../../../../Store";
import { useBotConfigStore } from "../../../../Store/Slices/ChatbotSlices/BotConfigSlice";
import {
  allMessagesStore,
  setAllMessages,
} from "../../../../Store/Slices/socket/AllMessagesSlice";
import { botInfoStore } from "../../../../Store/Slices/socket/BotInfoSlice";
import { socketStatusStore } from "../../../../Store/Slices/socket/SocketSlice";
import { MESSAGE_TYPES, PreviewType } from "../../../utils/Enum";
import { getCurrentUTCTime, isWithinBusinessHour } from "../../../utils/utils";
import AppSmallButton from "../../UtilsComponents/AppSmallButton";

interface Props {
  item: any;
  rowWrapperStyle?: React.CSSProperties | undefined;
  containerStyle?: React.CSSProperties | undefined;
  time: string;
  readStatus?: "sent" | "delivered" | "seen";
  [otherProps: string]: any;
}

const AppRenderNestedLiveButtons: React.FC<Props> = ({
  item,
  rowWrapperStyle,
  containerStyle,
  time,
  readStatus = "delivered",
  otherProps,
}) => {
  const dispatch = useAppDispatch();
  const { typingStatus, sessionId, liveChatConnected } =
    useAppSelector(allMessagesStore);
  const { socket, executeConnectSocketEventWithConnectUser } = useAppSelector(socketStatusStore);

  const { botConfig } = useAppSelector(useBotConfigStore);
  const { widgetPreviewType } = useAppSelector(botInfoStore);

  const handleLiveUserConnect = (item: any) => {

    if (liveChatConnected) {
      return console.warn("live chat already connected");
    }

    if (item?.type !== MESSAGE_TYPES.LIVE_AGENT_REQUESTED) {
      return console.warn("wrong button type recieved", item);
    }

    //detect if chat is within business hours
    const checkTimings = isWithinBusinessHour(botConfig.config.active_timings);

    const isChatWithInTime = checkTimings?.isChatWithInTime ?? true;
    const timelogs = checkTimings?.timelogs ?? {};

      let data = {
        type: "text",
        value: item?.value?.text ?? "live support requested",
        session_id: sessionId,
        isUser: true,
        socket_id: socket?.id,
        time: getCurrentUTCTime(),
        message_id: uniqid(),
        timelogs: timelogs,
      };
      dispatch(setAllMessages([data]));

      if (!isChatWithInTime) {
        handleOutsideBusinessTimeClick();
        return;
      }

      executeConnectSocketEventWithConnectUser(data);
  };

  const handleOutsideBusinessTimeClick = () => {
    let message =
      botConfig.config?.active_timings?.outside_business_hours_message ??
      "Sorry, we are not available at this time. Please try again later.";
    let data = {
      type: "text",
      value: message,
      session_id: sessionId,
      isUser: false,
      socket_id: socket?.id,
      time: getCurrentUTCTime(),
      message_id: uniqid(),
    };
    dispatch(setAllMessages([data]));
    // socket?.emit(SOCKET_CONNECTION_TYPES.BOT_CONVERSATION, data);
  };
  return (
    <AppSmallButton
      variant="primaryfilledbutton"
      style={{ pointerEvents: typingStatus ? "none" : "auto" }}
      onClick={() => handleLiveUserConnect(item)}
      disabled={liveChatConnected || widgetPreviewType === PreviewType.getaWidgetPreview ? true : false}
      title={widgetPreviewType === PreviewType.getaWidgetPreview ? "Live chat not available in preview mode" : liveChatConnected ? "Live chat already connected" : ""  }
    >
      {item?.value?.text}
    </AppSmallButton>
  );
};

export default memo(AppRenderNestedLiveButtons);

import { useSelector } from "react-redux";
import botConfiguration from "../../Customization/botConfiguration";
import { useBotConfigStore } from "../../Store/Slices/ChatbotSlices/BotConfigSlice";
import { WidgetCopyrightBox } from "../Styles/StyledScreens";

interface Props {
  // developer?: any;
}

const WidgetCopyright: React.FC<Props> = () => {
  const { botConfig } = useSelector(useBotConfigStore);

  return (
      <WidgetCopyrightBox>
        Powered by
        <a href={`https://geta.ai/`} target="_blank" rel="noreferrer">
          {botConfig.config?.remove_branding ? (
            botConfig?.chatbot?.business?.name
          ) : (
            <img src={botConfiguration.FOOTER_LOGO} alt="" />
          )}
        </a>
      </WidgetCopyrightBox>
  );
};

export default WidgetCopyright;

import React from "react";
import {
  StyledInfoMessageText,
  StyledMessageRow,
  StyledMessageTimeStamps,
} from "./../../Styles/StyledComponents";

import styled from "styled-components";
import { getMessageTime } from "../../utils/utils";

interface Props {
  userMessage: boolean;
  children: any;
  time: string;
  readStatus?: "sent" | "delivered" | "seen";
  [otherProps: string]: any;
}
const AppRenderInfo: React.FC<Props> = React.memo(
  ({ userMessage = true, time, children, readStatus='delivered' , ...otherProps }) => {
    if (!children || children?.length < 1 || userMessage) {
      return null;
    }

    return (
      <ExtendedStyledMessageRow
        className="styledMessageRow">
        <StyledInfoMessageText
          dangerouslySetInnerHTML={{
            __html: children,
          }}
        />
        <StyledMessageTimeStamps
          userMessage={userMessage}
          style={{ justifyContent: "center" }}
        >
          {getMessageTime(time)}
        </StyledMessageTimeStamps>
      </ExtendedStyledMessageRow>
    );
  }
);

export default AppRenderInfo;

const ExtendedStyledMessageRow = styled(StyledMessageRow)`
  && {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0px;
    border: none;
  }
`;

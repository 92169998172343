import SecureLS from "secure-ls";
const encodingType = process.env.NODE_ENV === "development" ? "" : "des";
let storage: any = null;

class LocalStorage {
  set = function (key: string, value: any) {
    storage.set(key, value);
  };

  get = function (key: string) {
    return storage.get(key) || {};
  };

  getAllKeys = function () {
    storage.getAllKeys();
  };

  removeAll = function () {
    storage.removeAll();
  };

  remove = function (key: string) {
    storage.remove(key);
  };

  getOrSet = function (key: string, value: any) {
    let result = storage.get(key);
    if (!result) {
      storage.set(key, value);
      result = value;
    }
    return result;
  };
}
class MockLocalStorage {
  storageMap: Map<string, string>;

  constructor() {
    this.storageMap = new Map();
  }
  set(key: string, value: any) {
    this.storageMap.set(key, value);
  };

  get(key: string) {
    return this.storageMap.get(key) || {};
  };

  getAllKeys() {
    return Array.from(this.storageMap.keys());
  };

  removeAll() {
    this.storageMap.clear();
  };

  remove(key: string) {
    this.storageMap.delete(key);
  };

  getOrSet(key: string, value: any) {
    let result = this.storageMap.get(key);
    if (!result) {
      this.storageMap.set(key, value);
      result = value;
    }
    return result;
  };
}


try {
  const ls = window.localStorage;
  storage = new SecureLS({
    encodingType: encodingType,
    isCompression: false,
    encryptionSecret: process.env.REACT_APP_STORAGE_SECRET,
  })
} catch(err: any) {
  // console.log("Localstorage not found..defaulting to in memory");
  storage = new MockLocalStorage();
}


export { LocalStorage };

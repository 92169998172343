import styled, { StyledComponent, keyframes } from "styled-components";
import Slider from "react-slick";
import { theme } from "../../Customization/Theme";
import ReactPlayer from "react-player";
import { ColorUtils } from "../../Utils/ColorUtils";
import { DEVICE_BREAKPOINT } from "../utils/BreakPoints";
import { hexToRgb } from "../utils/utils";

interface StyledProps {
  userMessage?: any;
  tooltipPosition?: any;
  isTooltipVisible?: any;
  WIDGET_POSITION?: any;
  variant?: any;
  enableuserinput?: number;
  chatopened?: any;
  devicewidth?: any;
  deviceheight?: any;
  jumpPosition?: any;
  theme?: any;
  selected?: any;
  isInvalid?: any;
  isButton?: any;
  disabled?: boolean;
  isHideAfterArrowIcon?: boolean;
  ref?: any;
}

export const StyledBannerCarouselSlickSlider: StyledComponent<
  any,
  any,
  object,
  string | number | symbol
> = styled(Slider)`
  && {
    .slick-list {
      // height: 150px !important;
    }
    .slick-next {
      position: absolute;
      z-index: 99;
      top: 50%;
      transform: translate(0px, -50%);
      right: -2%;
      opacity: 0.8;
      background-color: transperant;
      // height: 24px;
      // width: 24px;
      padding: 0px;

      svg {
        color: ${theme.palette.default.text};
        background: ${theme.palette.default.lightGrey};
        border: 1px solid ${theme.palette.default.text};
        border-radius: 50%;
      }
    }
    .slick-prev {
      position: absolute;
      z-index: 99;
      top: 50%;
      transform: translate(0px, -50%);
      left: -2%;
      opacity: 0.8;
      background-color: transperant;
      // height: 24px;
      // width: 24px;
      padding: 0px;
      svg {
        color: ${theme.palette.default.text};
        border: 1px solid ${theme.palette.default.text};
        background: ${theme.palette.default.lightGrey};
        border-radius: 50%;
      }
    }

    .slick-dots {
      background-color: ${theme.palette.default.white};
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      gap: 0.8rem;
      margin-top: -6px;
      display: none !important;
      button {
        display: none;
      }
      li {
        color: ${theme.palette.default.darkGrey};
      }
      .slick-active {
        color: ${theme.palette.default.text};
      }
    }

    .sliderSingleItem {
      display: flex !important;
      flex-direction: column;
      gap: 4px;
      // border: 4px solid red;

      max-width: 100%;

      // overflow: hidden;
      object-fit: cover;
      height: 192px;

      /* justify-content: center; */
      .btnWrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 0.5rem;
      }

      .title {
        padding: 2px 6px 0px;
        padding-left: 18px;
        font-size: 0.9rem;
        font-weight: 500;
        text-overflow: ellipsis;
        font-family: ${({ theme }) =>
          theme?.components?.user_input.normal.text.family};

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      .description {
        padding: 0px 6px;
        padding-left: 14px;
        font-size: 0.9rem;
        text-overflow: ellipsis;
        font-family: ${({ theme }) =>
          theme?.components?.user_input.normal.text.family};

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 8;
        -webkit-box-orient: vertical;
      }

      img {
        // width: 100%;
        // height: 150px;
        // border-radius: 6px;
        // min-height: 130px;
        // max-height: 130px;
        // overflow: hidden;
        // border: none;
        // object-fit: contain;
        object-fit: cover;
        // border: 1px solid blue;
      }
    }
  }
`;

export const StyledSlickSlider: StyledComponent<
  any,
  any,
  object,
  string | number | symbol
> = styled(Slider)`
  && {
    .slick-track {
      display: flex !important;
    }

    .slick-slide {
      /* height: auto !important; */
    }

    .nextPreBtn {
      position: absolute;
      z-index: 99;
      top: 20%;
      opacity: 0.8;
      background-color: ${theme.palette.default.lightGrey};
      height: 24px;
      width: 24px;
      display: flex !important; // to override slick slider default inline display block
      justify-content: center;
      align-items: center;
      svg {
        color: ${theme.palette.default.text};
      }
      &:before {
        display: none;
      }
    }

    .slick-next {
      right: 5%;
    }
    .slick-prev {
      left: 5%;
    }

    .slick-dots {
      background-color: ${theme.palette.default.white};
      display: flex !important;
      width: 100%;
      justify-content: center;
      align-items: center;
      gap: 0.8rem;
      margin-top: -6px;
      button {
        display: none;
      }
      li {
        color: ${theme.palette.default.darkGrey};
      }
      .slick-active {
        color: ${theme.palette.default.text};
      }
    }
  }
`;

export const WidgetSliderBox = styled.div`
  && {
    background-color: transparent;
    background-color: ${theme.palette.secondary.main};
    border-radius: 6px 6px 6px 6px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 2px 4px 2px;
    margin: 4px 0;
    min-height: 100%;
    overflow: hidden;
    .btnWrapper {
      display: flex;
      // flex-direction: column;
      flex-wrap: wrap;
      gap: 10px;
      padding-top: 10px;
      width: 100%;
    }
    .title {
      padding: 6px 8px 2px;
      font-size: 0.95rem;
      letter-spacing: 0.5px;
      line-height: 24px;
      font-weight: 600;
      text-overflow: ellipsis;
      font-family: ${({ theme }) =>
        theme?.components?.user_input.normal.text.family};
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .subTitle {
      padding: 0px 8px 2px;
      font-size: 0.9rem;
      line-height: 20px;
      font-weight: 500;
      text-overflow: ellipsis;
      font-family: ${({ theme }) =>
        theme?.components?.user_input.normal.text.family};
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .description {
      padding: 0px 8px 2px;
      font-size: 0.9rem;
      line-height: 20px;
      font-weight: 300;
      text-overflow: ellipsis;
      font-family: ${({ theme }) =>
        theme?.components?.user_input.normal.text.family};
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 8;
      -webkit-box-orient: vertical;
    }
    .sliderImage {
      width: 100%;
      min-width: 100%;
      // height: 150px;
      border-radius: 4px;
      // min-height: 150px;
      max-height: 150px;
      overflow: hidden;
      border: none;
      /* object-fit: contain; */
      object-fit: fill;
      max-width: 284px;
      min-width: 284px;
    }
  }
`;

export const StyledLanguageWrapper = styled.div`
  && {
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 3px;

    .LanguageIconBox {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      gap: 4px;
      border-radius: 40px;
      padding: 0 6px 0 2px;
      overflow: hidden;

      width: 40px;
      overflow: hidden;
      transition: width 0.5s;
      -webkit-transition: width 0.5s;
      -moz-transition: width 0.5s;

      .currentLanguage {
        display: none;
      }

      &:hover,
      &:focus {
        background-color: rgba(156, 39, 176, 0.08);
        width: 96px;
        .currentLanguage {
          display: -webkit-box;
        }
      }
    }
    .active {
      background-color: rgba(156, 39, 176, 0.08);
      width: 96px;
      .currentLanguage {
        display: -webkit-box;
      }
    }

    .languageIcon {
      svg {
        font-size: 19px;
        color: ${({ theme }) => {
          return theme?.components?.header.text.color;
        }};
      }
    }
    .currentLanguage {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      cursor: pointer;
      font-family: ${({ theme }) =>
        theme?.components?.bot_message?.text?.family};
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.5px;
      color: ${({ theme }) => {
        return theme?.components?.header.text.color;
      }};
    }

    .languageListWrapper {
      list-style-type: none;
      .title {
        font-family: ${({ theme }) =>
          theme?.components?.bot_message?.text?.family};
        color: ${({ theme }) => theme?.components?.bot_message?.text?.color};
        font-weight: 400;

        font-size: 15px;
      }
      li {
        font-family: ${({ theme }) =>
          theme?.components?.bot_message?.text?.family};
        color: ${({ theme }) => theme?.components?.bot_message?.text?.color};
        font-weight: 400;
        min-height: 36px;
        font-size: 15px;
        padding: 4px 12px;
        margin-right: 1px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        border-radius: 4px;
        overflow: hidden;
        &:hover {
          background-color: ${({ theme }) => {
            return theme?.components?.chat_wall.background.color;
          }};
          transform: scale(1.01);
        }
      }
    }
  }
`;

export const StyledQuickMenuWrapper = styled.div`
  && {
    /* margin-bottom: 80px; */
    .MuiAccordion-root {
      box-shadow: none;
      border-radius: 0px;
      .MuiAccordionSummary-root {
        min-height: 44px;
        box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.08);
      }
      .MuiAccordionSummary-content {
        align-items: center;
        gap: 8px;
      }
      .Mui-expanded {
        margin: 0;
      }
      .MuiCollapse-root {
        overflow: hidden;
        overflow-y: overlay;
        max-height: 190px;
      }

      .MuiAccordionDetails-root {
        background-color: #fdfcfc;

        width: 100%;
        padding: 0.15rem 0;

        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        overflow: overlay;
        overflow-x: hidden;
        height: auto;
      }
    }
    .bodyContent {
    }

    .accordianTitle {
      font-family: ${({ theme }) =>
        theme?.components?.bot_message?.text?.family};
      font-weight: 500;
      font-size: 0.85rem;
      
      text-transform: capitalize;
      /* color: ${({ theme }) =>
        theme?.components?.bot_message?.text?.color}; */
      /* opacity: 0.9; */
      color: #606060;
    }


    .singleCard {
      width: 33.2%;

      height: 90px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 6px;

      overflow: hidden;
      cursor: pointer;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
      border: 1px solid rgba(0, 0, 0, 0.08);
      border: 1px solid transparent;
      text-align: center;
      padding: 6px 2px 2px;
      border: 1px solid rgba(0, 0, 0, 0.08);

      &:hover {
        /* background-color: #606060; */
        /* transform: scale(1.01); */
      }
      .cardImage {
        width: 52px;
        height: 44px;
        object-fit: cover;
        border-radius: 4px;
      }

      .buttonTitle {
        font-family: ${({ theme }) =>
          theme?.components?.bot_message?.text?.family};
        color: ${({ theme }) => theme?.components?.bot_message?.text?.color};
        opacity: 0.8;
        font-weight: 500;
        font-size: 12.5px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }

    .singleRow {
      width: 98%;
      height: 44px;
      min-height: 44px;
      max-height: 44px;
      display: flex;
      align-items: center;
      justify-content: start;
      padding-left: 6px;
      gap: 1rem;
      border-radius: 4px;
      overflow: hidden;
      cursor: pointer;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
      border: 1px solid transparent;

      &:hover {
        /* transform: scale(1.01); */
        /* border: 1px solid rgba(0, 0, 0, 0.06); */
      }
      .rowImage {
        width: 32px;
        height: 32px;
        object-fit: cover;
        border-radius: 4px;
      }

      .buttonTitle {
        width: 100%;
        font-family: ${({ theme }) =>
          theme?.components?.bot_message?.text?.family};
        color: ${({ theme }) => theme?.components?.bot_message?.text?.color};
        opacity: 0.8;
        font-weight: 500;
        font-size: 13px;
      }
    }
  }
`;

export const StyledNotificationBlockWrapper = styled.div`
  && {
    .notificationIcon {
      svg {
        font-size: 19px;
        color: ${({ theme }) => {
          return theme?.components?.header.text.color;
        }};
      }
    }

    .notificationListWrapper {
      height: 100%;
      padding: 0.5rem 1rem 6px;
      position: relative;
      background-color: rgba(255, 255, 255, 0.7);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: start;
      gap: 3px;
      border-radius: 4px;
      overflow: hidden;
      flex: 1;

      .singleRow {
        background-color: rgba(0, 0, 0, 0.03);
        width: 100%;
        padding: 4px;
        height: 96px;
        display: flex;
        justify-content: space-between;
        border-radius: 6px;
        color: black;
        cursor: pointer;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
        border: 1px solid transparent;

        &:hover {
          transform: scale(1.01);
          border: 1px solid rgba(0, 0, 0, 0.06);
        }
        .rowLeftBox {
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          height: 100%;
          min-width: 80px;
          padding: 2px;
          img {
            height: 72px;
            width: 72px;
            object-fit: cover;
            border-radius: 4px;
          }
        }

        .rowRightBox {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          height: 100%;
          padding: 4px 6px 2px;
          overflow: hidden;

          .rowRightTop {
            display: flex;
            width: 100%;
            overflow: hidden;
            min-height: 20px;
            font-family: ${({ theme }) =>
              theme?.components?.bot_message?.text?.family};
            font-weight: 500;
            font-size: 0.85rem;
            line-height: 1.2rem;
            text-transform: capitalize;
            color: ${({ theme }) =>
              theme?.components?.bot_message?.text?.color};

            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }

          .rowRightMiddle {
            display: flex;
            justify-content: left;
            align-items: center;
            width: 100%;
            max-height: 20px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            font-family: ${({ theme }) =>
              theme?.components?.bot_message?.text?.family};
            font-size: 0.75rem;
            line-height: 1.2rem;
            color: ${theme.palette.default.darkGrey};
          }
          .rowRightBottom {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            overflow: hidden;

            font-size: 0.75rem;
            line-height: 1.2rem;
            color: ${theme.palette.default.darkGrey};
            .footerText {
              font-family: ${({ theme }) =>
                theme?.components?.bot_message?.text?.family};
              font-size: 0.75rem;
              line-height: 1.2rem;
              color: ${theme.palette.default.darkGrey};
            }

            button {
              color: dodgerblue;
            }
          }
        }
      }
    }
    .staticButton {
      button {
        color: #7e8392;
        min-height: 36px;
        max-height: 36px;
      }
      position: -webkit-sticky;
      position: sticky;
      z-index: 999;
      bottom: 0rem;
      border-radius: 10px;
      min-height: 36px;
      max-height: 36px;
      width: 100%;
      margin-top: auto;
      text-align: center;
      background-color: rgba(255, 255, 255, 0.9);
    }
  }
`;

const loadingFade = keyframes`
0% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
`;
export const StyledTyping = styled.div<StyledProps>`
  background-color: ${({ userMessage, theme }) =>
    userMessage
      ? theme?.components?.user_message?.background?.color
      : theme?.components?.bot_message?.background?.color};
  padding: 4px;
  border-radius: 5px;
  display: flex;
  && {
  }

  .typing__dot {
    float: left;
    width: 8px;
    height: 8px;
    margin: 0 4px;
    background: ${({ userMessage, theme }) =>
      userMessage
        ? theme?.components?.user_message.text.color
        : theme?.components?.bot_message.text.color};
    border-radius: 50%;
    opacity: 0;
    animation: ${loadingFade} 0.8s infinite;
  }

  .typing__dot:nth-of-type(1) {
    animation-delay: 0s;
  }

  .typing__dot:nth-of-type(2) {
    animation-delay: 0.2s;
  }

  .typing__dot:nth-of-type(3) {
    animation-delay: 0.4s;
  }
`;
export const StyledUserChatAvatar = styled.img`
  && {
    display: flex;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid
      ${({ theme }) => {
        return theme?.components?.header.background.color;
      }};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
    margin: 1px 0;
  }
`;

export const StyledReactVideoPlayer = styled(ReactPlayer)`
  && {
    overflow: hidden !important;
    border-radius: 6px;
    min-width: 100%;
    object-fit: contain;
    min-height: 10rem;
    min-height: 170px;
    max-height: 12rem;
    background-color: #fff;
    border-radius: 4px;
    cursor: pointer;
    .react-player__preview {
      svg {
        max-width: 54px;
        fill: #ffffff;
      }
    }
    .react-player__preview {
      width: 100%;
    }
  }
`;
export const StyledScrollDownBtn = styled.div`
  && {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 10%;
    width: 100%;
    z-index: 999;
  }
`;

export const StyledFileContainerBox = styled.a`
  width: 100%;
  height: 56px;
  border-radius: 4px;
  padding: 12px 8px;
  overflow: hidden;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  gap: 0.75rem;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  font-family: ${({ theme }) => {
    return theme?.components?.bot_message?.text?.family;
  }};
  border: 1px solid ${theme.palette.default.border};
  background-color: ${theme.palette.default.white};
  color: ${theme.palette.default.text};

  color: ${({ theme }) => theme?.components?.bot_message?.text?.color};
  background-color: rgba(
    ${({ theme }) =>
      hexToRgb(theme?.components?.bot_message?.background?.color)},
    1
  );
  border: 1px solid
    rgba(
      ${({ theme }) =>
        hexToRgb(theme?.components?.bot_message?.background?.color)},
      0.8
    );

  .fileNameBox {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 14px;
    font-weight: 500;
    overflow: hidden;
    cursor: pointer;
  }
  .fileActionBox {
    display: flex;
    cursor: pointer;
  }
`;

export const StyledBannerCarouselWrapper = styled.div`
  && {
    padding: 0 6px 0.2rem;

    .indicatorIconButtonProps {
      color: rgba(0, 0, 0, 0.1);
      svg {
        font-size: 10px;
      }
    }
    .activeIndicatorIconButtonProps {
      color: rgba(0, 0, 0, 0.3);
      svg {
        font-size: 10px;
      }
    }
    .indicatorContainerProps {
      width: 100%;
      margin-top: -2px;
      text-align: center;
    }
    .indicatorContainerProps {
      width: 100%;
      margin-top: -2px;
      text-align: center;
    }
    .navButtonsProps {
      width: 32px;
      height: 32px;
      background-color: rgba(0, 0, 0, 0.04);
      color: #7e8392;
      border: 1px solid transparent;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
    }
    .navButtonsProps {
      bottom: 0;
      top: calc(45% - 16px);
      height: 32px;
      background-color: transparent;
      z-index: 1;
    }

    .singleItem {
      background-color: rgba(255, 255, 255, 0.7);
      border-radius: 12px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 6px;
      padding: 4px 4px 4px;
      cursor: pointer;
    }

    p {
      padding: 10px;
      padding-left: 14px;
      font-size: 0.9rem;
      text-overflow: ellipsis;
      font-family: ${({ theme }) =>
        theme?.components?.user_input.normal.text.family};
    }
    img {
      width: 100%;
      height: 192px;
      border-radius: 4px;
      // min-height: 192px;
      max-height: 192px;
      border-radius: 2px;
      overflow: hidden;
      border: none;
      object-fit: contain;
    }
    video {
      width: 100%;
      height: 192px;
      overflow: hidden;
    }
  }
`;

export const StyledMessageRow = styled.div<StyledProps>`
  && {
    max-width: 100%;
    // border: 3px solid green;
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: ${(props) =>
      props.userMessage ? "flex-end" : "flex-start"};
    gap: 6px;
    /* margin: 6px 0; */
    margin: ${({ isButton }: any) => (isButton ? "0px 0" : "6px 0")};
    word-wrap: break-word;
    align-items: flex-end;
    padding: 0px 2px;
    .isButton {
      padding: 0px 0px;
      width: 75%;
      min-width: 75%;
      background-color: transparent;
      box-shadow: none;
      &:after {
        display: none;
      }
    }
  }
`;

export const StyledMessageContainer = styled.div<StyledProps>`
  && {
    max-width: 75%;
    word-wrap: break-word;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: ${(props) => (props.userMessage ? "right" : "left")};
    justify-content: ${(props) => (props.userMessage ? "right" : "left")};
    animation-delay: 2s;
    position: relative;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
    border-radius: ${({ userMessage }) =>
      userMessage ? "10px 4px 2px 10px" : "4px 10px 10px 0px"};
    margin: 1px 0;

    ul,
    ol {
      li {
        margin-left: 1rem;
      }
    }
  }
`;

export const StyledMessageText = styled.div<StyledProps>`
  && {
    /* padding: 17px 20px; */
    padding: 6px 12px;
    border-radius: ${({ userMessage }) =>
      userMessage ? "10px 10px 2px 10px" : "10px 10px 10px 0px"};
    overflow: hidden;

    background-color: ${({ userMessage, theme }) =>
      userMessage
        ? theme?.components?.user_message?.background?.color
        : theme?.components?.bot_message?.background?.color};
    word-break: break-word;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${({ userMessage, theme }) =>
      userMessage
        ? theme?.components?.user_message?.text?.color
        : theme?.components?.bot_message?.text?.color};
    font-family: ${({ userMessage, theme }) =>
      userMessage
        ? theme?.components?.user_message?.text?.family
        : theme?.components?.bot_message?.text?.family};
    font-weight: 400;
    font-size: ${({ userMessage, theme }) =>
      userMessage
        ? theme?.components?.user_message?.text?.size
        : theme?.components?.bot_message?.text?.size};
    line-height: 24px;

    a {
      cursor: pointer;
      color: ${({ userMessage, theme }) =>
        userMessage
          ? theme?.components?.user_message?.text?.color
          : theme?.components?.bot_message?.text?.color};
    }

    .actionBtnBox{
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1.5rem;
      width: 100%;
      padding: 0.7rem 0 0.5rem;
    }

    @media ${DEVICE_BREAKPOINT.mobileL} {
      font-size: 14px;
    }
  }
`;

export const StyledContentWrapper = styled.div<StyledProps>`
  && {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: ${(props: any) =>
      props.userMessage ? "flex-end" : "flex-start"};
    justify-content: flex-start;
  }
`;

export const StyledMediaContainer = styled.div<StyledProps>`
  && {
    position: relative;
    padding: 4px;
    margin: 1px 0;
    max-width: 85%;
    min-width: 85%;
    overflow: hidden;
    word-wrap: break-word;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 0px;
    background-color: ${({ userMessage, theme, isButton }: any) =>
      isButton
        ? "transparent"
        : userMessage
        ? theme?.components?.user_message?.background?.color
        : theme?.components?.bot_message?.background?.color};

    /* box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08); */
    border-radius: ${({ userMessage }) =>
      userMessage ? "10px 4px 0px 10px" : "4px 10px 10px 0px"};

    .loaderGif {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &:after {
      ${({ userMessage, theme }) =>
        userMessage
          ? `
        right: -1px;
        bottom: 6px;
        transform: rotate(180deg);
        `
          : `
        left: -4px;
        bottom: 0px;
        transform: rotate(180deg);
        `}
      content: ' ';
      position: absolute;
      width: 0;
      height: 0;
      border: 10px solid;
      border-color: ${({ userMessage, theme }) =>
          userMessage
            ? theme?.components?.user_message?.background?.color
            : theme?.components?.bot_message?.background?.color}
        transparent transparent transparent;

      display: ${({ isHideAfterArrowIcon, isButton }) =>
        isHideAfterArrowIcon || isButton ? "none" : "none"};
    }

    .renderImage {
      width: 100%;
      max-height: 15rem;
      /* min-height: 11rem; */
      object-fit: contain;
      background-color: ${theme.palette.default.white};
      border: 1px solid ${theme.palette.default.border};
      border-radius: 4px;
    }
    audio {
      height: 44px;
    }
  }
`;

export const StyledMessageTimeStamps = styled.div<StyledProps>`
  && {
    word-wrap: break-word;
    display: ${(props: any) => (props.isButton ? "none" : "flex")};
    align-items: center;
    justify-content: ${(props: any) => (props.userMessage ? "right" : "left")};
    flex-direction: row;
    gap: 5px;
    font-family: ${({ userMessage, theme }: any) =>
      userMessage
        ? theme?.components?.user_message?.text?.family
        : theme?.components?.bot_message?.text?.family};
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.5px;
    font-size: 11.5px;
    color: ${theme.palette.default.darkGrey};
    margin: 0px 4px 0px 0px;

    svg {
      font-size: 1rem;
    }
  }
`;

export const MessageMediaRowAttachment = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  background-color: ${theme.palette.default.lightGrey};
  font-size: 14px;
  border-radius: 5px;
  svg {
    margin-right: 5px;
    max-width: 15px;
  }
`;

export const StyledInfoMessageText = styled.p<StyledProps>`
  && {
    display: flex;
    justify-content: ${(props: any) => (props.userMessage ? "right" : "left")};
    word-wrap: break-word;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: ${({ userMessage, theme }: any) =>
      userMessage
        ? theme?.components?.user_message?.text?.family
        : theme?.components?.bot_message?.text?.family};
    font-weight: 300;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.5px;
    position: relative;
    margin: 4px 8px 4px;
    max-width: calc(100% - 180px);
    border-radius: 20px;
    text-align: center;
    padding: 5px 10px;
    color: rgba(
      ${({ theme }) =>
        hexToRgb(
          ColorUtils.getContrastTextColorHex(
            theme?.components?.chat_wall?.background?.color
          )
        )},
      0.8
    );
    opacity: 0.9;
    &:before {
      content: "";
      background: rgba(
        ${({ theme }) =>
          hexToRgb(
            ColorUtils.getContrastTextColorHex(
              theme?.components?.chat_wall?.background?.color
            )
          )},
        0.5
      );
      opacity: 0.5;
      width: 60px;
      height: 2px;
      transform: translateX(-60px);
      position: absolute;
      left: 0;
    }
    &:after {
      content: "";
      background: rgba(
        ${({ theme }) =>
          hexToRgb(
            ColorUtils.getContrastTextColorHex(
              theme?.components?.chat_wall?.background?.color
            )
          )},
        0.5
      );
      opacity: 0.5;

      width: 60px;
      height: 2px;
      right: 0;
      transform: translateX(60px);
      position: absolute;
    }
  }
`;

// Css For App Voice Button
const linePulseEffects = keyframes`
  0% {
    transform: scale(0.5);
    opacity: 0;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(1.4);
    opacity: 0;
  }
`;

export const AppVoiceButtonWrapper = styled.div`
  & {
    .startMicIcon {
      border: 1px solid
        ${({ theme }) => {
          return theme?.components?.bot_options_standard?.normal?.background
            ?.color;
        }};
      background-color: ${({ theme }) => {
        return theme?.components?.bot_options_standard?.normal?.background
          ?.color;
      }};
      color: ${({ theme }) => {
        return theme?.components?.bot_options_standard?.normal?.text?.color;
      }};
      &:hover {
        color: ${({ theme }) => {
          return theme?.components?.bot_options_standard?.normal?.background
            ?.color;
        }};
      }
    }
    .speech-area {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      align-items: center;

      .header {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        align-items: center;
        .MuiSvgIcon-root {
          font-size: 22px;
        }
      }

      .speech-body {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-conetnt: center;
        .MuiSvgIcon-root {
          font-size: 55px;
        }
      }
    }
    .active-mic {
      width: fit-content;
      height: fit-content;
      border-radius: 50%;
      position: relative;
      display: flex;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      align-items: center;
      background-color: rgb(0, 161, 255);
      cursor: pointer;

      .MuiSvgIcon-root {
        font-size: 40px;
      }

      &::before {
        content: ""; /* This is required for pseudo-elements */
        position: absolute;
        top: 0;
        left: 0;
        width: 40px;
        height: 40px;
        border: 1px solid rgb(52, 109, 179);
        border-radius: 50%;
        animation: 1.5s linear 0s infinite normal none running
          ${linePulseEffects};
      }

      &::after {
        content: "";
        position: absolute;
        width: 40px;
        height: 40px;
        border: 1px solid rgb(52, 109, 179);
        border-radius: 50%;
        animation: 1.5s linear 0.4s infinite normal none running
          ${linePulseEffects};
      }
    }
  }
`;

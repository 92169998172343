import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { INPUT_TYPES } from "../../../Models/Enum";
import { HostWindowCommService } from "../../../Services/IframeHostService/HostWindowCommService";
import { OEvents } from "../../../Services/IframeHostService/models";
let commService = HostWindowCommService.getInstance();
export interface LiveChatStatusSliceProps {
  liveChatStatus: boolean | null;
  projectId: string | null;
  requestId: string | null;
  tiledeskToken: string | null;
}

export interface LiveChatResponse {
  liveChatStatus?: boolean;
  project_id: string;
  request_id: string;
  success: boolean;
  token: string;
  user?: {
    _id: string;
    email: string;
    firstname: string;
    fullName: string;
    id: string;
    lastname: string;
  };
}

let INITIAL_STATE: LiveChatResponse = {
  liveChatStatus: false,
  project_id: "",
  request_id: "",
  success: false,
  token: ""
};

const LiveChatStatusSlice = createSlice({
  name: "LiveChatStatusSlice",
  initialState: INITIAL_STATE,
  reducers: {
    ChangeLiveChatStatus(state, { payload }: PayloadAction<LiveChatResponse>) {
      // console.log("ChangeLiveChatStatus", payload);
      commService.postMessage({
        event_type: OEvents.LIVECHAT_SESSION_CHANGE,
        data: payload,
      });

      return payload;
    },
    RemoveLiveChatStatus() {
      // console.log(" RemoveLiveChatStatus");
      commService.postMessage({
        event_type: OEvents.LIVECHAT_SESSION_CHANGE,
        data: INITIAL_STATE,
      });

      return INITIAL_STATE;
    },
  },
});

export default LiveChatStatusSlice.reducer;
export const { ChangeLiveChatStatus, RemoveLiveChatStatus } = LiveChatStatusSlice.actions;

export const useLiveChatStatusStore = (state: any) => {
  return { ...(state.botData.currrentLiveChatStatus as LiveChatResponse) };
};


import defaultChatLogo from "../assets/images/defaultChatLogo.svg";
// import sendIcon from "../assets/images/sendIcon.svg";
import userAvatar from "../assets/images/userAvatar.svg";
import getaFooter from "../assets/images/getaFooter.svg";
import { CHOICE_TYPES } from "../Models/Enum";



const botConfiguration: any = {

  INITIAL_BOT_ID: null,
  PRIMARY_COLOR: "#c00415", //#5B73E8    : geta default colorcode

  FONT_FAMILY: "Poppins",

  //avatars
  CHAT_AVATAR: userAvatar,
  COMPANY_LOGO: defaultChatLogo,
  QUICK_CHAT_AVATAR: defaultChatLogo,
  FOOTER_LOGO: getaFooter,

  //Help Tooltip Hover on quick chat icon
  TOOLTIP_RESET_TIMER: 60000 * 2, // 60000 = 1MiN
  TOOLTIP_MSG: "How can I help you?",

  //Msg Delay Time
  MESSAGES_DELAY: 1000,
  SESSION_EXPIRY_TIMEOUT_DAYS: 1,

  //you can change text msg but if changing url values then also change in handlechoicebutton accoradingly
  navOptions: [
    {
      text: "Yes, Navigate Me",
      url: CHOICE_TYPES.NAVIGATE_TO_PAGE
    },
    {
      text: "No, Just Explore here",
      url: CHOICE_TYPES.STAY_ON_PAGE
    }],

  inactiveStateOptions: [
    {
      text: "YES",
      url: CHOICE_TYPES.RESTART_SESSION
    },
    {
      text: "NO",
      url: CHOICE_TYPES.CLOSE_SESSION
    }
  ]
};

export default botConfiguration;

import { FastField, getIn, useFormikContext } from "formik";
import React, { useEffect, useRef } from "react";
import { theme } from "../../../Customization/Theme";
import {
  StyledInputField,
  StyledWidgetInputBox,
} from "../../Styles/StyledForms";
import { useAppSelector } from "../../../Store";

import { allMessagesStore } from "../../../Store/Slices/socket/AllMessagesSlice";
import AppErrorMessage from "./AppErrorMessage";
import AppSendSubmitButton from "./AppSendSubmitButton";
import useTimer from "../../hooks/useTimer";
import { useBotConfigStore } from "../../../Store/Slices/ChatbotSlices/BotConfigSlice";
import { ConversationService } from "../../../Services/Apis";
import AppResendOtp from "../UtilsComponents/AppResendOtp";
import { botInfoStore } from "../../../Store/Slices/socket/BotInfoSlice";

interface Props {
  name?: string;
  label?: string;
  children?: React.ReactNode;
  [otherProps: string]: any;
  ref?: any;
}

const AppFlowOTPField: React.FC<Props> = ({
  name = "otp",
  label = "Enter OTP",
  children,
  ...otherProps
}) => {
  const { errors, touched, values, handleChange } = useFormikContext<any>();
  const { typingStatus } = useAppSelector(allMessagesStore);
  const { getaHost } = useAppSelector(botInfoStore);
  const defaultTime = 90;
  const { timer, setTimer } = useTimer(defaultTime);

  const { botConfig } = useAppSelector(useBotConfigStore);
  const HEADERS = {
    appid: JSON.stringify({
      botid: botConfig?.chatbot?.bot_id || "",
    }),
    "GETA-HOST": getaHost,
  };
  const handleResendBtnClick = () => {
    ConversationService.resendOtp(HEADERS)
      .then((res: any) => {
        setTimer(defaultTime);
      })
      .catch((error: any) => {
        console.log("somewthing went wrong in suggestions", error);
      });
  };
  console.log("timer", timer);
  return (
    <StyledWidgetInputBox
      disabled={typingStatus ? true : false}
      error={getIn(errors, name) && getIn(touched, name) }
    >
      <FastField name={name}>
        {({ field }: any) => (
          <StyledInputField
            {...otherProps}
            {...field}
            value={getIn(values, name) || ""}
            onChange={(e: any) => {
              handleChange(e);
            }}
            placeholder={label}
            type={"number"}
            autoFocus={true}
            onWheel={(e) => e?.currentTarget?.blur()} //* disable scroll *//
          />
        )}
      </FastField>
      <AppSendSubmitButton />
      <AppResendOtp
        resendBtnFn={() => handleResendBtnClick()}
        timer={timer}
        buttonTitle="Resend OTP"
      />
      <AppErrorMessage
        error={getIn(errors, name)}
        visible={getIn(touched, name) }
      />
    </StyledWidgetInputBox>
  );
};

export default AppFlowOTPField;

import { combineReducers } from "@reduxjs/toolkit";
import BotConfigReducer from "./BotConfigSlice";
import ChatWindowStatusReducer from "./ChatWindowStatusSlice";
import InputReadyStatusReducer from "./InputReadyStatusSlice";
import IframeDimensionReducer from "./IframeDimensionSlice";
import WidgetPreviewTypeReducer from "./WidgetPreviewTypeSlice";
import LiveChatStatusReducer from "./LiveChatStatusSlice";
import TrackingConfigReducer from "./TrackingConfigSlice";
import VisitorIdReducer from "./VisitorIdConfigSlice";
import AllMessagesReducer from "../socket/AllMessagesSlice";
import SocketSliceReducer from "../socket/SocketSlice";
import DeviceSizeReducer from "../socket/DeviceSizeSlice";
import BotInfoReducer from "../socket/BotInfoSlice";


export const botReducer = combineReducers({
  currrentBotConfig: BotConfigReducer,
  currrentChatWindowStatus: ChatWindowStatusReducer,
  currrentInputReadyStatus: InputReadyStatusReducer,
  currrentIframeDimension: IframeDimensionReducer,
  currrentWidgetPreviewType: WidgetPreviewTypeReducer,
  currrentLiveChatStatus: LiveChatStatusReducer,
  currrentVisitorIdConfig: VisitorIdReducer,
  currrentTrackingConfig: TrackingConfigReducer,

  //socket
  currrentAllMessagesStore: AllMessagesReducer,
  currrentSocketStatus: SocketSliceReducer,
  currrentDeviceSize: DeviceSizeReducer,
  currrentBotInfo: BotInfoReducer,
});

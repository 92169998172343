import defaultChatLogo from "../../assets/images/defaultChatLogo.svg";
import getaFooter from "../../assets/images/getaFooter.svg";
import userAvatar from "../../assets/images/userAvatar.svg";

export class BotConfigDefaults {
  public static readonly PRIMARY_COLOR: string = "#c00415";
  public static readonly FONT_FAMILY: string = "Poppins";
  public static readonly INITIAL_BOT_ID: string | unknown | null = null;
  public static readonly CHAT_AVATAR: any = userAvatar;
  public static readonly COMPANY_LOGO: any = defaultChatLogo;
  public static readonly FAB: any = defaultChatLogo;
  public static readonly FOOTER_LOGO: any = getaFooter;
}

import React, { memo } from "react";
import { IconButton } from "@mui/material";
import { theme } from "../../../Customization/Theme";
import { StyledFileContainerBox, StyledMessageText } from "../../Styles/StyledComponents";
import { AppMaterialIcons } from "../Icons";
import AppMessageBoxWrapper from "../UtilsComponents/AppMessageBoxWrapper";

interface Props {
  src: string;
  name: string;
  alt?:string;
  userMessage: boolean;
  time?: string;
  readStatus?: "sent" | "delivered" | "seen";
  children?: any;
  [otherProps: string]: any;
}

const AppRenderFile: React.FC<Props> = ({ src, name, alt,userMessage = false, time,  readStatus = "delivered", }) => {
  if (!src) {
    return null;
  }

  return (
    <AppMessageBoxWrapper
      userMessage={userMessage}
      isMedia={true}
      time={time}
      readStatus={readStatus}
    >
       <StyledFileContainerBox
        href={src}
        target="_blank"
        rel="noopener,noreferrer"
      >
        <div className="fileActionBox">
          <AppMaterialIcons
            iconName="FilePresent"
            style={{ color: theme?.palette?.default?.darkGrey }}
          />
        </div>
        <div className="fileNameBox">{name}</div>
        <IconButton title={name || "Download"}>
          <AppMaterialIcons
            iconName="DownloadForOffline"
            style={{ fontSize: "1.5rem" }}
          />
        </IconButton>
      </StyledFileContainerBox>
      {alt && alt?.length > 0 ? (
        <StyledMessageText
          userMessage={userMessage}
          dangerouslySetInnerHTML={{
            __html: alt,
          }}
        />
      ) : null}
    </AppMessageBoxWrapper>
  );
};

export default memo(AppRenderFile);


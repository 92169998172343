import React from "react";
import useSpeechToText from "react-hook-speech-to-text";
import { ClickAwayListener, IconButton } from "@mui/material";
import MicIcon from "@mui/icons-material/Mic";
import AppDrawer from "../UtilsComponents/AppDrawer";
import { useSelector } from "react-redux";
import { deviceSizeStore } from "../../../Store/Slices/socket/DeviceSizeSlice";
import AppSmallButton from "../UtilsComponents/AppSmallButton";
import { AppMaterialIcons } from "../Icons";
import { MESSAGE_TYPES, SOCKET_CONNECTION_TYPES } from "../../utils/Enum";
import {
  getCurrentUTCTime,
  isEmptyObject,
  renderTextWithLinks,
} from "../../utils/utils";
import { useAppDispatch, useAppSelector } from "../../../Store";
import { socketStatusStore } from "../../../Store/Slices/socket/SocketSlice";
import {
  allMessagesStore,
  setAllMessages,
} from "../../../Store/Slices/socket/AllMessagesSlice";
import uniqid from "uniqid";
import { AppVoiceButtonWrapper } from "../../Styles/StyledComponents";
import ActiveMic from "../../../assets/images/activeMicRecording.gif";
import voiceLoader from "../../../assets/images/voiceLoader.gif";

let recordingStart: any = new Audio(
  require("../../../assets/recording-start.mp3")
);

let recordingStop: any = new Audio(
  require("../../../assets/recording-end.mp3")
);

interface Props {
  name?: any;
}

const AppVoiceButton: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = React.useState<boolean>(false);
  const IframeDimensions = useSelector(deviceSizeStore);
  const [inputText, setInputText] = React.useState("");

  const {
    lastNodeResponse,
    lastInputBoxType,
    lastQuickActions,
    typingStatus,
    sessionId,
    liveChatConnected,
  } = useAppSelector(allMessagesStore);
  const { socket } = useAppSelector(socketStatusStore);
  const {
    error,
    interimResult,
    isRecording,
    results,
    startSpeechToText,
    stopSpeechToText,
  } = useSpeechToText({
    continuous: false,
    useLegacyResults: false,
    crossBrowser: true,
    timeout: 5000,
    googleApiKey: "ed02d1ea7a7b19b16d6d485441f52ca689397d18",
    googleCloudRecognitionConfig: {
      languageCode: "en-US",
    },
  });

  const handleTextResponse = (values: any, emitType: string = "bot") => {
    const emitEvent =
      emitType === "livechat"
        ? SOCKET_CONNECTION_TYPES.AGENT_CHAT
        : SOCKET_CONNECTION_TYPES.BOT_CONVERSATION;

    let userInput: string = "";

    if (typeof values === "string") {
      userInput = values || "";
    } else if (typeof values === "object" && !isEmptyObject(values)) {
      let firstValue: string = String(Object?.values(values)[0]) || "";
      userInput = firstValue;
    } else {
      userInput = "";
    }
    let data = {
      type: MESSAGE_TYPES.TEXT,
      isUser: true,
      value: renderTextWithLinks(userInput),
      socket_id: socket?.id,
      session_id: sessionId,
      conversation_id: lastNodeResponse?.conversation_id,
      time: getCurrentUTCTime(),
      message_id: uniqid(),
    };
    dispatch(setAllMessages([data]));
    if (socket?.connected) {
      socket?.emit(emitEvent, data);
    }
  };

  React.useEffect(() => {
    setTimeout(() => {
      if (!isRecording && results.length > 0) {
        setOpen(false);
        const lastResult = results[results.length - 1];
        if (typeof lastResult === "string") {
          setInputText(lastResult);
          handleTextResponse(lastResult); // Execute the response handler
          // Clear the results array
          results.splice(0, results.length);
        } else if (
          typeof lastResult === "object" &&
          "transcript" in lastResult
        ) {
          setInputText(lastResult.transcript);
          handleTextResponse(lastResult.transcript); // Execute the response handler
          // Clear the results array
          results.splice(0, results.length);
        }
      }
    }, 3000);
  }, [isRecording, results]);

  // React.useEffect(() => {
  //   if (!isRecording && results.length > 0) {
  //     const lastResult = results[results.length - 1];
  //     if (typeof lastResult === "string") {
  //       setInputText(lastResult);
  //     } else if (typeof lastResult === "object" && "transcript" in lastResult) {
  //       setInputText(lastResult.transcript);
  //     }
  //   }
  // }, [isRecording, results]);

  const startRrcording = () => {
    setOpen(true);
    startSpeechToText();
    recordingStart.play();
  };

  const stopRecording = () => {
    setOpen(false);
    stopRecording();
  };


  if (error) return <p>Web Speech API is not available in this browser 🤷‍</p>;

  return (
    <AppVoiceButtonWrapper>
      <IconButton
        onClick={() => {
          startRrcording();
        }}
        className="startMicIcon"
      >
        <MicIcon />
      </IconButton>
      <AppDrawer
        anchor={"bottom"}
        showDrawer={open}
        setShowDrawer={setOpen}
        style={{
          bottom: "0px",
          left: "0px",
          maxWidth: IframeDimensions?.width,
          borderRadius: "0px",
          overflowX: "hidden",
          paddingBottom: 0,
          maxHeight: "238px",
          padding: "6px",
          boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.1)",
          display: "flex",
          justifyContent: "center",
        }}
        intialX={0}
        intialY={"5%"}
        animateX={0}
        animateY={0}
        exitX={0}
        exitY={"+100%"}
        animateHeight={IframeDimensions?.height - 104 + "px"}
      >
        <ClickAwayListener
          onClickAway={() => {
            setOpen(false);
            stopSpeechToText();
          }}
        >
          <div className="speech-area">
            <div className="header">
              <IconButton
                onClick={() => {
                  setOpen(false);
                  stopSpeechToText();
                }}
              >
                <AppMaterialIcons iconName="Close" />
              </IconButton>
            </div>

            <div className="speech-body">
              {isRecording ? (
                <>
                  <img src={ActiveMic} width="115px" />
                  <p>Listening...</p>
                </>
              ) : (
                <>
                  {results?.length ? (
                    <img src={voiceLoader} width="115px" />
                  ) : (
                    <>
                      <IconButton
                        style={{ marginBottom: "1.6rem" }}
                        onClick={() => startSpeechToText()}
                      >
                        <AppMaterialIcons iconName="MicNone" />
                      </IconButton>
                      <AppSmallButton
                        variant="text"
                        onClick={() => startSpeechToText()}
                      >
                        Click To Record
                      </AppSmallButton>
                    </>
                  )}
                </>
              )}
              <div>
                {results.length > 0 ? (
                  <p style={{ color: "red" }}>
                    {(results[results.length - 1] as any).transcript ||
                      (results[results.length - 1] as string)}
                  </p>
                ) : null}

                {interimResult && results.length > 0 && (
                  <p style={{ color: "blue" }}>{interimResult}</p>
                )}
              </div>
            </div>

            <div className="footer"></div>
          </div>
        </ClickAwayListener>
      </AppDrawer>
    </AppVoiceButtonWrapper>
  );
};

export default AppVoiceButton;

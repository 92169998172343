import { FastField, getIn, useFormikContext } from "formik";
import React, { useEffect, useRef } from "react";
import {
  StyledInputField,
  StyledSuggestionBox,
  StyledWidgetInputBox,
} from "../../Styles/StyledForms";
import { useAppSelector } from "../../../Store";

import { allMessagesStore } from "../../../Store/Slices/socket/AllMessagesSlice";
import AppErrorMessage from "./AppErrorMessage";
import AppSendSubmitButton from "./AppSendSubmitButton";
import { useBotConfigStore } from "../../../Store/Slices/ChatbotSlices/BotConfigSlice";
import { ConversationService } from "../../../Services/Apis";
import { AppMaterialIcons } from "../Icons";
import { IconButton } from "@mui/material";
import useDebounce from "../../hooks/useDebounce";
import AppVoiceButton from "./AppVoiceButton";
import { botInfoStore } from "../../../Store/Slices/socket/BotInfoSlice";

interface Props {
  name?: string;
  label?: string;
  children?: React.ReactNode;
  [otherProps: string]: any;
  ref?: any;
}

const AppFlowFaqQueryField: React.FC<Props> = ({
  name = "query",
  label = "Enter your query",
  children,
  ...otherProps
}) => {
  const { errors, touched, values, handleChange, setFieldValue, handleSubmit } =
    useFormikContext<any>();
  const { typingStatus } = useAppSelector(allMessagesStore);
  const { botConfig } = useAppSelector(useBotConfigStore);
  const {getaHost } = useAppSelector(botInfoStore);
  const inputRef: any = useRef<any>();

  useEffect(() => {
    if (inputRef?.current && !typingStatus) {
      inputRef?.current?.focus();
    }
  }, [inputRef, typingStatus]);

  //* FAQ SUGGESTION *//

  const HEADERS = {
    appid: JSON.stringify({
      botid: botConfig?.chatbot?.bot_id || "",
    }),
    "GETA-HOST": getaHost,
  };

  const [showSuggestionsBox, setShowSuggestionsBox] =
    React.useState<boolean>(false);
  const [liveResults, setLiveResults] = React.useState<any>([]);
  const debouncedSearchTerm = useDebounce<string>(
    getIn(values, name) || "",
    800
  );

  const SuggestionFAQ = (text: string | null) => {
    if (text && text?.length > 1) {
      ConversationService.postSearchFaqVariations(
        {
          query: text,
        },
        HEADERS
      )
        .then((res: any) => {
          // console.log("Availabe matched results", res?.data?.data);
          let data = res?.data?.data;
          setLiveResults(data);

          data && setShowSuggestionsBox(true);
        })
        .catch((error: any) => {
          console.log("somewthing went wrong in suggestions", error);
        });
    }
  };

  useEffect(() => {
    // console.log("useEffect", debouncedSearchTerm, botConfig?.chatbot?.bot_id);
    if (botConfig?.chatbot?.bot_id) {
      if (
        debouncedSearchTerm.length > 1 &&
        botConfig?.config?.show_suggestion
      ) {
        SuggestionFAQ(debouncedSearchTerm);
      } else {
        // setLiveResults([]);
      }
    }
  }, [debouncedSearchTerm]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSuggestionAdd = (curItem: any) => {
    setFieldValue(name, curItem?.query);
    setTimeout(() => {
      handleSubmit();
    }, 100);
  };

  return (
    <>
      {getIn(values, name)?.length > 1 &&
        showSuggestionsBox &&
        liveResults &&
        liveResults.length > 0 && (
          <StyledSuggestionBox
            transition={{ duration: 0.25, delay: 0, ease: "easeIn" }}
            initial={{ opacity: 0, y: "+60%" }}
            animate={{ opacity: 1, y: "0" }}
            exit={{
              opacity: 0,
              y: "+100%",
              transition: { duration: 0.15, delay: 0, ease: "easeOut" },
            }}
          >
            <p className="suggestionHeader">
              Similar Questions Found{" "}
              <IconButton onClick={() => setShowSuggestionsBox(false)}>
                <AppMaterialIcons iconName="close" />
              </IconButton>{" "}
            </p>
            {liveResults.map((curItem: any, index: number) => {
              return (
                <div
                  className="suggestionBoxSingleRow"
                  key={index}
                  onClick={() => {
                    handleSuggestionAdd(curItem);
                    setShowSuggestionsBox(false);
                  }}
                >
                  {curItem?.query || curItem?.qid}
                </div>
              );
            })}
          </StyledSuggestionBox>
        )}
      <StyledWidgetInputBox
        disabled={typingStatus ? true : false}
        error={getIn(errors, name) && getIn(touched, name)}
      >
        <FastField name={name}>
          {({ field }: any) => (
            <StyledInputField
              {...otherProps}
              {...field}
              value={getIn(values, name) || ""}
              onChange={(e: any) => {
                handleChange(e);
              }}
              autoFocus={true}
              placeholder={label}
            />
          )}
        </FastField>
        {/* <AppSendSubmitButton /> */}
        {/* {getIn(values, name)?.length > 0 ? (
          <AppSendSubmitButton />
        ) : (
          <AppVoiceButton name={name} />
        )} */}
        <AppSendSubmitButton />

        <AppErrorMessage
          error={getIn(errors, name)}
          visible={getIn(touched, name)}
        />
      </StyledWidgetInputBox>
    </>
  );
};

export default AppFlowFaqQueryField;

import React from "react";
import { ReactComponent as TimerIcon } from "../../../assets/images/timer-icon.svg";

import AppSmallButton from "./AppSmallButton";
import Box from "@mui/material/Box";
import { StyledResendBoxWrap } from "../../Styles/StyledScreens";
import { secondsToHms } from "../../utils/utils";


interface Props {
  buttonTitle?: string;
  timer?: number;
  resendBtnFn: () => void;
  children?: React.ReactNode;
  [otherProps: string]: any;
}

const AppResendOtp: React.FC<Props> = ({
  buttonTitle = "Resend OTP",
  timer = 10,
  resendBtnFn,
  children,
  ...otherProps
}) => {


  const handleResendBtnClick = () => {
    resendBtnFn();
  };

  return (
    <StyledResendBoxWrap>
      {timer <= 0 ? <AppSmallButton
        variant="text"
        onClick={() => handleResendBtnClick()}
      >
        {buttonTitle}
      </AppSmallButton>
        :
        <Box className="timerWrapper">
          <TimerIcon />
          {secondsToHms(timer.toString())}
        </Box>
      }

    </StyledResendBoxWrap>
  );
};

export default AppResendOtp;

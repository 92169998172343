import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Props {
  widgetPreviewType: string | null | undefined;
}

let INITIAL_STATE: Props = {
  widgetPreviewType: null
};

const WidgetPreviewTypeSlice = createSlice({
  name: "WidgetPreviewTypeSlice",
  initialState: INITIAL_STATE,
  reducers: {
    ChangeWidgetPreviewType(state, { payload }: PayloadAction<Props>) {
      return payload;
    },
  },
});

export default WidgetPreviewTypeSlice.reducer;
export const { ChangeWidgetPreviewType } = WidgetPreviewTypeSlice.actions;

export const useWidgetPreviewTypeStore = (state: any) => {
  return { ...(state.botData.currrentWidgetPreviewType as Props) };
};

import { useCallback, useEffect, useMemo, useState } from "react";
import { ThemeProvider } from "styled-components";
import "./App.scss";
import { BotTheme, theme as default_theme } from "./Customization/Theme";
import { ChatbotConsoleService } from "./Services/Apis";
import { HostWindowCommService } from "./Services/IframeHostService/HostWindowCommService";
import { IEvents, OEvents } from "./Services/IframeHostService/models";
import { useAppDispatch, useAppSelector } from "./Store";
import {
  StoreBotConfigFn,
  useBotConfigStore,
} from "./Store/Slices/ChatbotSlices/BotConfigSlice";
import {
  setIsSessionIdFromLocalStorage,
  setSessionId,
} from "./Store/Slices/socket/AllMessagesSlice";
import {
  botInfoStore,
  setBotId,
  setBotToFullScreen,
  setGetaHost,
  setLocation,
  setVisitorId,
  setWidgetPreviewType,
} from "./Store/Slices/socket/BotInfoSlice";
import { setDeviceSize } from "./Store/Slices/socket/DeviceSizeSlice";
import Home from "./socket/screens/Home";
import { PreviewType, SOCKET_CONNECTION_TYPES } from "./socket/utils/Enum";
import GlobalStyles from "./globalStyles";

const WidgetApp = () => {
  const [theme, setTheme] = useState<any>(default_theme);
  const [isThemeLoadedFromConfig, setIsThemeLoadedFromConfig] =
    useState<boolean>(false);

  const dispatch = useAppDispatch();
  // const { botConfig } = useAppSelector(useBotConfigStore);

  const { botId, toggleReloadWidget, getaHost, location } =
    useAppSelector(botInfoStore);

  let myBotTheme = null;
  let commService = useMemo(() => {
    return HostWindowCommService.getInstance();
  }, []);

  const onChatbotStart = useCallback((event: any) => {
    if (event.data && event.data.hasOwnProperty("data")) {
      const data = event.data.data;
      if (data.hasOwnProperty("bot_id")) {
        dispatch(setBotId(data.bot_id));
      }
      if (data.hasOwnProperty("location")) {
        dispatch(setLocation(data.location));
      }
      if (data?.["is_widget_preview"]) {
        dispatch(setWidgetPreviewType(data?.["is_widget_preview"]));

        if (data?.["is_widget_preview"] === PreviewType.getaWidgetPreview) {
          // web bot preview for geta
          SOCKET_CONNECTION_TYPES.BOT_CONVERSATION = "bot-conversation-preview";
          SOCKET_CONNECTION_TYPES.USER_CONNECT = "";
        }
      }
      if (data.hasOwnProperty("device")) {
        dispatch(setDeviceSize(data.device));
      }

      if (data.hasOwnProperty("geta_host") && data.geta_host) {
        dispatch(setGetaHost(data.geta_host));
      }
      if (
        data.hasOwnProperty("chatbot_session_id") &&
        data.chatbot_session_id
      ) {
        dispatch(setSessionId(data?.chatbot_session_id));
        dispatch(setIsSessionIdFromLocalStorage(true));
      }
      if (data?.isFullScreenBot) {
        dispatch(setBotToFullScreen(true));
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onChatbotSaveVisitorId = (event: any) => {
    if (event.data && event.data.hasOwnProperty("data")) {
      const data = event.data.data;
      if (data.hasOwnProperty("visitor_id")) {
        dispatch(setVisitorId(data.visitor_id));
      }
    }
  }; // eslint-disable-line react-hooks/exhaustive-deps

  const getWidgetFrame = () => {
    commService.registerEventListener(IEvents.CHATBOT_START, onChatbotStart);
    commService.registerEventListener(
      IEvents.CHATBOT_SAVE_VISITOR_ID,
      onChatbotSaveVisitorId
    );
  }; // eslint-disable-line react-hooks/exhaustive-deps

  const getBotWidgetConfigFn = (id: string) => {
    setIsThemeLoadedFromConfig(false);
    const CONFIG = {
      "GETA-HOST": getaHost,
    };
    let parentURL: any = [];
    if (location) {
      parentURL = new URL(location).searchParams;
    }
    const paramsObject: any = {
      bot_id: id,
    };
    parentURL.forEach((value: any, key: any) => {
      paramsObject[key] = value;
    });
    ChatbotConsoleService.getBotWidgetConfig({ ...paramsObject }, CONFIG)
      .then((res: any) => {
        const data = res?.data;
        dispatch(
          StoreBotConfigFn({
            botConfig: {
              chatbot: data?.chatbot,
              theme: data?.chatbot_theme,
              config: data?.chatbot_configuration,
            },
          })
        );
        myBotTheme = new BotTheme(data?.chatbot_theme);
        setTheme(myBotTheme?.theme);
        setIsThemeLoadedFromConfig(true);

        //* sending widget configuration to tracking
        commService.postMessage({
          event_type: OEvents.CHATBOT_CONFIGURATION,
          data: data?.chatbot_configuration || null,
        });
      })
      .catch((err: any) => {
        console.error("bot config theme api error");
      });
  };

  useEffect(() => {
    getWidgetFrame();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (botId?.length > 0) {
      getBotWidgetConfigFn(botId);
    } else {
      // console.error("bot id not found");
    }
  }, [botId, toggleReloadWidget]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!isThemeLoadedFromConfig) {
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles $fontFamily={theme?.font?.primary?.family ?? "Inter"} />
      <div className="App">{botId && <Home />}</div>
    </ThemeProvider>
  );
};

export default WidgetApp;

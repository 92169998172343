import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Socket } from "socket.io-client";
import { RootState } from "../..";

interface Props {
  socket: Socket | any;
  executeConnectSocketEventWithReConnectUser: (() => void); // Function to be stored
  executeConnectSocketEventWithConnectUser: ((data:any) => void); // Function to be stored
}

const INITIAL_STATE: Props = {
  socket: null,
  executeConnectSocketEventWithReConnectUser: ()=>undefined,
  executeConnectSocketEventWithConnectUser: ()=>undefined,
};

const SocketSlice = createSlice({
  name: "SocketSlice",
  initialState: INITIAL_STATE,
  reducers: {
    setSocketStatus(state, { payload }: PayloadAction<any>) {
      state.socket = payload;
    },

    removeSocketStatus() {
      return INITIAL_STATE;
    },

    setExecuteConnectSocketEventWithReConnectUser(state, { payload }: PayloadAction<() => void>) {
      state.executeConnectSocketEventWithReConnectUser = payload;
    },
    setExecuteConnectSocketEventWithConnectUser(state, { payload }: PayloadAction<() => void>) {
      state.executeConnectSocketEventWithConnectUser = payload;
    },
  },
});

export default SocketSlice.reducer;

export const {
  removeSocketStatus,
  setSocketStatus,
  setExecuteConnectSocketEventWithReConnectUser,
  setExecuteConnectSocketEventWithConnectUser
} = SocketSlice.actions;

export const socketStatusStore = (state: RootState) =>
  state.botData.currrentSocketStatus;

import React, { memo } from "react";
import { useAppDispatch, useAppSelector } from "../../../Store";
import {
    allMessagesStore,
    setAllMessages,
} from "../../../Store/Slices/socket/AllMessagesSlice";
import { ActionBoxWrapper } from "../../Styles/StyledScreens";
import AppSmallButton from "../UtilsComponents/AppSmallButton";
import useMessagesData from "../../hooks/useMessagesData";

interface Props {}

const AppRenderTransferToFaqButton: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const { sessionId } = useAppSelector(allMessagesStore);
  // const { socket } = useAppSelector(socketStatusStore);
  const { postBotUserQuery } = useMessagesData();


  const handleTransferFlow = (item: any) => {
    let data = {
      type: "text",
      value: "anything else",
      transfer_to_faq: true,
      session_id: sessionId,
      isUser: true,
    };

    dispatch(setAllMessages([data]));
    postBotUserQuery(data);
  };

  return (
    <ActionBoxWrapper>
      <AppSmallButton onClick={() => handleTransferFlow({})}>
        Anything else?
      </AppSmallButton>
    </ActionBoxWrapper>
  );
};

export default memo(AppRenderTransferToFaqButton);

import { useEffect } from "react";
import { useAppSelector } from "../../Store";
import {
  allMessagesStore
} from "../../Store/Slices/socket/AllMessagesSlice";
import { botInfoStore } from "../../Store/Slices/socket/BotInfoSlice";
import { deviceSizeStore } from "../../Store/Slices/socket/DeviceSizeSlice";
import { StyledChatUIBoxWrap } from "../Styles/StyledScreens";
import AppChatScreenWrapper from "../appComponents/UtilsComponents/AppChatScreenWrapper";
import useMessagesData from "../hooks/useMessagesData";
import { PreviewType } from "../utils/Enum";
import AppInputHandler from "./AppInputHandler";
import Header from "./Header";
import WidgetCopyright from "./WidgetCopyright";
import AppChatLoadingScreen from "../appComponents/UtilsComponents/AppChatLoadingScreen";
import { useBotConfigStore } from "../../Store/Slices/ChatbotSlices/BotConfigSlice";

interface Props {
  isFullScreen?: boolean;
}

const MainUI: React.FC<Props> = ({ isFullScreen }) => {
  const { chatOpened, widgetPreviewType } = useAppSelector(botInfoStore);
  const { height, width } = useAppSelector(deviceSizeStore);
  const { sessionId, botLanguage, allMessages } = useAppSelector(allMessagesStore);
  //custom hooks
  const { getNewMessages, getHistoryMessages, loading } = useMessagesData();

  const { botConfig } = useAppSelector(useBotConfigStore);
  const conversationLimit = botConfig?.config?.conversation;

  // ********************************************************

  // ─── REQUEST DATA EVENTS ──────────────────────────────────────────────────────────────

  // ********************************************************

  // const BOT_HEADERS = {
  //   appid: JSON.stringify({
  //     botid: botId,
  //   }),
  //   "geta-host": getaHost,
  //   visitor_id: visitorId || null,
  //   preview: widgetPreviewType === PreviewType.getaWidgetPreview ? true : false,
  // };

  // const botHeaders = useMemo(
  //   () => ({
  //     appid: JSON.stringify({
  //       botid: botId,
  //     }),
  //     "geta-host": AppConfig.getGetaHost(),
  //     visitor_id: visitorId || null,
  //     preview:
  //       widgetPreviewType === PreviewType.getaWidgetPreview ? true : false,
  //     connection_type: "user",
  //   }),
  //   [botId, visitorId, widgetPreviewType]
  // );

  // const sendRequestNewBotSessionEvent = () => {
  //   if (socket?.connected) {
  //     socket?.emit(
  //       SOCKET_CONNECTION_TYPES.INITIATE_BOT_SESSION,
  //       BOT_HEADERS,
  //     );
  //   }
  // };

  // const sendRequestBotFirstFlowEvent = () => {
  //   if (socket?.connected && socket?.id) {
  //     let data = {
  //       session_id: sessionId,
  //       language: botLanguage,
  //     };
  //     socket?.emit(SOCKET_CONNECTION_TYPES.BOT_CONVERSATION, data);
  //   }
  //   // else {
  //   //   sendRequestNewBotSessionEvent();
  //   // }
  // };

  // const sendRequestBotHistoryFlowEvent = () => {
  //   if (socket?.connected && sessionId && socket?.id) {
  //     let data = {
  //       session_id: sessionId,
  //       socket_id: socket?.id,
  //     };
  //     socket?.emit(SOCKET_CONNECTION_TYPES.BOT_CONVERSATION_HISTORY, data,(error: any) => {
  //       if (error) {
  //         console.log(error);
  //       }
  //       else {
  //         // console.log("Bot conversation history fetched successfully");
  //       }
  //     });
  //   }
  // };


  const getMessages = () => {
    if (!sessionId || widgetPreviewType === PreviewType.getaWidgetPreview) {
      let data = {
        session_id: null,
        language: botLanguage,
      };
      getNewMessages(data);
    } else {
      const requestData = {
        session_id: sessionId,
        language: botLanguage,
      };
      getHistoryMessages(requestData);
      console.warn("previous session found");
    }
  };

  useEffect(() => {
    if (conversationLimit?.enabled && chatOpened && allMessages?.length === 0) {
      getMessages();
    }
  }, [chatOpened]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledChatUIBoxWrap
      chatopened={chatOpened ? 1 : 0}
      devicewidth={width}
      deviceheight={height - 78}
      isFullScreen={isFullScreen}
    >
      <Header />
      <AppChatLoadingScreen loading={loading}/>
      <AppChatScreenWrapper />
      <AppInputHandler />
      <WidgetCopyright />
    </StyledChatUIBoxWrap>
  );
};

export default MainUI;

import { FastField, getIn, useFormikContext } from "formik";
import React, { useEffect, useRef } from "react";
import { theme } from "../../../Customization/Theme";
import {
  StyledInputField,
  StyledWidgetInputBox,
} from "../../Styles/StyledForms";
import { useAppSelector } from "../../../Store";

import { allMessagesStore } from "../../../Store/Slices/socket/AllMessagesSlice";
import AppErrorMessage from "./AppErrorMessage";
import AppSendSubmitButton from "./AppSendSubmitButton";
import AppVoiceButton from "./AppVoiceButton";

interface Props {
  name?: string;
  label?: string;
  children?: React.ReactNode;
  [otherProps: string]: any;
  ref?: any;
}

const AppFlowNameField: React.FC<Props> = ({
  name = "name",
  label = "Enter your name",
  children,
  ...otherProps
}) => {
  const { errors, touched, values, handleChange } = useFormikContext<any>();
  const { typingStatus } = useAppSelector(allMessagesStore);


  return (
    <StyledWidgetInputBox
      disabled={typingStatus ? true : false}
      error={getIn(errors, name) && getIn(touched, name)}
    >
      <FastField name={name}>
        {({ field }: any) => (
          <StyledInputField
            {...otherProps}
            {...field}
            value={getIn(values, name)}
            onChange={(e: any) => {
              handleChange(e);
            }}
            placeholder={label}
            autoFocus={true}
          />
        )}
      </FastField>

      {/* {getIn(values, name)?.length > 0 ? (
        <AppSendSubmitButton />
      ) : (
        <AppVoiceButton name={name} />
      )} */}

      <AppSendSubmitButton />

      <AppErrorMessage
        error={getIn(errors, name)}
        visible={getIn(touched, name)}
      />
    </StyledWidgetInputBox>
  );
};

export default AppFlowNameField;

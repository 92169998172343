import { useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatch } from "react-redux";
import io, { ManagerOptions, Socket, SocketOptions } from "socket.io-client";
import { useAppSelector } from "../../Store";

import { HostWindowCommService } from "../../Services/IframeHostService/HostWindowCommService";
import { OEvents } from "../../Services/IframeHostService/models";
import { useBotConfigStore } from "../../Store/Slices/ChatbotSlices/BotConfigSlice";
import {
  allMessagesStore,
  removeMessage,
  setAllHistoryMessages,
  setAllMessages,
  setIsSessionIdFromLocalStorage,
  setLastNodeResponse,
  setLiveChatConnected,
  setOriginalResponses,
  setSessionId,
  setTypingStatus,
} from "../../Store/Slices/socket/AllMessagesSlice";
import { botInfoStore } from "../../Store/Slices/socket/BotInfoSlice";
import { setSocketStatus } from "../../Store/Slices/socket/SocketSlice";
import { PreviewType, SOCKET_CONNECTION_TYPES } from "../utils/Enum";
const parser= require("socket.io-msgpack-parser")

let audio: any = new Audio(require("../../assets/tong.mp3"));

const SOCKET_BASE_URL =
  process.env.REACT_APP_SOCKET_BASE_URL || "http://localhost:8082";

const useSocket = (
  url: string = SOCKET_BASE_URL,
  options?: Partial<ManagerOptions & SocketOptions> | undefined
) => {
  let socketRef = useRef<Socket | any>(null);
  const dispatch = useDispatch();

  let commService = useMemo(() => {
    return HostWindowCommService.getInstance();
  }, []);

  const { botConfig } = useAppSelector(useBotConfigStore);
  const { visitorId, botId, widgetPreviewType, isOpenedFirstTime,getaHost } =
    useAppSelector(botInfoStore);
  const { typingStatus, sessionId, botLanguage } =
    useAppSelector(allMessagesStore);

  // Create a ref for sessionId
  const sessionIdRef = useRef(sessionId);
  // Update the ref whenever sessionId changes to avoid closures scopes below.
  useEffect(() => {
    sessionIdRef.current = sessionId;
  }, [sessionId]);

  const botHeaders =
    useMemo(
      () => (
        {
      appid: JSON.stringify({
        botid: botId,
      }),
      "geta-host": getaHost,
      visitor_id: visitorId || null,
      preview:
        widgetPreviewType === PreviewType.getaWidgetPreview ? true : false,
      connection_type: "user",
      username:sessionIdRef.current
  }
    ),
    [botId, visitorId, widgetPreviewType,getaHost,sessionIdRef]
  );

  //* keep recieve msg fn in socketRef?.current dependency otherwise it will not work
  useEffect(() => {
    if (!socketRef.current) return;

    dispatch(setSocketStatus(socketRef?.current));

    setupAllListeners();
  }, [socketRef?.current]); // eslint-disable-line react-hooks/exhaustive-deps


  // ********************************************************

  // ─── REQUEST DATA EVENTS ──────────────────────────────────────────────────────────────

  // ********************************************************

  // const sendRequestNewBotSessionEvent = useCallback(() => {

  //   if (socketRef?.current) {
  //     socketRef.current?.emit(
  //       SOCKET_CONNECTION_TYPES.INITIATE_BOT_SESSION,
  //       botHeaders
  //     );
  //   }
  // }, [botHeaders]);

  const sendRequestUserReconnectEvent = useCallback(() => {
    if (sessionIdRef.current && socketRef.current) {
      const reconnectData = {
        session_id: sessionIdRef.current,
        socket_id: socketRef.current?.id,
      };

      // console.warn("reconnectData", reconnectData);
      socketRef.current?.emit(
        SOCKET_CONNECTION_TYPES.USER_RECONNECT,
        reconnectData
      );
    }
  }, []);

  const sendRequestUserConnectEvent = useCallback((data:any) => {
    if (sessionIdRef.current && socketRef.current) {
      const connectData = {
        ...data,
        // session_id: sessionIdRef.current,
        socket_id: socketRef.current?.id,
      };

      // console.warn("user connect", connectData);
      socketRef.current?.emit(
        SOCKET_CONNECTION_TYPES.USER_CONNECT,
        connectData,(error: any, response: any) => {
          if (error) {
            dispatch(removeMessage(data));
          }
        } 
      );
    }
  }, [socketRef]); // eslint-disable-line react-hooks/exhaustive-deps



  const sendRequestBotFirstFlowEvent = useCallback(() => {
    if (socketRef.current) {
      let data = {
        session_id: sessionIdRef.current,
        language: botLanguage,
      };
      socketRef?.current?.emit(
        SOCKET_CONNECTION_TYPES.BOT_CONVERSATION,
        data
      );
    } else {
      connectSocketEvent();
    }
  }, [botLanguage]); // eslint-disable-line react-hooks/exhaustive-deps

  // const sendRequestBotHistoryFlowEvent = useCallback(() => {
  //   if (socketRef.current?.connected && sessionIdRef.current) {
  //     let data = {
  //       session_id: sessionIdRef.current,
  //       socket_id: socketRef.current?.id,
  //     };
  //     socketRef.current?.emit(
  //       SOCKET_CONNECTION_TYPES.BOT_CONVERSATION_HISTORY,
  //       data, (error: any) => {
  //         if (error) {
  //           console.error("error while getting history", error);
  //         }
  //         else {
  //           // console.log("Bot conversation history fetched successfully");
  //         }
  //       }
  //     );
  //   }
  // }, []);

  // ********************************************************

  // ─── CONNECTIONS EVENTS ──────────────────────────────────────────────────────────────

  // ********************************************************

  const connectSocketEvent = () => {

    //ignore if socket is already connected
    if (socketRef?.current?.connected) return;

    const op: Partial<ManagerOptions & SocketOptions> = {
      auth: botHeaders as any,
      secure: true,
      transports: ["websocket"], 
      withCredentials: true,
      parser: parser
    };
    console.warn(`outgoing event: [${SOCKET_CONNECTION_TYPES.CONNECT}] ---`, op);
    socketRef.current = io(url, op);
    socketRef.current.on(SOCKET_CONNECTION_TYPES.CONNECT, () => {
      if (socketRef.current?.id && socketRef.current.connected) {
        dispatch(setSocketStatus(socketRef.current));
        // sendRequestUserReconnectEvent();
        // console.log("frontend connected");
      }
    });
  };

  const disconnectSocketEvent = (
    disconnectType: "leave" | "reconnect" = "leave"
  ) => {
    if (socketRef.current) {
      dispatch(setAllMessages([])); //remove only messages
      socketRef.current.disconnect();
      socketRef.current = null;
      dispatch(setSocketStatus(null));
    }
  };





  const connectSocketEventWithReConnectUser = () => {

    //ignore if socket is already connected
    if (socketRef?.current?.connected) {
      sendRequestUserReconnectEvent();
      return;  
    };

    const op: Partial<ManagerOptions & SocketOptions> = {
      auth: botHeaders as any,
      secure: true,
      transports: ["websocket"], 
      withCredentials: true,
      parser: parser
    };
    console.warn(`outgoing event: [${SOCKET_CONNECTION_TYPES.CONNECT}] ---`, op);
    socketRef.current = io(url, op);
    socketRef.current.on(SOCKET_CONNECTION_TYPES.CONNECT, () => {
      if (socketRef.current?.id && socketRef.current.connected) {
        dispatch(setSocketStatus(socketRef.current));
        sendRequestUserReconnectEvent();
        // console.log("frontend connected");
      }
    });
  };

  // *************************custom fn for api approch*******************************
  const connectSocketEventWithConnectUser = (data:any) => {
    //ignore if socket is already connected
    if (socketRef?.current?.connected) {
      sendRequestUserConnectEvent(data);
      return;  
    };

    const op: Partial<ManagerOptions & SocketOptions> = {
      auth: {
        ...botHeaders as any,
        username: data?.session_id 
      },
      secure: true,
      transports: ["websocket"], 
      withCredentials: true,
      parser: parser
    };
    console.warn(`outgoing event: [${SOCKET_CONNECTION_TYPES.CONNECT}] ---`, op);
    socketRef.current = io(url, op);
    socketRef.current.on(SOCKET_CONNECTION_TYPES.CONNECT, () => {
      if (socketRef.current?.id && socketRef.current.connected) {
        dispatch(setSocketStatus(socketRef.current));
        sendRequestUserConnectEvent(data);
        // console.log("frontend connected");
      }
    });
  };

  // ********************************************************

  // ─── GET DATA EVENTS LISTENERS ──────────────────────────────────────────────────────────────

  // ********************************************************

  const receiveSessionIdEvent = () => {
    socketRef.current.on(
      SOCKET_CONNECTION_TYPES.GET_SESSION_ID,
      (response: any) => {

        dispatch(setSessionId(response?.data));
        dispatch(setIsSessionIdFromLocalStorage(false));

        //* do not store session id in local storage if widget preview type is getaWidgetPreview
        let tempId =
          widgetPreviewType !== PreviewType.getaWidgetPreview
            ? response?.data
            : null ?? null;

        commService.postMessage({
          event_type: OEvents.CHATBOT_SESSION_ID,
          data: tempId,
        });
      }
    );
  };

  const receiveUserConnectInfoEvent = () => {
    socketRef.current.on(
      SOCKET_CONNECTION_TYPES.USER_CONNECT_RESPONSE,
      (response: any) => {
        //* live chat flag
        let agentConnectInfo = response?.agent_connected || false;
        dispatch(setLiveChatConnected(agentConnectInfo));
      }
    );
  };

  const receiveUserReConnectInfoEvent = () => {
    socketRef.current.on(
      SOCKET_CONNECTION_TYPES.USER_RECONNECT_RESPONSE,
      (response: any) => {
        //* live chat flag

        let agentConnectInfo = response?.agent_connected || false;
        dispatch(setLiveChatConnected(agentConnectInfo));

          // sendRequestBotHistoryFlowEvent();
        }
      );
  };

  const receiveMessageEvent = () => {
   
      socketRef.current.on(
        SOCKET_CONNECTION_TYPES.RECEIVE_MESSAGE,
        (response: any) => {
          // console.log("receive_message", response?.data);
          if (
            response?.data?.response?.length <= 0 ||
            !response?.data?.response
          ) {
            return;
          }

        if (!response?.data?.response || response?.data?.response?.length === 0) return;

        if (response?.data?.["subType"] === "button") {
          let temp = {
            value: response?.data?.response,
            type: "button",
            time: response?.data?.["createdAt"],
          };
          dispatch(setAllMessages(temp));
        } else {
          dispatch(setAllMessages(response?.data?.response));
        }



        dispatch(setLastNodeResponse(response?.data));
        dispatch(setOriginalResponses(response?.data));

        if (!typingStatus) {
          playSoundLastBotMsg();
        }
      }
    );
  };

  const receiveHistoryMessageEvent = () => {
    socketRef.current.on(
      SOCKET_CONNECTION_TYPES.RECEIVE_MESSAGE_HISTORY,
      (response: any) => {
        // let history: any = JSON.parse(response?.data);
        let history: any = response?.data ?? null;

        if (
          !history || history?.chat_data?.length === 0 ||
          !Array.isArray(history?.chat_data)
        ) {
          dispatch(setSessionId(null));
          dispatch(setIsSessionIdFromLocalStorage(false));
          sendRequestBotFirstFlowEvent();
          return;
        }

        if (history?.user?.botid !== botId) {
          console.warn("bot id not matched");
          sendRequestBotFirstFlowEvent();
          return;
        }

        let payload: any = [];

        history?.chat_data?.forEach((item: any, index: any) => {
          if (item?.["user_query_object"]) {
            let temp = {
              ...item?.["user_query_object"],
              time: item?.["createdAt"],
            };
            payload.push(temp);
          }

          if (item?.["bot_response"]?.["response"]?.length > 0) {
            if (item?.["bot_response"]?.["subType"] === "button") {
              let temp = {
                value: item?.["bot_response"]?.["response"],
                type: "button",
                time: item?.["createdAt"],
              };
              payload.push(temp);
            }
            else {
              item?.["bot_response"]?.["response"]?.forEach((curRes: any) => {
                let temp = {
                  ...curRes,
                  time: item?.["createdAt"],
                };
                payload.push(temp);
              });
            }
          }

          if (index + 1 === history?.chat_data?.length) {
            dispatch(
              setLastNodeResponse(
                item?.["user_query_object"] ?? item?.["bot_response"]
              )
            );
          }

          dispatch(setOriginalResponses(item?.["bot_response"]));
        });

        dispatch(setAllHistoryMessages(payload));
        dispatch(setIsSessionIdFromLocalStorage(true));
      }
    );
  };

  const receiveTypingStatusEvent = () => {
    socketRef.current.on(
      SOCKET_CONNECTION_TYPES.TYPING_STATUS,
      (response: any) => {
        dispatch(setTypingStatus(response?.data));
      }
    );
  };

  const receiveSocketDisconnectReason = () => {
    socketRef.current.on(
      SOCKET_CONNECTION_TYPES.DISCONNECT,
      (response: any) => {
        console.log(`user disconnected:`, response);
        // dispatch(setSocketStatus(null));
        // Handle the disconnection reason or perform any necessary actions
      }
    );
  };

  const receiveSocketConnectErrorReason = () => {
    socketRef.current.on(
      SOCKET_CONNECTION_TYPES.CONNECT_ERROR,
      (response: any) => {
        console.error("Connection failed:", response);
        // Handle the error or perform any necessary actions
      }
    );
  };

  const receiveSocketConnectTimeoutReason = () => {
    socketRef.current.on(
      SOCKET_CONNECTION_TYPES.CONNECT_TIMEOUT,
      (response: any) => {
        console.error("Connection timeout", response);
        // Handle the timeout here
      }
    );
  };
  const receiveSocketReconnectReason = () => {
    socketRef.current.on(
      SOCKET_CONNECTION_TYPES.RECONNECT,
      (response: any) => {
        sendRequestUserReconnectEvent();
        // Handle the reconnection here
      }
    );
  };

  const debugIncomingSocketEvents = () => {
    if (!socketRef?.current) return;

    socketRef.current.onAny((event: string, ...args: any) => {
      console.warn(`incoming event: [${event}] ---`, args?.[0]);
    });

    socketRef.current?.onAnyOutgoing((event: string, ...args: any) => {
      console.warn(`outgoing event: [${event}] ---`, args?.[0]);
    });
  }

  // ********************************************************

  // ─── UTILS SOCKET EVENTS ──────────────────────────────────────────────────────────────

  // ********************************************************

  const setupAllListeners = () => {

    receiveSessionIdEvent();
    receiveMessageEvent();

    receiveTypingStatusEvent();
    receiveUserConnectInfoEvent();

    receiveUserReConnectInfoEvent();
    receiveHistoryMessageEvent();

    //* connection events
    receiveSocketDisconnectReason();
    receiveSocketConnectErrorReason();
    receiveSocketConnectTimeoutReason();
    receiveSocketReconnectReason();

    //debugging   
    debugIncomingSocketEvents();
  };

  const removeAllListeners = () => {
    if (!socketRef.current) return;

    // Assuming each receive function sets up a listener, you can remove them like this:
    socketRef.current.off(SOCKET_CONNECTION_TYPES.GET_SESSION_ID);
    socketRef.current.off(SOCKET_CONNECTION_TYPES.RECEIVE_MESSAGE);
    socketRef.current.off(SOCKET_CONNECTION_TYPES.USER_RECONNECT_RESPONSE);
    socketRef.current.off(SOCKET_CONNECTION_TYPES.USER_CONNECT_RESPONSE);
    socketRef.current.off(SOCKET_CONNECTION_TYPES.RECEIVE_MESSAGE_HISTORY);
    socketRef.current.off(SOCKET_CONNECTION_TYPES.TYPING_STATUS);
    socketRef.current.off(SOCKET_CONNECTION_TYPES.DISCONNECT);
    socketRef.current.off(SOCKET_CONNECTION_TYPES.CONNECT_ERROR);
    socketRef.current.off(SOCKET_CONNECTION_TYPES.CONNECT_TIMEOUT);
    socketRef.current.off(SOCKET_CONNECTION_TYPES.RECONNECT);
  };

  const playSoundLastBotMsg = useCallback(() => {
    if (isOpenedFirstTime && botConfig.config?.message_tone?.enabled) {
      audio.volume =
        (botConfig.config?.message_tone?.volume_level || 100) / 100;
      audio.play();
    }
  }, [botConfig]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    socket: socketRef?.current,
    connectSocketEvent,
    disconnectSocketEvent,
    connectSocketEventWithReConnectUser,
    connectSocketEventWithConnectUser,
  };
};

export default useSocket;

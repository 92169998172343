export enum API_ENDPOINTS {
    CORE_API = "core_api",
    CH_BOT_API = "ch_bot_api",
    CH_CONSOLE_API = "ch_console_api"
}

export class CH_BOT_ENDPOINTS {
    static HISTORY = "/chat-history"
    static QUERY = "/query"
    static PREVIEW_QUERY = "/preview-query"
    static SAVE_VISITOR_ID = "/save_visitor_id"
    static LIVE_CHAT = "/live-agent"
    static SWITCH_LANGUAGE = "/switch-language?language={0}"
    static SEARCH_FAQ_VARIATIONS = '/variations/suggest'
    static LEAD_OUTSIDE_BUSINESS_HOURS = '/get_lead_outside_business_hrs'
    static RESENDOTP = '/resend-otp'
    static ATTACHMENT = '/attachment'
    static SUBMIT_CHAT_FEEDBACK = '/chat-rating'
}

export class CORE_ENDPOINTS {
    static AUTH_LOGIN = "/auth/login/"
    static PROJECTS = "/core/project/"
    static PROJECT_VER_CONFIG = "/core/project-version/{0}/configuration/"
}

export class CH_CONSOLE_ENDPOINTS {
    // static CHATBOT_SETUP = "/chatbot/setup/"
    // static CHATBOTS = "/chatbot/"
    static CHATBOT_WIDGET_CONFIG = "/widget-config/"
    static PUBLIC_UPLOAD_FILE = "/workspace/whatsapp-resolve-media-attach";
}

export class MATOMO_ENDPOINTS {
    static HOME = "/"
}
import { AxiosResponse } from 'axios';
import { Chatbot, ChatbotTheme } from '../../Models';
import { BotConfig } from '../../Models/BotConfig';
import { ChatbotConfiguration } from '../../Models/ChatbotConfiguration';
import { chatbotConsoleAxios } from '../../Utils/Axios';
import { getHttpService } from "../HttpService";



import {
  CH_CONSOLE_ENDPOINTS
} from "./Endpoints";


export class ChatbotConsoleService {
  static uploadWhatsappMedia(formData: FormData) {
    throw new Error("Method not implemented.");
  }
  static httpService: any = getHttpService(chatbotConsoleAxios);
  static getBotWidgetConfig = (queryData = {}, config = {}): Promise<AxiosResponse<BotConfig>> => {
    return ChatbotConsoleService.httpService.get(CH_CONSOLE_ENDPOINTS.CHATBOT_WIDGET_CONFIG, queryData, config);
  };



  static uploadFileRichEditor = (data: any): Promise<AxiosResponse<any>> => {
    const {formData, config} = data;
    return ChatbotConsoleService.httpService.upload(CH_CONSOLE_ENDPOINTS.PUBLIC_UPLOAD_FILE, formData, {},config);
  };

}

import React, { memo } from "react";
import { MESSAGE_TYPES } from "../../../../Models/Enum";
import { useAppSelector } from "../../../../Store";
import { allMessagesStore } from "../../../../Store/Slices/socket/AllMessagesSlice";
import { AppMaterialIcons } from "../../Icons";
import AppSmallButton from "../../UtilsComponents/AppSmallButton";

interface Props {
  item: any;
  rowWrapperStyle?: React.CSSProperties | undefined;
  containerStyle?: React.CSSProperties | undefined;
  time: string;
  //   readStatus?: "sent" | "delivered" | "seen";
  [otherProps: string]: any;
}

const AppRenderNestedCtaButtons: React.FC<Props> = ({
  userMessage,
  item,
  rowWrapperStyle,
  containerStyle,
  time,
  readStatus = "delivered",
}) => {
  const { typingStatus } = useAppSelector(allMessagesStore);

  if (item.type === MESSAGE_TYPES.LINK) {
    let finalUrl: string = item?.value?.url;
    if (
      !item?.value?.url.includes("https://") &&
      !item?.value?.url.includes("Https://") &&
      !item?.value?.url.includes("http://")
    ) {
      finalUrl = "https://" + item?.value?.url;
    }
    return (
      <AppSmallButton
        component="a"
        startIcon={
          <AppMaterialIcons iconName="OpenInNew" style={{ fontSize: "14px" }} />
        }
        target="_blank"
        rel="noreferrer"
        variant="primaryoutlinebutton"
        style={{ pointerEvents: typingStatus ? "none" : "auto" }}
        href={finalUrl}
      >
        {item?.value?.text ?? item?.value?.url}
      </AppSmallButton>
    );
  }

  if (item.type === MESSAGE_TYPES.MOBILE) {
    return (
      <AppSmallButton
        component="a"
        startIcon={
          <AppMaterialIcons iconName="AddIcCall" style={{ fontSize: "14px" }} />
        }
        target="_blank"
        rel="noreferrer"
        variant="primaryoutlinebutton"
        href={`tel:${item?.value?.url}`}
        style={{ pointerEvents: typingStatus ? "none" : "auto" }}
      >
        {item?.value?.text ?? item?.value?.url}
      </AppSmallButton>
    );
  }

  if (item.type === MESSAGE_TYPES.EMAIL) {
    const finalUrl: string = `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${item?.value?.url}`;
    return (
      <AppSmallButton
        component="a"
        startIcon={
          <AppMaterialIcons
            iconName="AlternateEmail"
            style={{ fontSize: "14px" }}
          />
        }
        href={finalUrl}
        target="_blank"
        rel="noreferrer"
        variant="primaryoutlinebutton"
      >
        {item?.value?.text ?? item?.value?.url}
      </AppSmallButton>
    );
  }

  return null;
};

export default memo(AppRenderNestedCtaButtons);

import {
  CommMessage,
  IEvents,
  INCOMING_EVENTS,
  OEvents,
  OUTGOING_EVENTS,
} from "./models";

const targetWindow = window.parent;

export class HostWindowCommService {
  static instance: HostWindowCommService;
  parent_window: Window;
  ievent_fn_map: Map<string, (event: any) => void>;
  initMessageListeners: () => void;

  constructor() {
    this.parent_window = targetWindow;
    this.ievent_fn_map = new Map();
    this.initMessageListeners = () => {
      window.addEventListener(
        "message",
        (event) => {
          if (event.data.hasOwnProperty("event_type")) {
            const event_name = event.data.event_type;
            if (INCOMING_EVENTS.has(event_name)) {
              if (this.ievent_fn_map.has(event_name)) {
                const callback = this.ievent_fn_map.get(event_name);
                callback && callback(event);
              }
            }
          }
        },
        false
      );
    };
    this.initMessageListeners();
  }
  registerEventListener(
    event_name: IEvents,
    event_callback: (event: any) => void
  ) {
    if (INCOMING_EVENTS.has(event_name)) {
      this.ievent_fn_map.set(event_name, event_callback);
    } else {
      throw new Error("IEvent Type not available!");
    }
  }

  postMessage(message: CommMessage) {
    if (this.parent_window) {
      if (OUTGOING_EVENTS.has(message.event_type as OEvents)) {
        this.parent_window.postMessage(message, "*");
      } else {
        throw new Error("OEvent Type not available!");
      }
    } else {
      throw new Error("Parent Window not available!");
    }
  }

  static getInstance() {
    if (!HostWindowCommService.instance) {
      HostWindowCommService.instance = new HostWindowCommService();
    }
    return HostWindowCommService.instance;
  }
}

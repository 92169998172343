import React from "react";
import uniqid from "uniqid";
import AppMessageBoxWrapper from "../../UtilsComponents/AppMessageBoxWrapper";
import { StyledMessageText } from "./../../../Styles/StyledComponents";

interface Props {
  children: any;

  [otherProps: string]: any;
}
const AppRenderNestedText: React.FC<Props> = React.memo(
  ({
    children,

    ...otherProps
  }) => {
    if (!children || children?.length < 1) {
      return null;
    }

    return (
      <StyledMessageText
        dangerouslySetInnerHTML={{
          __html: children,
        }}
        style={{
          width: "100%",
          display: "block",
          padding: "0px",
        }}
        {...otherProps}
      />
    );
  }
);

export default AppRenderNestedText;

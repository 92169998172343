import React, { memo } from "react";
import AppMessageBoxWrapper from "../UtilsComponents/AppMessageBoxWrapper";
import { StyledMessageText } from "../../Styles/StyledComponents";

const FallbackImage = require("../../../assets/images/Image_not_available.png");

interface Props {
  src: string;
  alt?: string;
  userMessage: boolean;
  time: string;
  children?: any;
  readStatus?: "sent" | "delivered" | "sent";
  [otherProps: string]: any;
}

const AppRenderImage: React.FC<Props> = ({
  src = "",
  alt = "",
  time,
  userMessage = false,
  readStatus = "delivered",
}) => {
  if (!src) {
    return null;
  }
  return (
    <AppMessageBoxWrapper
      userMessage={userMessage}
      isMedia={true}
      time={time}
      readStatus={readStatus}
    >
      <a href={src} target="_blank" rel="noreferrer">
        <img
          className="renderImage"
          src={src}
          alt={alt}
          // onClick={() => window.open(src, "_blank")}
          onError={(error: any) => {
            error.target.src = FallbackImage;
          }}
        />
      </a>
      {alt && alt?.length > 0 ? (
        <StyledMessageText
          userMessage={userMessage}
          dangerouslySetInnerHTML={{
            __html: alt,
          }}
        />
      ) : null}
    </AppMessageBoxWrapper>
  );
};

export default memo(AppRenderImage);

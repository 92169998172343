import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { botReducer } from './Slices/ChatbotSlices/index';
import { configureStore } from "@reduxjs/toolkit";

const store = configureStore({
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck:false
  }),
  reducer: {
    botData: botReducer 
  },
});
export * from './Slices';
export default store;

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch; // Export a hook that can be reused to resolve types
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector; // Export a hook that can be reused to resolve types

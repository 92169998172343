import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { IconButton, Tooltip } from "@mui/material";
import Grow from "@mui/material/Grow";
import { getIn, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import { theme } from "../../../Customization/Theme";
import { ConversationService } from "../../../Services/Apis";
import { useBotConfigStore } from "../../../Store/Slices/ChatbotSlices/BotConfigSlice";
import AppErrorMessage from "./AppErrorMessage";
import AppSendSubmitButton from "./AppSendSubmitButton";
import { useAppSelector } from "../../../Store";
import { botInfoStore } from "../../../Store/Slices/socket/BotInfoSlice";

enum ImageState {
  INITIAL = "initial",
  SEARCH = "search",
  GALLERY = "gallery",
  UPLOADED = "uploaded",
}

interface Props {
  name?: string;
  mediaExtensions: any[];
}

const getCorrectMediaSize = (type: string) => {
  var size: number = 5000000;

  if (type === "Video") {
    if (size < 16000000) {
      size = 16000000;
    }
  } else if (type === "Images") {
    if (size < 5000000) {
      size = 5000000;
    }
  } else if (type === "Documents") {
    if (size < 10000000) {
      size = 10000000;
    }
  } else if (type === "Sticker") {
    if (size < 1000000) {
      size = 1000000;
    }
  } else if (type === "Audio") {
    if (size < 16000000) {
      size = 16000000;
    }
  }

  return size;
};

const AppFlowUploadField: React.FC<Props> = ({
  name = "attachment",
  mediaExtensions,
}) => {
  const {
    setFieldTouched,
    values,
    setFieldValue,
    setFieldError,
    errors,
    touched,
    handleSubmit,
  } = useFormikContext<any>();
  const {getaHost } = useAppSelector(botInfoStore);


  let requiredExtensions: string[] = [];
  mediaExtensions?.forEach((item: any) => {
    item?.extensions?.forEach((ele: any) => {
      requiredExtensions?.push(ele?.value);
    });
  });

  const [mainState, setMainState] = useState<ImageState>(ImageState.INITIAL); // initial, search, gallery, uploaded

  useEffect(() => {
    if (
      getIn(values, name)?.source &&
      getIn(values, name)?.source?.length > 0
    ) {
      setMainState(ImageState.UPLOADED);
    } else {
      setMainState(ImageState.INITIAL);
    }
  }, [getIn(values, name)]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleUploadClick = (event: any) => {
    setFieldTouched(name, true);

    var file = event.target.files[0];
    const reader = new FileReader();
    var url = reader?.readAsDataURL(file);

    reader.onloadend = (e: any) => {
      let mimeType: any = e?.target?.result
        ?.split(",")[0]
        .split(":")[1]
        .split(";")[0];

      if (!requiredExtensions?.includes(mimeType)) {
        setFieldError(name, "Invalid File format");
        return;
      }

      let uploadExtensionMediaType: string = "";
      mediaExtensions?.forEach((item: any) => {
        item?.extensions?.forEach((ele: any) => {
          if (ele?.value === mimeType) {
            uploadExtensionMediaType = item?.type;
          }
        });
      });

      const allowedFileSize = getCorrectMediaSize(uploadExtensionMediaType);
      if (allowedFileSize && file?.size > allowedFileSize) {
        setFieldError(
          name,
          `${uploadExtensionMediaType} size must be smaller than ${
            allowedFileSize / 1000000
          } MB`
        );
        return;
      }

      setFieldError(name, "");
      onImageChange(file);
    };
  };

  //   Make upload api call
  const { botConfig } = useSelector(useBotConfigStore);
  const HEADERS = {
    appid: JSON.stringify({
      botid: botConfig?.chatbot?.bot_id || "",
    }),
    "GETA-HOST": getaHost,
  };
  const onImageChange = (file: any) => {
    setFieldTouched(name, true);
    if (file) {
      const formData = new FormData();
      formData.append("source", file);
      ConversationService.uploadMedia(formData, HEADERS)
        .then((response: any) => {
          setFieldValue(name, response.data);
          setMainState(ImageState.UPLOADED);
        })
        .catch((error: any) => {
          setFieldError(name, `Media upload failed. Please try again.`);
        })
        .finally(() => {});
    }
  };

  const imageResetHandler = () => {
    setMainState(ImageState.INITIAL);
    setFieldValue(name, null);
  };

  const renderInitialState = () => {
    return (
      <>
        <StyledIntialState>
          <input
            accept={requiredExtensions.toString()}
            id={name}
            name={name}
            value=""
            type="file"
            onChange={handleUploadClick}
          />
          <label htmlFor={name}>
            <Tooltip
              arrow
              enterDelay={1}
              leaveDelay={0}
              TransitionComponent={Grow}
              placement="top"
              title={"Upload"}
            >
              <MediaUploadBox>
                Upload
                <AttachFileIcon />
              </MediaUploadBox>
            </Tooltip>
          </label>
        </StyledIntialState>
      </>
    );
  };

  const RenderUploadedState = () => {
    const file: any = getIn(values, name);
    // const { handleSubmit,
    // } = useFormikContext<any>();
    return (
      <>
        <StyledUploadedState>
          <StyledUploadedImageWrap>
            {file?.name || file?.file_name}
            <Tooltip
              arrow
              enterDelay={1}
              leaveDelay={0}
              TransitionComponent={Grow}
              placement="top"
              title={"Delete"}
            >
              <IconButton onClick={() => imageResetHandler()}>
                <ClearRoundedIcon />
              </IconButton>
            </Tooltip>
          </StyledUploadedImageWrap>
          <AppSendSubmitButton
            style={getIn(values, name) ? {} : { color: "#7E8392" }}
          />
        </StyledUploadedState>
      </>
    );
  };
  console.log("err", getIn(errors, name), errors);
  return (
    <>
      <StyledViewWrap>
        {(mainState === ImageState.INITIAL && renderInitialState()) ||
          (mainState === ImageState.UPLOADED && RenderUploadedState())}
        <AppErrorMessage
          error={getIn(errors, name)}
          visible={getIn(touched, name)}
        />
      </StyledViewWrap>
    </>
  );
};

export default AppFlowUploadField;

const StyledUploadedImageWrap = styled.div`
  && {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: 14px;
    svg {
      cursor: pointer;
      font-size: 1.1rem;
      color: ${theme.palette.default.darkGrey};
    }
  }
`;
const MediaUploadBox = styled.div`
  && {
    word-break: keep-all;
    white-space: nowrap;
    text-transform: capitalize;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    min-height: 40px;
    height: 40px;
    width: 100%;

    padding: 6px 12px;
    border-radius: 6px;
    background-color: transparent;
    color: ${theme.palette.default.headerBackground};
    border: none;
    outline: none;
    cursor: pointer;
    font-family: ${theme.typography.fontFamily};
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    background: ${theme.palette.default.white};
    &:disabled {
      color: ${theme.palette.default.grey};
    }
    &:hover {
      color: ${theme.palette.default.headerBackground};
    }
    svg {
      font-size: 18px;
      margin: 0 4px;
    }
  }
`;

const StyledViewWrap = styled.div`
  && {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    gap: 0.5rem;
    background: ${theme.palette.default.white};
    input {
      display: none;
    }
  }
`;

const StyledIntialState = styled.div`
  && {
    width: 100%;
    height: 100%;
    padding: 0;
    display: flex;
    align-items: center;
    gap: 1rem;
    background: ${theme.palette.default.white};
    border: 1px solid ${theme.palette.default.border};
  }
  label {
    display: flex;
    width: 100%;
  }
`;

const StyledUploadedState = styled.div`
  && {
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding-left: 10px;
    display: flex;
    align-items: center;
    border: 1px solid ${theme.palette.default.border};
    img {
      width: 52px !important;
      height: 52px !important;
      object-fit: cover !important;
      overflow: hidden;
    }
  }
`;

import { useFormikContext } from "formik";
import React from "react";
import AppSmallButton from "../UtilsComponents/AppSmallButton";

interface Props {
  title: string;
  children?: React.ReactNode;
  [otherProps: string]: any;
}
const AppSubmitButton: React.FC<Props> = ({
  title = "Submit",
  ...otherProps
}) => {
  const { handleSubmit, isSubmitting, isValid } = useFormikContext();

  return (
    <AppSmallButton
      onClick={() => handleSubmit()}
      loading={isSubmitting}
      disabled={isSubmitting && !isValid}
      loadingPosition="center"
      {...otherProps}
    >
      {title}
    </AppSmallButton>
  );
};

export default AppSubmitButton;

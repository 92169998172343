import { IconButton } from "@mui/material";
import React from "react";
import "slick-carousel/slick/slick.css";
import { WidgetSliderBox } from "../..//Styles/StyledComponents";
import { StyledSlickSlider } from "../../Styles/StyledComponents";

import { MESSAGE_TYPES } from "../../utils/Enum";
import { AppMaterialIcons } from "../Icons";
import AppMessageBoxWrapper from "../UtilsComponents/AppMessageBoxWrapper";
import AppRenderNestedFlowButtons from "./nestedFlow/AppRenderNestedFlowButtons";
import AppRenderNestedCtaButtons from "./nestedFlow/AppRenderNestedCtaButtons";
import AppRenderNestedLiveButtons from "./nestedFlow/AppRenderNestedLiveButtons";

const FallbackImage = require("../../../assets/images/Image_not_available.png");

interface SliderProps {
  userMessage: boolean;
  items: any[];
  time: string;
  readStatus?: "sent" | "delivered" | "seen";
  [otherProps: string]: any;
}

const AppRenderSlider = React.memo(
  ({
    userMessage = false,
    items,
    time,
    readStatus = "delivered",
    ...otherProps
  }: SliderProps) => {
    return (
      <AppMessageBoxWrapper
        userMessage={userMessage}
        time={time}
        isMedia={true}
        readStatus={readStatus}
        divStyle={{
          flexWrap: "nowrap",
          // minWidth: "50%",
          // maxWidth: "300px",
          backgroundColor: "white",
        }}
      >
        <StyledSlickSlider {...settings}>
          {items?.length &&
            items?.map((item: any, index: number) => {
              return (
                <WidgetSliderBox key={index.toString()}>
                  {item?.image ? (
                    <a href={item?.image} target="_blank" rel="noreferrer">
                      <img
                        className="sliderImage"
                        src={item?.image}
                        alt={"image " + (index + 1)}
                        // onClick={() => window.open(item?.image, "_blank")}
                        onError={(error: any) => {
                          error.target.src = FallbackImage;
                        }}
                      />
                    </a>
                  ) : null}

                  <p
                    className="title"
                    dangerouslySetInnerHTML={{
                      __html: item?.title ? item?.title : null,
                    }}
                  />
                  <p
                    className="subTitle"
                    dangerouslySetInnerHTML={{
                      __html: item?.subTitle ? item?.subTitle : null,
                    }}
                  />
                  <p
                    className="description"
                    dangerouslySetInnerHTML={{
                      __html: item?.text ? item?.text : null,
                    }}
                  />

                  <div className="btnWrapper">
                    {item?.buttons?.map((curBtn: any, index: number) => {
                      //* FLOW BUTTONS
                      if (curBtn?.type === MESSAGE_TYPES.FLOW) {
                        return (
                          <AppRenderNestedFlowButtons
                            key={index.toString()}
                            userMessage={userMessage}
                            item={curBtn}
                            time={time}
                          />
                        );
                      }
                      //* CTA BUTTONS
                      if (
                        curBtn?.type === MESSAGE_TYPES.LINK ||
                        curBtn?.type === MESSAGE_TYPES.MOBILE ||
                        curBtn?.type === MESSAGE_TYPES.EMAIL
                      ) {
                        return (
                          <AppRenderNestedCtaButtons
                            item={curBtn}
                            key={index.toString()}
                            time={time}
                          />
                        );
                      }

                      //* LIVE BUTTONS
                      if (curBtn?.type === MESSAGE_TYPES.LIVE_AGENT_REQUESTED) {
                        return (
                          <AppRenderNestedLiveButtons
                            key={index.toString()}
                            item={curBtn}
                            time={time}
                          />
                        );
                      }

          
                      return null;
                    })}
                  </div>
                </WidgetSliderBox>
              );
            })}
        </StyledSlickSlider>
      </AppMessageBoxWrapper>
    );
  }
);

export default AppRenderSlider;

const CustomNextArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <IconButton
      onClick={onClick}
      className={`${className} nextPreBtn`}
      style={{ ...style }}
    >
      <AppMaterialIcons iconName={"ChevronRight"} />
    </IconButton>
  );
};

const CustomPrevArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <IconButton
      onClick={onClick}
      className={`${className} nextPreBtn`}
      style={{ ...style }}
    >
      <AppMaterialIcons iconName={"ChevronLeft"} />
    </IconButton>
  );
};

const settings = {
  className: "slickSlider",
  adaptiveHeight: true,
  lazyLoad: true,
  fade: true,
  dots: true,
  infinite: true,
  speed: 500,
  //   autoplay: true,
  //   autoplaySpeed: 2000,
  pauseOnHover: true,
  swipeToSlide: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,

  nextArrow: <CustomNextArrow />,
  prevArrow: <CustomPrevArrow />,
};

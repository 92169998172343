import React, { memo } from "react";
import {
  StyledMessageText,
  StyledReactVideoPlayer,
} from "../../Styles/StyledComponents";
import AppMessageBoxWrapper from "../UtilsComponents/AppMessageBoxWrapper";

interface Props {
  src: string;
  alt?: string;
  userMessage: boolean;
  time: string;
  children?: any;
  readStatus?: "sent" | "delivered" | "seen";
  [otherProps: string]: any;
}

const AppRenderVideo: React.FC<Props> = ({
  src = "",
  alt = "",
  userMessage = false,
  time,
  readStatus = "delivered",
}) => {
  if (!src) {
    return null;
  }
  return (
    <AppMessageBoxWrapper
      userMessage={userMessage}
      isMedia={true}
      time={time}
      readStatus={readStatus}
    >
      <StyledReactVideoPlayer
        url={src}
        width={"100%"}
        controls={true}
        light={false}
        alt={alt}
        height={180}
      />
      {alt && alt?.length > 0 ? (
        <StyledMessageText
          userMessage={userMessage}
          dangerouslySetInnerHTML={{
            __html: alt,
          }}
        />
      ) : null}
    </AppMessageBoxWrapper>
  );
};

export default memo(AppRenderVideo);

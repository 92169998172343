import { createTheme } from "@mui/material";
import Matercolor from "matercolors";
import { ChatbotTheme, FontOptions } from "../Models";
import { ColorUtils } from "../Utils/ColorUtils";
import { FONT, PRIMARY } from "./ThemeConstants";
import botConfiguration from "./botConfiguration";

const getDefaultFont = (initOptions?: FontOptions | any) => {
  const returnValue: any = { ...FONT };
  if (initOptions) {
    const optionKeys = Object.keys(initOptions);
    for (const k of optionKeys) {
      returnValue[k] = initOptions[k];
    }
  }
  return returnValue;
};

export class BotTheme {
  theme: any;
  dbTheme: ChatbotTheme;
  myTheme: ChatbotTheme | any;
  constructor(dbTheme: any) {
    this.dbTheme = dbTheme;
    this.myTheme = {
      palette: {
        primary: this.dbTheme?.primary ? this.dbTheme?.primary : PRIMARY,
        secondary: this.dbTheme?.secondary ? this.dbTheme?.secondary : null,
        button: {
          background: this.dbTheme?.button?.background
            ? this.dbTheme?.button?.background
            : this.dbTheme?.primary?.main,
          text: this.dbTheme?.button?.text
            ? this.dbTheme?.button?.text
            : this.dbTheme?.primary,
        },
        button_hover: {
          background: this.dbTheme?.button_hover?.background
            ? this.dbTheme?.button_hover?.background
            : this.dbTheme?.primary?.main,
          text: this.dbTheme?.button_hover?.text
            ? this.dbTheme?.button_hover?.text
            : this.dbTheme?.primary,
        },
        default: {
          background: "#ffffff",
          text: "#282828",
          placeholder: "#AFAFAF",
          white: "#ffffff",
          black: "#101010",
          grey: " #CBCDD3",
          lightGrey: " #F5F6F8",
          darkGrey: " #7E8392",
          blue: "#5B73E8",
          border: "#ededed",
          error: "#F34E4E",
          errorLight: "#eba4a4",
          success: "#27B783",
          iconLight: "rgba(255, 255, 255, 0.4)",
        },
      },
      font: {
        primary: this.dbTheme?.font
          ? {
              ...FONT,
              family: this.dbTheme?.font?.primary?.family,
              size: `${this.dbTheme?.font?.primary?.size}px`,
            }
          : FONT,
        secondary: FONT,
      },
      components: {},
    };

    let pimaryColor = this.myTheme.palette.primary.main;

    let scheme = new Matercolor(this.myTheme.palette.primary.main);
    this.myTheme.palette.primary = {
      ...this.myTheme.palette.primary,
      ...scheme.palette.primary,
    };
    this.myTheme.palette.primary["light"] = scheme.palette.primary["200"];
    this.myTheme.palette.primary["lightest"] = scheme.palette.primary["50"];
    this.myTheme.palette.primary["dark"] = scheme.palette.primary["700"];
    this.myTheme.palette.primary["darkest"] = scheme.palette.primary["900"];

    let secondaryColor = null;
    if (!this.myTheme.palette.secondary) {
      this.myTheme.palette.secondary = {
        main: "#ffffff",
      };
    }
    secondaryColor = this.myTheme.palette.secondary.main;
    let secondaryScheme = new Matercolor(secondaryColor);
    this.myTheme.palette.secondary = {
      ...this.myTheme.palette.secondary,
      ...secondaryScheme.palette.primary,
    };
    this.myTheme.palette.secondary["light"] =
      secondaryScheme.palette.primary["200"];
    this.myTheme.palette.secondary["lightest"] =
      secondaryScheme.palette.primary["50"];
    this.myTheme.palette.secondary["dark"] =
      secondaryScheme.palette.primary["700"];
    this.myTheme.palette.secondary["darkest"] =
      secondaryScheme.palette.primary["900"];

    let primaryTextColor = ColorUtils.getTextColorForBackgroundHex(pimaryColor);
    let secondaryTextColor =
      ColorUtils.getTextColorForBackgroundHex(secondaryColor);

    // primary text scheme to go wherever primary backgrounds are used.
    let primaryTextScheme = new Matercolor(primaryTextColor);
    let secondaryTextScheme = new Matercolor(secondaryTextColor);
    this.myTheme.font.primary.color = { main: primaryTextColor };
    this.myTheme.font.primary.color["light"] =
      primaryTextScheme.palette.primary["200"];
    this.myTheme.font.primary.color["lightest"] =
      primaryTextScheme.palette.primary["50"];
    this.myTheme.font.primary.color["dark"] =
      primaryTextScheme.palette.primary["700"];
    this.myTheme.font.primary.color["darkest"] =
      primaryTextScheme.palette.primary["900"];

    this.myTheme.font.secondary.color = {
      main: primaryTextColor,
      ...secondaryTextScheme.palette.primary,
    };
    this.myTheme.font.secondary.color["light"] =
      secondaryTextScheme.palette.primary["200"];
    this.myTheme.font.secondary.color["lightest"] =
      secondaryTextScheme.palette.primary["50"];
    this.myTheme.font.secondary.color["dark"] =
      secondaryTextScheme.palette.primary["700"];
    this.myTheme.font.secondary.color["darkest"] =
      secondaryTextScheme.palette.primary["900"];

    let primaryFontFamily = this.myTheme.font.primary.family;
    let primaryFontSize = this.myTheme.font.primary.size;

    //generate defaults based on primary
    let headerColor = "#ffffff";
    let headerBrandColor = pimaryColor;
    let headerIconColor = ColorUtils.getContrastTextColorHex(headerColor, true);

    const headerDefault = {
      background: {
        color: headerColor,
      },
      text: getDefaultFont({
        color: headerBrandColor,
        family: primaryFontFamily,
      }),
      icon: getDefaultFont({
        color: headerIconColor,
        family: primaryFontFamily,
        size: "20px",
      }),
    };

    let frameColor = pimaryColor;
    const frameDefault = {
      border: {
        color: frameColor,
      },
    };

    let botMessageColor = this.myTheme.palette.secondary.main;
    let botMessageTextColor =
      ColorUtils.getTextColorForBackgroundHex(botMessageColor);
    const botMessageDefault = {
      background: {
        color: botMessageColor,
      },
      text: getDefaultFont({
        color: botMessageTextColor,
        family: primaryFontFamily,
        size: primaryFontSize,
      }),
    };

    let userMessageColor = this.myTheme.palette.primary.main;
    let userMessageTextColor = "#fff";
    // ColorUtils.getTextColorForBackgroundHex(userMessageColor);
    const userMessageDefault = {
      background: {
        color: userMessageColor,
      },
      text: getDefaultFont({
        color: userMessageTextColor,
        family: primaryFontFamily,
        size: primaryFontSize,
      }),
    };

    // let chatWallColor = this.myTheme.palette.primary.lightest;
    let chatWallColor = "#F1F1F1";
    let chatWallTextColor =
      ColorUtils.getTextColorForBackgroundHex(chatWallColor);
    const chatWallDefault = {
      background: {
        color: chatWallColor,
      },
      text: getDefaultFont({
        color: chatWallTextColor,
        family: primaryFontFamily,
      }),
    };

    // let userInputColor = this.myTheme.palette.primary.lightest;
    let userInputColor = ColorUtils.getMatchingBackgroundColorHex(
      this.myTheme.palette.primary.lightest
    );
    let userInputTextColor =
      ColorUtils.getTextColorForBackgroundHex(userInputColor);
    let inputFieldTextColorScheme = new Matercolor(userInputTextColor);

    const userInputDefault = {
      background: {
        color: userInputColor,
      },
      text: getDefaultFont({
        color: inputFieldTextColorScheme["900"],
        family: primaryFontFamily,
      }),
    };

    const userFocusInputDefault = {
      background: {
        color: userInputColor,
      },
      text: getDefaultFont({
        color: inputFieldTextColorScheme["900"],
        family: primaryFontFamily,
      }),
    };
    const userActiveInputDefault = {
      background: {
        color: userInputColor,
      },
      text: getDefaultFont({
        color: inputFieldTextColorScheme["800"],
        family: primaryFontFamily,
      }),
    };

    const userPlaceholderInputDefault = {
      background: {
        color: userInputColor,
      },
      text: getDefaultFont({
        color: inputFieldTextColorScheme["500"],
        family: primaryFontFamily,
      }),
    };

    let botOptionOutlinedColor = this.myTheme.palette.primary.lightest;
    let botOptionOutlinedTextColor = ColorUtils.getTextColorForBackgroundHex(
      botOptionOutlinedColor
    );

    // console.log(this.dbTheme.button_background);

    let botOptionStandardColor =
      this.myTheme.palette?.button?.background ??
      this.myTheme.palette.primary.main;
    let botOptionStandardTextColor = ColorUtils.getTextColorForBackgroundHex(
      botOptionStandardColor
    );
    let botOptionStandardHoverColor =
      this.myTheme.palette?.button_hover?.background ??
      this.myTheme.palette.primary.main;
    let botOptionStandardHoverTextColor =
      ColorUtils.getTextColorForBackgroundHex(botOptionStandardHoverColor);

    // let botOptionStandardColor = this.myTheme.palette.primary.main;
    // let botOptionStandardTextColor = ColorUtils.getTextColorForBackgroundHex(botOptionStandardColor);

    const botOptionOutlinedDefault = {
      background: {
        color: botOptionOutlinedColor,
      },
      text: getDefaultFont({
        color: botOptionOutlinedTextColor,
        family: primaryFontFamily,
        size: primaryFontSize,
      }),
      box: {
        borderWidth: "1px",
        borderColor: this.myTheme.palette.primary.main,
        borderRadius: "4px",
      },
    };
    const botOptionOutlinedHoverDefault = {
      background: {
        color: botOptionStandardColor,
      },
      text: getDefaultFont({
        color: botOptionStandardTextColor,
        family: primaryFontFamily,
        size: primaryFontSize,
      }),
      box: {
        borderWidth: "1px",
        borderColor: this.myTheme.palette.primary.main,
        borderRadius: "4px",
      },
    };

    const botOptionStandardDefault = {
      background: {
        color: botOptionStandardColor,
      },
      text: getDefaultFont({
        color: botOptionStandardTextColor,
        family: primaryFontFamily,
        size: primaryFontSize,
      }),
      box: {
        borderWidth: "1px",
        borderColor: botOptionStandardColor,
        borderRadius: "4px",
      },
    };
    const botOptionStandardHoverDefault = {
      background: {
        color: botOptionStandardHoverColor,
      },
      text: getDefaultFont({
        color: botOptionStandardHoverTextColor,
        family: primaryFontFamily,
        size: primaryFontSize,
      }),
      box: {
        borderWidth: "1px",
        borderColor: botOptionStandardHoverColor,
        borderRadius: "4px",
      },
    };

    this.myTheme.components = {
      header: headerDefault,
      frame: frameDefault,
      bot_message: botMessageDefault,
      user_message: userMessageDefault,
      chat_wall: chatWallDefault,
      user_input: {
        normal: userInputDefault,
        placeholder: userPlaceholderInputDefault,
        focus: userFocusInputDefault,
        active: userActiveInputDefault,
      },
      bot_options_outlined: {
        normal: botOptionOutlinedDefault,
        hover: botOptionOutlinedHoverDefault,
      },
      bot_options_standard: {
        normal: botOptionStandardDefault,
        hover: botOptionStandardHoverDefault,
      },
      primary_color_scheme: new Matercolor(this.myTheme.palette.primary.main),
    };

    const outerKeys = this.dbTheme ? Object.keys(this.dbTheme) : [];
    for (const outerKey of outerKeys) {
      if (outerKey !== "id") {
        const elementOptions: any = dbTheme[outerKey];
        if (elementOptions) {
          const innerKeys = Object.keys(elementOptions);
          for (const innerKey of innerKeys) {
            if (
              elementOptions[innerKey] !== null ||
              elementOptions[innerKey] !== undefined ||
              elementOptions[innerKey] !== ""
            ) {
              this.setThemeComponentKeyValue(
                outerKey,
                innerKey,
                elementOptions[innerKey]
              );
            } else {
            }
          }
        }
      }
    }
    this.theme = createTheme(this.myTheme);
  }

  setThemeComponentKeyValue(ok: string, ik: string, v: any) {
    const components = this.myTheme["components"];
    if (!components.hasOwnProperty(ok)) {
      components[ok] = {};
    }
    components[ok][ik] = v;
  }

  // getDefaultValue

  getItem(key: string) {
    if (this.theme.palette.primary.hasOwnProperty(key)) {
      return this.theme.palette.primary[key];
    } else {
      return this.theme.palette.default.hasOwnProperty(key);
    }
  }

  initTheme() {
    // const allKeys = Object.keys(this.dbTheme.primary);
    // for (const k of allKeys) {
    //   muiThemeObj.palette.primary[k] = this.dbTheme.primary[k];
    // }
  }

  initElement(element: string, option: string) {}
}

// const botTheme = new BotTheme();

const bot_theme = new BotTheme({
  primary: {
    main: PRIMARY.main,
  },
});
export const theme = createTheme(bot_theme.theme);

const botTheme: any = {
  palette: {
    primary: {
      main: botConfiguration.PRIMARY_COLOR,
      light: "#5B73E8",
      dark: "#5B73E8",
    },
    default: {
      background: "#ffffff",
      text: "#282828",
      placeholder: "#AFAFAF",
      white: "#ffffff",
      black: "#101010",
      grey: " #CBCDD3",
      lightGrey: " #F5F6F8",
      darkGrey: " #7E8392",
      blue: "#5B73E8",
      border: "#CBCDD3",
      error: "#F34E4E",
      success: "#27B783",
      iconLight: "rgba(255, 255, 255, 0.4)",
      user: "#5B73E8",
      bot: "#979797",

      headerBackground: botConfiguration.PRIMARY_COLOR,
      headerTextColor: "#ffffff",

      chatScreenBackgroundColor: "white",

      botMessageBackground: "orange",
      botMessageTextColor: "dodgerBlue",
      userMessageBackground: "tomato",
      userMessageTextColor: "brown",

      buttonPrimaryColor: "white",
      buttonSecondaryColor: botConfiguration.PRIMARY_COLOR,

      inputFieldBackground: "red",
      inputFieldTextColor: "black",
      inputFieldPlaceholder: "yellow",
      inputFieldBorder: "black",
      sendButtonColor: "Green",
    },
    secondary: {
      main: "#F5F6F8",
    },
    action: {
      selected: "#5B73E8",
      disabled: "#F5F6F8",
    },
  },
  typography: {
    fontFamily: botConfiguration.FONT_FAMILY,
    h1: {
      fontSize: "6rem",
    },
    h2: {
      fontSize: "3.75rem",
    },
    h3: {
      fontSize: "3rem",
      fontWeight: 400,
      lineHeight: 1.13,
    },
    h4: {
      fontSize: "2.125rem",
      fontWeight: 500,
    },
    h5: {
      fontSize: "1.5rem",
      fontWeight: 600,
    },
    h6: {
      fontSize: "1.25rem",
    },

    body1: {
      fontSize: "1rem",
    },
    body2: {
      fontSize: "0.875rem",
    },
    caption: {
      fontSize: "0.75rem",
    },

    subtitle1: {
      fontSize: "1rem",
    },
    subtitle2: {
      fontSize: "0.875rem",
    },
    overline: {
      fontSize: "0.75rem",
    },
    button: {
      fontSize: "0.875rem",
    },
  },
  spacing: 8,
  shape: {
    borderRadius: 4,
  },
};

import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosRequestHeaders,
} from "axios";
import { formatString } from "../Utils";

const getHttpService = (axios: AxiosInstance) => {
  const get = async (
    url: string,
    params = {},
    headers?: AxiosRequestHeaders,
    pathParams?: Array<any>
  ) => {
    if (pathParams) {
      url = formatString(url, pathParams);
    }

    const config: AxiosRequestConfig = {
      url: url,
      method: "get",
    };
    if (params) {
      config.params = params;
    }
    if (headers) {
      config.headers = headers;
    }
    return await axios
      .request(config)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  };

  const post = async (
    url: string,
    data = {},
    params?: any,
    headers?: AxiosRequestHeaders
  ) => {
    const config: AxiosRequestConfig = {
      url: url,
      method: "post",
    };
    if (params) {
      config.params = params;
    }
    if (data) {
      config.data = data;
    }
    if (headers) {
      config.headers = headers;
    }
    return await axios
      .request(config)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  };

  const patch = async (
    url: string,
    data = {},
    params?: any,
    headers?: AxiosRequestHeaders
  ) => {
    const config: AxiosRequestConfig = {
      url: url,
      method: "patch",
    };
    if (params) {
      config.params = params;
    }
    if (data) {
      config.data = data;
    }
    if (headers) {
      config.headers = headers;
    }
    return await axios
      .request(config)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  };
  const put = async (
    url: string,
    data = {},
    params?: any,
    headers?: AxiosRequestHeaders
  ) => {
    const config: AxiosRequestConfig = {
      url: url,
      method: "put",
    };
    if (params) {
      config.params = params;
    }
    if (data) {
      config.data = data;
    }
    if (headers) {
      config.headers = headers;
    }
    return await axios
      .request(config)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  };

  const httpDelete = async (
    url: string,
    data = {},
    params?: any,
    headers?: AxiosRequestHeaders
  ) => {
    const requestData: any = { config: { headers } };
    return axios
      .delete(url, requestData)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  };

  const upload = async (
    url: string,
    data = {},
    params?: any,
    headers?: AxiosRequestHeaders
  ) => {   
    const config: AxiosRequestConfig = {
      url: url,
      method: "post",
    };
    if (params) {
      config.params = params;
    }
    if (data) {
      config.data = data;
    }
    if (headers) {
      config.headers = headers;
    }
    return await axios
      .request(config)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  };

  return { get, post, put, patch, httpDelete, upload };
};

export { getHttpService };

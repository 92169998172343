let shorthandColorHexRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
let colorHexRegex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;

var colourIsLight = function (r: number, g: number, b: number) {
  // Counting the perceptive luminance
  // human eye favors green color...
  var a = 1 - (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  // console.log(a);
  return a < 0.5;
};

export class ColorUtils {
  static getTextColorForBackgroundRGB(r: number, g: number, b: number) {
    return colourIsLight(r, g, b) ? "#282828" : "#ffffff";
  }
  static getTextColorForBackgroundHex(hexCode: string) {
    const result = ColorUtils.hexToRgb(hexCode);
    if (result) {
      const { r, g, b } = result;
      return colourIsLight(r, g, b) ? "#282828" : "#ffffff";
    }
    throw Error("HexCode Incorrect");
  }
  static hexToRgb(hex: string) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    // console.log(hex, "hex");

    if (typeof hex !== "string") {
      hex = "#5B73E8";
    }
    hex = hex?.replace(shorthandColorHexRegex, function (m, r, g, b) {
      return r + r + g + g + b + b;
    });

    var result = colorHexRegex.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  static getContrastTextColorHex = (
    hexcolor: string,
    returnGreyColor: boolean = false
  ) => {
    // If a leading # is provided, remove it
    if (hexcolor.slice(0, 1) === "#") {
      hexcolor = hexcolor.slice(1);
    }

    // Convert to RGB value
    var r = parseInt(hexcolor.substr(0, 2), 16);
    var g = parseInt(hexcolor.substr(2, 2), 16);
    var b = parseInt(hexcolor.substr(4, 2), 16);

    // Get YIQ ratio
    var yiq = (r * 299 + g * 587 + b * 114) / 1000;

    // Check contrast
    return yiq >= 128 ? (returnGreyColor ? "#7E8293" : "#282828") : "#ffffff";
  };

  static getMatchingBackgroundColorHex = (hexcolor: string) => {
    // If a leading # is provided, remove it
    // console.log(hexcolor,"hexcolor")
    if (hexcolor.slice(0, 1) === "#") {
      hexcolor = hexcolor.slice(1);
    }

    // Convert to RGB value
    var r = parseInt(hexcolor.substr(0, 2), 16);
    var g = parseInt(hexcolor.substr(2, 2), 16);
    var b = parseInt(hexcolor.substr(4, 2), 16);

    var a = 1 - (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    // console.log(a);
    return a < 0.5 ? "#FAFAFA" : "#424242";
  };
}

import { ClickAwayListener, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { withTheme } from "styled-components";

import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import MarkChatUnreadIcon from "@mui/icons-material/MarkChatUnread";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import { useBotConfigStore } from "../../../Store/Slices/ChatbotSlices/BotConfigSlice";
import { deviceSizeStore } from "../../../Store/Slices/socket/DeviceSizeSlice";
import { StyledNotificationBlockWrapper } from "../../Styles/StyledComponents";
import AppDrawer from "../UtilsComponents/AppDrawer";
import AppSmallButton from "../UtilsComponents/AppSmallButton";

interface Props {
  theme?: any;
}

const AppRenderNotificationMenu: React.FC<Props> = ({ theme }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);
  const [iconStyle, setIconStyle] = useState<number>(1);

  const IframeDimensions = useSelector(deviceSizeStore);

  const { botConfig } = useSelector(useBotConfigStore);

  useEffect(() => {
    if (
      botConfig?.config?.notification_block?.enabled &&
      botConfig?.config?.notification_block?.data
    ) {
      setData(botConfig?.config?.notification_block?.data);
      setIconStyle(botConfig?.config?.notification_block?.selected_icon || 1);
    }
  }, [botConfig]);

  const handleCardClick = (url: any) => {
    window.open(url, "_blank", "noreferrer");
  };

  if (
    !botConfig?.config?.notification_block?.enabled ||
    botConfig?.config?.notification_block?.data?.length < 1
  ) {
    return null;
  }
  return (
    <>
      <StyledNotificationBlockWrapper>
        <IconButton
          onClick={() => setOpen(true)}
          className="notificationIcon"
        // style={{ pointerEvents: inputReadyStatus ? "auto" : "none" }}
        >
          {iconStyle === 1 && <NotificationsOutlinedIcon />}
          {iconStyle === 2 && <ConfirmationNumberIcon />}
          {iconStyle === 3 && <Inventory2OutlinedIcon />}
          {iconStyle === 4 && <MarkChatUnreadIcon />}
        </IconButton>

        <AppDrawer
          showDrawer={open}
          setShowDrawer={setOpen}
          style={{
            top: "55px",
            right: "-0.44rem",
            width: IframeDimensions?.width - 21 + "px",
            borderRadius: "6px",
            overflowX: "hidden",
            paddingBottom: 0,
            maxHeight: "calc(100vh - 110px)",
          }}
          intialX={0}
          intialY={"5%"}
          animateX={0}
          animateY={0}
          exitX={0}
          exitY={"+100%"}
          animateHeight={"calc(100vh - 110px)"}
        >
          <ClickAwayListener onClickAway={() => setOpen(false)}>
            <div
              className="notificationListWrapper"
              style={{ overflow: "overlay", width: "100%" }}
            >
              {open &&
                data &&
                data?.length > 0 &&
                data?.map((curElem: any, index: number) => {
                  return (
                    <div
                      className={"singleRow"}
                      key={index?.toString()}
                      onClick={() => handleCardClick(curElem?.button_url)}
                    >
                      <div className="rowLeftBox">
                        <img src={curElem?.image?.source} alt="" />
                      </div>

                      <div className="rowRightBox">
                        <div className="rowRightTop">
                          {curElem?.notification_title}
                        </div>
                        <div className="rowRightMiddle">
                          {curElem?.notification_description}
                        </div>
                        <div className="rowRightBottom" style={{}}>
                          <AppSmallButton
                            variant={"text"}
                            onClick={() => handleCardClick(curElem?.button_url)}
                            style={{ minWidth: "unset" }}
                          >
                            {curElem?.button_title}
                          </AppSmallButton>
                          <div className="footerText">
                            {curElem?.footer_text}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              <div className="staticButton" onClick={() => setOpen(false)}>
                <AppSmallButton
                  variant={"text"}
                  onClick={() => setOpen(false)}
                  style={{ width: "100%" }}
                >
                  Close
                </AppSmallButton>
              </div>
            </div>
          </ClickAwayListener>
        </AppDrawer>
      </StyledNotificationBlockWrapper>
    </>
  );
};

export default withTheme(AppRenderNotificationMenu);

import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAltOutlined";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import Rating, { IconContainerProps } from "@mui/material/Rating";
import { getIn, useFormikContext } from "formik";
import React, { useEffect } from "react";
import {
  StyledTextAreaField
} from "../../../Styles/StyledForms";
import {
  StyledRatingDrawerTextContentBox
} from "../../../Styles/StyledScreens";

const labels: { [index: string]: string } = {
  0.5: "Bad",
  1: "Bad",
  1.5: "Poor",
  2: "Poor",
  2.5: "Ok",
  3: "Average",
  3.5: "Good",
  4: "Good",
  4.5: "Excellent",
  5: "Excellent",
};

function getLabelText(value: number) {
  return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
}

const customIcons: {
  [index: string]: {
    icon: React.ReactElement;
    label: string;
  };
} = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon color="error" />,
    label: "Very Dissatisfied",
  },
  2: {
    icon: <SentimentDissatisfiedIcon color="error" />,
    label: "Dissatisfied",
  },
  3: {
    icon: <SentimentSatisfiedIcon color="warning" />,
    label: "Neutral",
  },
  4: {
    icon: <SentimentSatisfiedAltIcon color="success" />,
    label: "Satisfied",
  },
  5: {
    icon: <SentimentVerySatisfiedIcon color="success" />,
    label: "Very Satisfied",
  },
};

function IconContainer(props: IconContainerProps) {
  const { value, ...other } = props;
  return <span {...other} style={{marginRight:'1rem'}}>{customIcons[value].icon}</span>;
}

interface Props {
  name?: string;
  feedbackKeyName?: string;
  label?: string;
  children?: React.ReactNode;
  [otherProps: string]: any;
  ref?: any;
}

const AppInputRatingField: React.FC<Props> = ({
  name = "rating",
  feedbackKeyName = "feedback",
  label = "Enter your email",
  children,
  ...otherProps
}) => {
  const {
    values,
    setFieldTouched,
    setFieldValue,
  } = useFormikContext<any>();

  const [hover, setHover] = React.useState(
    getIn(values, name) ? getIn(values, name) : 4
  );

  useEffect(() => {
    if (!getIn(values, name)) {
      setFieldValue(name, 3);
    }
  }, [getIn(values, name)]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledRatingDrawerTextContentBox>
      <p className="title">
        Please rate your experience with us.
      </p>

      <div className="ratingBox">
        <Rating
          name={name}
          precision={1}
          onBlur={() => setFieldTouched(name)}
          value={getIn(values, name)}
          getLabelText={getLabelText}
          onChange={(event: any, newValue: any) => {
            setFieldValue(name, newValue);
          }}
          onChangeActive={(event, newHover) => {
            setHover(newHover);
          }}
          size="large"
          // IconContainerComponent={IconContainer}
        />
        {getIn(values, name) !== null && (
          <p className="ratingText">
            {labels[hover !== -1 ? hover : getIn(values, name)]}
          </p>
        )}
      </div>
      <StyledTextAreaField
        {...otherProps}
        autoFocus
        rows={3}
        onBlur={() => setFieldTouched(feedbackKeyName)}
        onChange={(e: any) => setFieldValue(feedbackKeyName, e.target.value)}
        value={getIn(values, feedbackKeyName) || ""}
        autoComplete={"off"}
        placeholder="Type your suggestions here..."
      />
      {/* <div className="captionText">
        When you submit feedback, your chat session will end.
      </div> */}
    </StyledRatingDrawerTextContentBox>
  );
};

export default AppInputRatingField;

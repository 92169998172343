import { memo, useEffect, useRef } from "react";
import { useAppSelector } from "../../Store";
import { allMessagesStore } from "../../Store/Slices/socket/AllMessagesSlice";
import {
  AppRenderAudio,
  AppRenderCard,
  AppRenderCtaButtons,
  AppRenderFile,
  AppRenderFlowButtons,
  AppRenderImage,
  AppRenderInfo,
  AppRenderLiveButtons,
  AppRenderReturningSession,
  AppRenderSlider,
  AppRenderText,
  AppRenderTypingIndicator,
  AppRenderVideo,
} from "../appComponents/RenderComponents";
import { MESSAGE_TYPES } from "../utils/Enum";

interface Props {
  [otherProps: string]: any;
}

const MessagesScreen: React.FC<Props> = () => {
  //redux
  const { allMessages, sessionId } = useAppSelector(allMessagesStore);

  //scroll to bottom
  const messagesEndRef = useRef<any>();
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({
        behavior: "instant",
        block: "end",
        inline: "nearest",
      });
    }
  }, [allMessages]);

  //render returning user session confirmation message
  if (allMessages?.length === 0 && sessionId?.length > 0)
    return (
      <>
        <AppRenderReturningSession />
        <div ref={messagesEndRef} />
      </>
    );

  return (
    <>
      {allMessages &&
        allMessages?.length > 0 &&
        allMessages?.map((singleMsg: any, index: number) => {
          //* TEXT
          if (singleMsg?.type === MESSAGE_TYPES.TEXT) {
            return (
              <AppRenderText
                userMessage={singleMsg?.isUser ? true : false}
                key={index.toString()}
                time={singleMsg?.time}
                readStatus={singleMsg?.readStatus || "delivered"}
              >
                {singleMsg?.text || singleMsg?.value}
              </AppRenderText>
            );
          }

          //* INFO Alert
          if (singleMsg?.type === MESSAGE_TYPES.INFO) {
            return (
              <AppRenderInfo
                key={index.toString()}
                userMessage={singleMsg?.isUser ? true : false}
                time={singleMsg?.time}
                readStatus={singleMsg?.readStatus || "delivered"}
              >
                {singleMsg?.text || singleMsg?.value}
              </AppRenderInfo>
            );
          }

          // Buttons
          if (singleMsg?.type === MESSAGE_TYPES.BUTTON) {
            return (
              <AppRenderCard
                userMessage={singleMsg?.isUser ? true : false}
                key={index.toString()}
                item={singleMsg?.value}
                time={singleMsg?.time}
                readStatus={singleMsg?.readStatus || "delivered"}
              />
            );
          }


          //* VIDEO
          if (singleMsg?.type === MESSAGE_TYPES.VIDEO) {
            return (
              <AppRenderVideo
                userMessage={singleMsg?.isUser ? true : false}
                key={index.toString()}
                src={singleMsg?.value?.url || ""}
                alt={singleMsg?.value?.text || ""}
                time={singleMsg?.time}
                readStatus={singleMsg?.readStatus || "delivered"}
              />
            );
          }
          //* AUDIO
          if (singleMsg?.type === MESSAGE_TYPES.AUDIO) {
            return (
              <AppRenderAudio
                userMessage={singleMsg?.isUser ? true : false}
                key={index.toString()}
                src={singleMsg?.value?.url || ""}
                alt={singleMsg?.value?.text || ""}
                time={singleMsg?.time}
                readStatus={singleMsg?.readStatus || "delivered"}
              />
            );
          }

          //* IMAGE
          if (singleMsg?.type === MESSAGE_TYPES.IMAGE) {
            return (
              <AppRenderImage
                userMessage={singleMsg?.isUser ? true : false}
                key={index.toString()}
                src={singleMsg?.value?.url || ""}
                alt={singleMsg?.value?.text || ""}
                time={singleMsg?.time}
                readStatus={singleMsg?.readStatus || "delivered"}
              />
            );
          }

          //* FILE
          if (
            singleMsg?.type === MESSAGE_TYPES.FILE ||
            singleMsg?.type === MESSAGE_TYPES.DOCUMENT
          ) {
            return (
              <AppRenderFile
                userMessage={singleMsg?.isUser ? true : false}
                key={index.toString()}
                src={singleMsg?.value?.url || ""}
                name={singleMsg?.value?.fileName || "File"}
                alt={singleMsg?.value?.text || ""}
                time={singleMsg?.time}
                readStatus={singleMsg?.readStatus || "delivered"}
              />
            );
          }

          //* CAROUSEL
          if (singleMsg?.type === MESSAGE_TYPES.CAROUSEL) {
            return (
              <AppRenderSlider
                userMessage={singleMsg?.isUser ? true : false}
                items={singleMsg?.value}
                key={index.toString()}
                time={singleMsg?.time}
                readStatus={singleMsg?.readStatus || "delivered"}
              />
            );
          }

          return null;
        })}
      
      <AppRenderTypingIndicator />
      <div ref={messagesEndRef} />
    </>
  );
};

export default memo(MessagesScreen);

import React from "react";
import styled from "styled-components";
import useInternetConnection from "../../hooks/useInternetConnection";
import AppAlert from "./AppAlert";
import { CircularProgress } from "@mui/material";
import { useAppSelector } from "../../../Store";
import { useBotConfigStore } from "../../../Store/Slices/ChatbotSlices/BotConfigSlice";

interface Props {
    loading: boolean;
}

const AppChatLoadingScreen: React.FC<Props> = ({loading}) => {
  const { isOnline } = useInternetConnection();
  const { botConfig } = useAppSelector(useBotConfigStore);

  const returnLoadingImageSpinner = () => {
    return (
      <div className="loadingContainer">
        <img
          src={botConfig.config?.company_logo?.source}
          alt={botConfig?.chatbot?.name}
          className="logoImage"
        />
        <CircularProgress size={44} className="loadingCircle" />
      </div>
    );
  };

  const returnNoInternetBox = () => {
    return (
      <AppAlert severity="error">
        Kindly check your internet connection and try again!
      </AppAlert>
    );
  };

  const returnLoadingBlock = () => {
    return (
      <StyledChatLoadingScreenWrap>
        {returnLoadingImageSpinner()}
        <p className="loadingText">Loading messages...</p>
      </StyledChatLoadingScreenWrap>
    );
  };

  if (!isOnline) {
    return returnNoInternetBox();
  }

  if (loading) {
    return returnLoadingBlock();
  }

  return null;
};

export default AppChatLoadingScreen;

const StyledChatLoadingScreenWrap = styled.div`
  && {
    position: absolute;
    z-index: 1;
    background-color: ${({ theme }) =>
      theme?.components?.chat_wall.background.color};
    width: 100%;
    height:calc(100% - 74px);
    border-radius: 12px;

    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    justify-content: center;
    align-items: center;

    .loadingContainer {
      display: flex;
      gap: 1rem;
      align-items: center;
      justify-content: center;
      position: relative;
    }

    .loadingCircle {
      position: absolute;
    }

    .logoImage {
      width: 32px;
      height: 32px;
      object-fit: cover;
      border-radius: 50%;
    }

    .loadingText {
      font-size: 15px;
      letter-spacing: 0.5px;
      font-weight: 400;
      font-family: ${({ theme }) => theme?.font?.primary.family};
      width: 80%;
      text-align: center;
    }
  }
`;


export const PRIMARY = {
    // main: "#3654E3",
    // main: "#efdf00",
    // main: "#ff4c4c",
    // main: "#ed1c24",
    // main: "#34bf49",
    main: "#0099e5",
    // main: "#000000",
    // light: "#5B73E8",
    // dark: "#5B73E8",
    // lightest: "#ffebee",
    "50": "#e9ebfc",
    "100": "#c8ccf7",
    "200": "#a2acf2",
    "300": "#7a8bed",
    "400": "#596fe9",
    "500": "#3654e3",
    "600": "#304bd8",
    "700": "#2540cb",
    "800": "#1935c0",
    "900": "#001fad",
    "A100": "#879ffb",
    "A200": "#4b70f4",
    "A400": "#395eec",
    "A700": "#3654e3"
};
export const SECONDARY = {
    main: "#F5F6F8"
};
export const FONT = {
    family: "Poppins",
    color: "black",
    weight: "400",
    size: '14px'

}
export const FONT_LIGHT = {
    family: "Poppins",
    color: "white",
    weight: "400"
}
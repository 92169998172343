import TranslateIcon from "@mui/icons-material/Translate";
import { ClickAwayListener, IconButton } from "@mui/material";
import React, { memo, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import uniqid from "uniqid";
import { StyledLanguageWrapper } from "../../Styles/StyledComponents";
import { useAppDispatch } from "../../../Store";
import { useBotConfigStore } from "../../../Store/Slices/ChatbotSlices/BotConfigSlice";
import {
  allMessagesStore,
  setBotLanguage,
} from "../../../Store/Slices/socket/AllMessagesSlice";
import { botInfoStore } from "../../../Store/Slices/socket/BotInfoSlice";
import { titlize } from "../../utils/utils";
import AppDrawer from "../UtilsComponents/AppDrawer";
import { PreviewType } from "../../utils/Enum";

interface Props {}

const AppLanguageSelector: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const { botConfig } = useSelector(useBotConfigStore);
  const { widgetPreviewType, chatOpened } = useSelector(botInfoStore);
  const { botLanguage, typingStatus } = useSelector(allMessagesStore);

  const [langauageData, setLanguagesData] = useState<any>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [showLanguageSwitchOption, setShowLanguageSwitchOption] =
    useState<boolean>(false);

  useEffect(() => {
    if (
      botConfig?.chatbot?.multi_lingual?.multi_lingual &&
      botConfig?.chatbot?.multi_lingual?.display_language_option
    ) {
      setShowLanguageSwitchOption(true);
    } else {
      setShowLanguageSwitchOption(false);
    }

    if (
      botConfig?.chatbot?.chatbot_languages &&
      botConfig?.chatbot?.chatbot_languages?.length > 0
    ) {
      setLanguagesData(botConfig?.chatbot?.chatbot_languages);
    }
  }, [botConfig]);

  const handleLanguageClick = (item: any) => {
    dispatch(setBotLanguage(item?.language || "english"));
    setOpen(false);
  };

  let DivBoxref = useRef<any>(null);
  useEffect(() => {
    if (chatOpened && DivBoxref && DivBoxref?.current) {
      setTimeout(() => {
        DivBoxref?.current?.classList.add("active");
      }, 800);
      setTimeout(() => {
        DivBoxref?.current?.classList.remove("active");
      }, 2300);
    }
  }, [botLanguage, chatOpened]);

  if (!showLanguageSwitchOption && !chatOpened) {
    return null;
  }

  return (
    <StyledLanguageWrapper
      style={{
        display: showLanguageSwitchOption ? "flex" : "none",
        pointerEvents:
          widgetPreviewType !== PreviewType.getaWidgetPreview && !typingStatus
            ? "auto"
            : "none",
      }}
    >
      <div
        onClick={() => {
          setOpen(true);
        }}
        ref={DivBoxref}
        className="LanguageIconBox"
      >
        <IconButton
          onClick={() => {
            setOpen(true);
          }}
          className="languageIcon"
        >
          <TranslateIcon />
        </IconButton>

        <p className="currentLanguage">
          {botLanguage ? titlize(botLanguage) : "English"}
        </p>
      </div>

      <AppDrawer
        showDrawer={open}
        setShowDrawer={setOpen}
        style={{
          top: "50px",
          width: "120px",
          borderRadius: "6px",
        }}
        intialX={0}
        intialY={"-10%"}
        animateX={0}
        animateY={0}
        exitX={0}
        exitY={"+100%"}
        animateHeight={(langauageData?.length + 1) * 40 + "px"}
      >
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <div
            className="languageListWrapper"
            style={{ overflow: open ? "overlay" : "hidden", height: "auto" }}
          >
            <li
              onClick={() => handleLanguageClick({ language: "english" })}
              key={uniqid()}
            >
              {titlize("English")}
            </li>
            {langauageData &&
              langauageData?.length > 0 &&
              langauageData?.map((curLang: any, index: number) => {
                if (curLang?.enabled) {
                  return (
                    <li
                      key={index?.toString()}
                      onClick={() => handleLanguageClick(curLang)}
                    >
                      {titlize(curLang?.language)}
                    </li>
                  );
                }

                return null;
              })}
          </div>
        </ClickAwayListener>
      </AppDrawer>
    </StyledLanguageWrapper>
  );
};

export default memo(AppLanguageSelector);

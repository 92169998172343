
export enum MESSAGE_TYPES {
  ATTACHMENT = "attachment",
  TEXT = "text",
  FILE = "file",
  IMAGE = 'image',
  VIDEO = 'video',
  LINK = "link",
  MOBILE = "mobile",
  EMAIL = "email",
  CAROUSEL = 'carousel',
  BUTTONS = 'buttons',
  CHOICE = 'choice',
  FLOW = 'flow',
  FEEDBACK = 'feedback',
  LIVE_AGENT = 'live_agent',
  TIMESTAMPS = 'timestamps',
  ALERT = 'alert',
  AWAY = 'AWAY',
  TRANSFER_TO_FAQS = "TRANSFER_TO_FAQS",
  INFO = 'info',
}


export enum INPUT_TYPES {
  NAME = "NAME",
  MOBILE = "MOBILE",
  WEBSITE = "WEBSITE",
  EMAIL = "EMAIL",
  QUERY = "QUERY",
  AWAY = "AWAY",
  NONE = "NONE",
  FILE = "FILE",
  FAQ = "TRANSFER_TO_FAQS",
  TRANSFER_TO_FAQS = "TRANSFER_TO_FAQS",
  OTP = "OTP",
  NUMBER = "NUMBER",
  DATE = "DATE",
  FEEDBACK = "FEEDBACK",
  TEXT = "TEXT",
  DATETIME = "DATETIME",
  DROPDOWN = "DROPDOWN",
  FILE_UPLOAD = "FILE_UPLOAD",
  CUSTOM = "CUSTOM"
}


export enum CHOICE_TYPES {
  CLOSE_SESSION = "close_session",
  RESTART_SESSION = 'restart_session',
  NAVIGATE_TO_PAGE = 'navigate_to_page',
  STAY_ON_PAGE = "stay_on_page",
}


export enum WIDGET_POSITIONS_OPTIONS {
  LEFT = 'Left',
  CENTER = 'Center',
  RIGHT = 'Right',
}
export enum TOOLTIP_POSITIONS_OPTIONS {
  TOP = 'Top',
  SIDE = 'Side',
}

export const IS_FLOW_BUTTONS = [MESSAGE_TYPES.FLOW, MESSAGE_TYPES.LIVE_AGENT]

export enum PreviewType {
  getaWidgetPreview = 'getaWidgetPreview',
}

import { ClickAwayListener, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { withTheme } from "styled-components";
import AppDrawer from "../UtilsComponents/AppDrawer";
import { useSelector } from "react-redux";
import { deviceSizeStore } from "../../../Store/Slices/socket/DeviceSizeSlice";
import { QuickMenuHeaderWrapper } from "../../Styles/StyledScreens";
import MenuIcon from "@mui/icons-material/Menu";
import { useAppDispatch, useAppSelector } from "../../../Store";
import {
  allMessagesStore,
  setAllMessages,
} from "../../../Store/Slices/socket/AllMessagesSlice";
import { socketStatusStore } from "../../../Store/Slices/socket/SocketSlice";
import { useBotConfigStore } from "../../../Store/Slices/ChatbotSlices/BotConfigSlice";
import { SOCKET_CONNECTION_TYPES } from "../../utils/Enum";

interface Props {
  theme?: any;
}
const AppRenderQuickMenuHeader: React.FC<Props> = ({ theme }) => {
  const [open, setOpen] = useState<boolean>(false);
  const IframeDimensions = useSelector(deviceSizeStore);
  const [data, setData] = useState<any[]>([]);
  const [viewStyle, setViewStyle] = useState<number>(2);
  const dispatch = useAppDispatch();
  const { sessionId } = useAppSelector(allMessagesStore);
  const { socket } = useAppSelector(socketStatusStore);
  const { botConfig } = useSelector(useBotConfigStore);

  useEffect(() => {
    if (
      botConfig?.config?.quick_menu?.enabled &&
      botConfig?.config?.quick_menu?.data
    ) {
      setData(botConfig?.config?.quick_menu?.data);
      if (
        botConfig?.config?.quick_menu?.view_style === "Lists" ||
        botConfig?.config?.quick_menu?.view_style === 1
      ) {
        setViewStyle(1);
      }
    }
  }, [botConfig]);

  const handleCardClick = (url: any) => {
    window.open(url, "_blank", "noreferrer");
  };

  const handleOptionBtnClick = async (item: any) => {
    let data = {
      type: "text",
      value:
        item?.journey?.sub_flow?.response?.[0]?.value ||
        item?.journey?.main_flow?.name,
      journey: {
        nodeId: item?.journey?.sub_flow?.nodeId,
        qid: item?.journey?.sub_flow?.qid,
        stage: item?.journey?.sub_flow?.stage,
        displayOrder: item?.journey?.sub_flow?.displayOrder,
      },
      session_id: sessionId,
      isUser: true,
    };

    dispatch(setAllMessages([data]));
    if (socket?.connected) {
      socket?.emit(SOCKET_CONNECTION_TYPES.BOT_CONVERSATION, data);
    }
  };

  if (
    !botConfig?.config?.quick_menu?.enabled ||
    botConfig?.config?.quick_menu?.data?.length < 1
  ) {
    return null;
  }

  return (
    <QuickMenuHeaderWrapper>
      <IconButton onClick={() => setOpen(true)} className="notificationIcon">
        <MenuIcon
          style={{
            fill: "#101010",
          }}
        />
      </IconButton>

      <AppDrawer
        showDrawer={open}
        setShowDrawer={setOpen}
        style={{
          top: "64px",
          left: "0px",
          maxWidth: IframeDimensions?.width,
          borderRadius: "0px",
          overflowX: "hidden",
          paddingBottom: 0,
          maxHeight: "238px",
          padding: "6px",
          boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.1)",
        }}
        intialX={0}
        intialY={"5%"}
        animateX={0}
        animateY={0}
        exitX={0}
        exitY={"+100%"}
        animateHeight={"30vh"}
      >
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <div
            className="notificationListWrapper"
            style={{ overflow: "overlay", width: "100%" }}
          >
            {data?.map((curElem: any, index: number) => {
              return (
                <div
                  className={viewStyle === 1 ? "singleRow" : "singleCard"}
                  key={index?.toString()}
                  onClick={() => {
                    curElem?.journey?.main_flow &&
                      curElem.position !== "Add Link"
                      ? handleOptionBtnClick(curElem)
                      : handleCardClick(curElem?.button_url);
                  }}
                >
                  <img
                    className={viewStyle === 1 ? "rowImage" : "cardImage"}
                    src={curElem?.image?.source}
                    alt="quick menu "
                  />
                  <p className="buttonTitle">
                    {curElem?.button_title || curElem?.journey?.main_flow?.name}
                  </p>
                </div>
              );
            })}
          </div>
        </ClickAwayListener>
      </AppDrawer>
    </QuickMenuHeaderWrapper>
  );
};

export default withTheme(AppRenderQuickMenuHeader);
